import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';

import * as Master from './Store/Master';

import './index.css';
import './web.config';

import { Provider } from 'react-redux';

import configureStore, { history } from './configureStore'
import { firebaseToken, firebaseSmsToken, firebaseRoomSupportToken } from './Store/FirebaseStore';

const authentication = require('react-azure-adb2c2').default;




const store = configureStore(/* provide initial state if any */)




authentication.initialize({
    // optional, will default to this
    instance: 'https://rentglobalcenter.b2clogin.com/',
    // instance: 'https://homestation.b2clogin.com/tfp/',

    // your B2C tenant
    tenant: 'rentglobalcenter.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1_roomsupport',
    // the the B2C application you want to authenticate with
    // applicationId: 'b343c5e8-0695-4d69-93b2-ed9243e30f64',
    applicationId: '876c3868-21a0-4a81-9147-7c29a26638df',
    // where MSAL will store state - localStorage or sessionStorage
    // cacheLocation: 'sessionStorage',
    cacheLocation: 'localStorage',
    // the scopes you want included in the access token
    // scopes: ['https://rentglobalcenter.onmicrosoft.com/876c3868-21a0-4a81-9147-7c29a26638df/roomsupport/'],
    scopes: ['https://rentglobalcenter.onmicrosoft.com/5c87f06f-a30c-4178-a348-390cae2422d2/read'],

    //redirectUri: 'https://localhost:44339/',
    redirectUri: process.env.REACT_APP_BASE_URL,
    // redirectUri: 'http://localhost:37158/',    
    // redirectUri: 'https://homestationreact.azurewebsites.net',

    // optional, the URI to redirect to after logout
    //postLogoutRedirectUri: 'https://homestationreact.azurewebsites.net'
});


// authentication.initialize({
//     // optional, will default to this
//     // instance: 'https://login.microsoftonline.com/tfp/',
//     instance: 'https://globalcenterowners.b2clogin.com/',

//     // your B2C tenant
//     tenant: 'globalcenterowners.onmicrosoft.com',
//     // the policy to use to sign in, can also be a sign up or sign in policy
//     // signInPolicy: 'B2C_1_owners.global-center.co.jp',

//     signInPolicy: 'B2C_1_owners_globalcenter',

//     resetPolicy: 'B2C_1_owners',
//     // the the B2C application you want to authenticate with
//     applicationId: '1704cc2b-e6db-462e-b24f-bd1db6137c91',
//     // where MSAL will store state - localStorage or sessionStorage
//     cacheLocation: 'sessionStorage',
//     // cacheLocation: 'localStorage',
//     // the scopes you want included in the access token
//     // scopes: ['https://globalcenterowners.onmicrosoft.com/api/user_impersonation'],
//     // scopes: ['https://globalcenterowners.onmicrosoft.com/21dc96fa-34ab-4908-bda1-e91a590b666d/user_impersonation'],
//     scopes: ['https://globalcenterowners.onmicrosoft.com/265d9341-816c-4681-9b41-76ccea08b02f/read'],

//     //redirectUri: 'https://localhost:44339/',
//     redirectUri: process.env.REACT_APP_BASE_URL,
//     // redirectUri: 'http://localhost:37158/',    
//     // redirectUri: 'https://homestationreact.azurewebsites.net',

//     // optional, the URI to redirect to after logout
//     //postLogoutRedirectUri: 'https://homestationreact.azurewebsites.net'
// });






authentication.run(() => {
    // store.dispatch(firebaseToken())
    // store.dispatch(firebaseSmsToken())
    // store.dispatch(firebaseRoomSupportToken())



    // store.dispatch(Master.getUsers())
    // store.dispatch(Master.get_user_details())
    // store.dispatch(Master.getRentMMedeas())
    // store.dispatch(Master.getRentAgents())
    // store.dispatch(Master.getRentMReasons())
    // store.dispatch(Master.get_shops())
    // store.dispatch(Master.get_setsubi_master())
    // store.dispatch(Master.get_chomeis())
    // store.dispatch(Master.get_chuugakkous())
    // store.dispatch(Master.get_shougakkous())
    // store.dispatch(Master.get_todoufukens())
    // store.dispatch(Master.get_shikuchousons())
    // store.dispatch(Master.get_kanricorps())
    // store.dispatch(Master.get_tm_owners())
    // store.dispatch(Master.get_rent_m_contract_types())
    // store.dispatch(Master.get_tm_chinryou_koumokus())
    // store.dispatch(Master.get_m_penalties())



    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}

                <App />

            </ConnectedRouter>
        </Provider>
        , document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})