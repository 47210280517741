const authentication = require('../../../react-azure-adb2c2').default;







export const list = (values: any) => {

  // const url = process.env.REACT_APP_API_BASE_URL + `/v1/seisan/follow/list`;

  const url = process.env.REACT_APP_API_PM_URL + `/v1/kanri/m/follows`;
  // let url = `http://localhost:5000/v1/kanri/m/follows`;
  const token = authentication.getAccessToken();
  // console.log(JSON.stringify(values))
  // alert(JSON.stringify(values))
  // alert(url)

  return fetch(url, {
    method: 'get',
    // body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));



  //  return  fetch(url, {
  //             method: 'get',
  //             // body: JSON.stringify(search),

  //             headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //         }).then(res => res.json())
  //         .then(payload => ({ payload }))
  //             .catch(error => ({ error }));
  //       return  fetch(url, {
  //       method: 'get',
  //       // body: JSON.stringify(accountinglistset),

  //       headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //   })
  //   .then(res => res.json())
  //   .then(payload => ({ payload }))
  //       .catch(error => ({ error }));






};






export const update = (values: any) => {
  const url = process.env.REACT_APP_API_BASE_URL + `/api/v1/kyousais/update`;
  // let url = `http://localhost:5000/api/v1/kyousais/update`;
  // alert(url)


  const token = authentication.getAccessToken();
  // alert(url)
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));



  //  return  fetch(url, {
  //             method: 'get',
  //             // body: JSON.stringify(search),

  //             headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //         }).then(res => res.json())
  //         .then(payload => ({ payload }))
  //             .catch(error => ({ error }));
  //       return  fetch(url, {
  //       method: 'get',
  //       // body: JSON.stringify(accountinglistset),

  //       headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //   })
  //   .then(res => res.json())
  //   .then(payload => ({ payload }))
  //       .catch(error => ({ error }));






};



export const delete_kyousais = (values: any) => {
  const url = process.env.REACT_APP_API_BASE_URL + `/api/v1/kyousais/deletekyousais`;
  // let url = `http://localhost:5000/api/v1/kyousais/deletekyousais`;


  // alert(url)

  const token = authentication.getAccessToken();
  // alert(url)
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));





};


export const upload_csv = (payload: any) => {

  var formData = new FormData();
  formData.append(payload.id, payload.file[0]);



  console.log(payload.file)
  console.log(formData.getAll(payload.id))
  // alert(payload.id)
  const url = process.env.REACT_APP_API_BASE_URL + `/api/v1/kyousais/uploadcsv`;
  // let url = `http://localhost:5000/api/v1/kyousais/uploadcsv`;
  // alert(url)

  const token = authentication.getAccessToken();
  // alert(JSON.stringify(formData))
  return fetch(url, {
    method: 'post',
    body: formData,

    headers: { 'Authorization': 'Bearer ' + token },

  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
}


// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }




export const matching = (values: any) => {
  const url = process.env.REACT_APP_API_BASE_URL + `/api/v1/kyousais/matching`;
  // let url = `http://localhost:5000/api/v1/kyousais/matching`;


  // alert(url)

  const token = authentication.getAccessToken();
  // alert(url)
  return fetch(url, {
    method: 'get',
    // body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};



export const downloadcsv = (values: any) => {


  const url = process.env.REACT_APP_API_BASE_URL + `/api/v1/kyousais`;
  // const url = `http://localhost:5000/api/v1/kyousais`;

  // let url = `https://localhost:44341/api/RentContractAccounting`;

  const token = authentication.getAccessToken();
  values.csv = 1

  // alert(JSON.stringify(accountinglistset))
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),

    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  }).then(response => response.blob())
    .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    .catch(error => ({ error }));






};