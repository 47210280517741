import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RequestDetail from './Saga/RequestDetail';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as RequestDetailSaga from './Saga/RequestDetail';
import { format } from 'date-fns'
import { Row, Col } from 'reactstrap';



const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


interface Props {
    edit_after_toggle: any,
    edittoggle: any
    get_tatemono: any,
    get_room: any,
    get_parking: any,
    get_parkingarea: any,
    Tatemono: any,
    Room: any,
    Parking: any,
    Parkingarea: any,
    shops: any,
    users_select: any,
    Gyousya: any,
    Outaisub: any,
    new_request: any,
    request_detail: any,
    user_details: any,
    outai_update: any,
    Outai_No: any,
    get_customers: any,
    customers: any,
    get_kaketsuke_kojin: any,
    kaketsuke_kojin: any,
    demo_date:any
}
interface State {
    checked: boolean,
    checked1: boolean,
    checked2: boolean,
    checked3: boolean,
    checked4: boolean,
    checked5: boolean,
    checked6: boolean,
    checked7: boolean,
    checked8: boolean,
    checked9: boolean,
    checked10: boolean,
    checked11: boolean,
    checked12: boolean,
    checked13: boolean,
    checked14: boolean,
    checked15: boolean,
    checked16: boolean,
    checked17: boolean,
    checked18: boolean,
    checked19: boolean,
    checked20: boolean,
    checked21: boolean,
    checked22: boolean,
    checked23: boolean,
    checked24: boolean,
    checked25: boolean,
    checked26: boolean,
    checked27: boolean,
    checked28: boolean,
    checked29: boolean,
    checked30: boolean,
    checked31: boolean,
    checked32: boolean,
    checked33: boolean,
    checked34: boolean,
    checked35: boolean,
    checked36: boolean,
    checked37: boolean,
    checked38: boolean,
    checked39: boolean,
    checked40: boolean,
    checked41: boolean,
    checked42: boolean,
    checked43: boolean,
    checked44: boolean,
    checked45: boolean,
    checked46: boolean,
    checked47: boolean,
    checked48: boolean,
    checked49: boolean,
    checked50: boolean,
    checked51: boolean,
    checked52: boolean,
    checked53: boolean,
    checked54: boolean,
    checked55: boolean,
    checked56: boolean,
    checked57: boolean,
    checked58: boolean,
    checked59: boolean,
    checked60: boolean,
    checked61: boolean,
    checked62: boolean,
    checked63: boolean,
    checked64: boolean,
    checked65: boolean,
    checked66: boolean,
    checked67: boolean,
    checked68: boolean,
    Tatemono_Id: any,
    Tatemono_Name: any,
    Room_Id: any,
    Room_Name: any,
    Kokyaku_Name: any,
    Renraku_Saki: any,
    Renraku_Kibou: any,
    gyousha_renraku: any,
    Outai_Joukyou_Name: any,
    Tatemono_or_P: any,
    Kokyaku_Kbn: any,
    Shokai_section_id: any,
    shokai_user: any,
    Tenpo_Name_Short: any
    Shokai_User_Id: any,
    taiou_user: any,
    taiou_tantou_user_id: any,
    Syokai_Date: any,
    Shokai_H: any,
    Shokai_M: any,
    Gyosha_Name: any,
    Gyosha2_Name: any,
    syokai_gyousha_no: any,
    second_gyousha_no: any,
    Title: any,
    Naiyou: any,
    remarks: any,
    OutaiDate: any,
    OutaiDate_H: any,
    OutaiDate_M: any,
    OutaiNaiyo: any,
    OutaiHoho_Name: any,
    OutaiHoho_Biko: any,
    OutaiTanto_Id: any,
    OutaiBiko: any,
    main_outai_sub: any,
    outai_sub_name: any,
    symbol: any,
    Keiyaku_No: any,
    complete_date: any,
    owner_houkoku: any,
    room_disp: boolean,
    type: any,
    second_date: any,
    Next_Time: any,
    Next_Date: any,
    check_count: any,
    symbol_name: any,

}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);



const kokyaku = [
    { label: "", key: "" },
    { label: "オーナー", key: "オーナー" },
    { label: "入居者", key: "入居者" },
    { label: "退去者", key: "退去者" },
    { label: "その他", key: "その他" },
];

var test = [
    { Tatemono_Name: "ああああ" },
    { Tatemono_Name: "おおおお" },
]

var taiou_joukyou = [
    { value: "応対中", key: "応対中" },
    { value: "完了", key: "完了" },
    { value: "一旦保留", key: "一旦保留" },
    { value: "業者対応完了", key: "業者対応完了" },
]

var outai_houhou = [
    { value: "電話" },
    { value: "FAX" },
    { value: "訪問" },
    { value: "来店" },
    { value: "通知文" },
    { value: "メール" },
    { value: "Teams" },
    { value: "傷汚れチェックシート受付" },
    { value: "その他" },
]

// var type = [
//     { value: "通常対応", key: "直接受付" },
//     { value: "UME", key: "UME" },
//     { value: "シード", key: "シード" },
//     { value: "駆付", key: "駆付" },
// ]

var type = [
    { value: "電話", key: "電話" },
    { value: "メール", key: "メール" },
    { value: "UME", key: "UME" },
    { value: "来店", key: "来店" },
    { value: "シード", key: "シード" },
    { value: "駆付", key: "駆付" },
    { value: "留守電", key: "留守電" },
    { value: "アプリ", key: "アプリ" },
]


var jikoku = [
    { value: '時刻未定' },
    { value: '午前' },
    { value: '正午' },
    { value: '午後' },
    { value: '夕方' },
    { value: '夜間' },
    { value: '8:00' },
    { value: '9:00' },
    { value: '10:00' },
    { value: '11:00' },
    { value: '12:00' },
    { value: '13:00' },
    { value: '14:00' },
    { value: '15:00' },
    { value: '16:00' },
    { value: '17:00' },
    { value: '18:00' },
    { value: '19:00' },
    { value: '20:00' },
    { value: '21:00' },
    { value: '22:00' },
    { value: '23:00' }
]


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        var data = {
            tatemono_id: props.demo_date[0].Tatemono_Id,
            room_id: props.demo_date[0].Room_Id,
        }
        this.props.get_customers(data);


        this.state = {
            checked: false,
            checked1: false,
            checked2: false,
            checked3: false,
            checked4: false,
            checked5: false,
            checked6: false,
            checked7: false,
            checked8: false,
            checked9: false,
            checked10: false,
            checked11: false,
            checked12: false,
            checked13: false,
            checked14: false,
            checked15: false,
            checked16: false,
            checked17: false,
            checked18: false,
            checked19: false,
            checked20: false,
            checked21: false,
            checked22: false,
            checked23: false,
            checked24: false,
            checked25: false,
            checked26: false,
            checked27: false,
            checked28: false,
            checked29: false,
            checked30: false,
            checked31: false,
            checked32: false,
            checked33: false,
            checked34: false,
            checked35: false,
            checked36: false,
            checked37: false,
            checked38: false,
            checked39: false,
            checked40: false,
            checked41: false,
            checked42: false,
            checked43: false,
            checked44: false,
            checked45: false,
            checked46: false,
            checked47: false,
            checked48: false,
            checked49: false,
            checked50: false,
            checked51: false,
            checked52: false,
            checked53: false,
            checked54: false,
            checked55: false,
            checked56: false,
            checked57: false,
            checked58: false,
            checked59: false,
            checked60: false,
            checked61: false,
            checked62: false,
            checked63: false,
            checked64: false,
            checked65: false,
            checked66: false,
            checked67: false,
            checked68: false,
            Tatemono_Id: props.demo_date[0].Tatemono_Id,
            Tatemono_Name: props.demo_date[0].Tatemono_Name ? props.demo_date[0].Tatemono_Name : props.demo_date[0].P_Name,
            Room_Id: props.demo_date[0].Room_Id,
            Room_Name: props.demo_date[0].Room_Name ? props.demo_date[0].Room_Name : props.demo_date[0].PA_Name,
            Kokyaku_Name: props.demo_date[0].Kokyaku_Name,
            Renraku_Saki: props.demo_date[0].Renraku_Saki,
            Renraku_Kibou: props.demo_date[0].Renraku_Kibou,
            gyousha_renraku: props.demo_date[0].gyousha_renraku,
            Outai_Joukyou_Name: props.demo_date[0].Outai_Joukyou_Name,
            Tatemono_or_P: props.demo_date[0].Tatemono_Name ? "建物" : "駐車場",
            Kokyaku_Kbn: props.demo_date[0].Kokyaku_Kbn,
            Shokai_section_id: props.demo_date[0].Shokai_section_id,
            Tenpo_Name_Short: props.demo_date[0].Tenpo_Name_Short,
            Shokai_User_Id: props.demo_date[0].Shokai_User_Id,
            shokai_user: props.demo_date[0].shokai_user,
            taiou_user: props.demo_date[0].taiou_user,
            taiou_tantou_user_id: props.demo_date[0].taiou_tantou_user_id,
            Syokai_Date: props.demo_date[0].Syokai_Date,
            Shokai_H: props.demo_date[0].Shokai_H,
            Shokai_M: props.demo_date[0].Shokai_M,
            Gyosha_Name: props.demo_date[0].Gyosha_Name,
            Gyosha2_Name: props.demo_date[0].Gyosha2_Name,
            syokai_gyousha_no: props.demo_date[0].syokai_gyousha_no,
            second_gyousha_no: props.demo_date[0].second_gyousha_no,
            Title: props.demo_date[0].Title,
            Naiyou: props.demo_date[0].Naiyou,
            remarks: props.demo_date[0].remarks,
            OutaiDate: new Date(),
            OutaiDate_H: 0,
            OutaiDate_M: 0,
            OutaiNaiyo: "",
            OutaiHoho_Name: "",
            OutaiHoho_Biko: "",
            OutaiTanto_Id: 0,
            OutaiBiko: "",
            main_outai_sub: props.demo_date[0].main_outai_sub,
            outai_sub_name: props.demo_date[0].outai_sub_name,
            symbol: props.demo_date[0].symbol,
            Keiyaku_No: props.demo_date[0].Keiyaku_No,
            owner_houkoku: props.demo_date[0].owner_houkoku,
            complete_date: props.demo_date[0].complete_date,
            type: props.demo_date[0].room_support_m_chat_id ? "アプリ" : props.demo_date[0].type == "通常対応" ? '直接受付' : props.demo_date[0].type,
            room_disp: true,
            second_date: props.demo_date[0].second_date,
            Next_Date: props.demo_date[0].Next_Date,
            Next_Time: props.demo_date[0].Next_Time,
            check_count: props.demo_date[0].symbol ? 1 : 0,
            symbol_name: "",


        }
        this.handleChange = this.handleChange.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.onChangeRoom = this.onChangeRoom.bind(this);
        this.onChangeKokyaku_Name = this.onChangeKokyaku_Name.bind(this);
        this.onChangeRenraku_Saki = this.onChangeRenraku_Saki.bind(this);
        this.onChangeRenraku_Kibou = this.onChangeRenraku_Kibou.bind(this);

        this.onChangegyousha_renraku = this.onChangegyousha_renraku.bind(this);
        this.onChangeOutai_Joukyou_Name = this.onChangeOutai_Joukyou_Name.bind(this);
        this.onChangeKokyaku_Kbn = this.onChangeKokyaku_Kbn.bind(this);
        this.onChangeTatemono_or_P = this.onChangeTatemono_or_P.bind(this);
        this.onChangeShokai_section_id = this.onChangeShokai_section_id.bind(this);
        this.onChangeShokai_User_Id = this.onChangeShokai_User_Id.bind(this);
        this.onChangetaiou_tantou_user_id = this.onChangetaiou_tantou_user_id.bind(this);
        this.onChangeShokai_H = this.onChangeShokai_H.bind(this);
        this.onChangeShokai_M = this.onChangeShokai_M.bind(this);
        this.onChangesyokai_gyousha_no = this.onChangesyokai_gyousha_no.bind(this);
        this.onChangesecond_gyousha_no = this.onChangesecond_gyousha_no.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeNaiyou = this.onChangeNaiyou.bind(this);
        this.onChangeremarks = this.onChangeremarks.bind(this);
        this.onChangeOutaiTanto_Id = this.onChangeOutaiTanto_Id.bind(this);
        this.onChangeOutaiHoho_Name = this.onChangeOutaiHoho_Name.bind(this);
        this.onChangeOutaiHoho_Biko = this.onChangeOutaiHoho_Biko.bind(this);
        this.onChangeOutaiNaiyo = this.onChangeOutaiNaiyo.bind(this);
        this.onChangeOutaiBiko = this.onChangeOutaiBiko.bind(this);
        this.onChangemain_outai_sub = this.onChangemain_outai_sub.bind(this);
        this.onChangecomplete_date = this.onChangecomplete_date.bind(this);
        this.onChangeowner_houkoku = this.onChangeowner_houkoku.bind(this);
        this.onChangeTatemonoName = this.onChangeTatemonoName.bind(this);
        this.onChange_type = this.onChange_type.bind(this);
        this.second_date = this.second_date.bind(this);
        this.shokai_delete = this.shokai_delete.bind(this);
        this.Next_Time = this.Next_Time.bind(this);

        this.save = this.save.bind(this);

    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });


        if (event.target.value !== '入居時リクエスト') {
            if (this.state.check_count == 0) {
                var subs = event.target.name.replace("checked", "")
                this.setState({
                    main_outai_sub: subs,
                    symbol_name: event.target.value,
                    symbol: ''
                });
            }
            if (event.target.checked == true) {
                this.setState({
                    check_count: this.state.check_count + 1
                });
            } else {
                if (this.state.check_count == 1) {
                    this.setState({
                        check_count: this.state.check_count - 1
                    });
                }
            }
        }


    };


    Next_Time(event: any, values: any) {
        if (values) {
            this.setState({
                Next_Time: values.value,
            });
        }
    }

    onChangeTatemono(event: any, values: any) {

        if (values) {
            if (this.state.Tatemono_or_P == "建物") {
                this.props.get_room(values.Tatemono_Id);
                this.setState({
                    Tatemono_Id: values.Tatemono_Id,
                    Room_Id: null,
                    Room_Name: "",
                    room_disp: false
                });

                setTimeout(() => {
                    this.setState({
                        Kokyaku_Name: "",
                        Renraku_Saki: "",
                        Keiyaku_No: "",
                        Room_Name: "",
                        room_disp: true
                    });
                }, 1000)

            } else {
                this.props.get_parkingarea(values.P_Id);
                this.setState({
                    Tatemono_Id: "SysP" + values.P_Id,
                    Room_Id: null,
                    Room_Name: "",
                    room_disp: false
                });
                setTimeout(() => {
                    this.setState({
                        room_disp: true
                    });
                }, 1000)
            }
        } else {
            this.setState({
                Tatemono_Id: null,
                Room_Id: null,
                Room_Name: "",
                Kokyaku_Name: "",
                Renraku_Saki: "",
                Keiyaku_No: "",
                room_disp: false
            });
            setTimeout(() => {
                this.setState({
                    room_disp: true
                });
            }, 1000)
        }
    }

    onChangeRoom(event: any, values: any) {
        if (values) {
            this.setState({
                Room_Id: values.Room_Id,
            });

            var data = {
                tatemono_id: this.state.Tatemono_Id,
                room_id: values.Room_Id
            }
            this.props.get_customers(data);

            setTimeout(() => {
                console.log("-----------------");
                console.log(this.props.customers);
                if (this.props.customers) {

                    // 携帯の電話番号をハイフンつきにする
                    if (this.props.customers.tel) {
                        const str = this.props.customers.tel;

                        console.log(str);
                        console.log(str.length);
                        if (str.length == 11) {
                            const a = str.slice(0, 3)
                            const b = '-'
                            const c = str.slice(3)
                            var str2 = a + b + c;
                            const d = str2.slice(-4)
                            var str3 = str2.split("-");
                            var str4 = str3[1].replace(d, "");
                            var str5 = str3[0] + "-" + str4 + "-" + d
                        } else {
                            str5 = "";
                        }
                    } else {
                        str5 = "";
                    }

                    this.setState({
                        Kokyaku_Name: this.props.customers.name,
                        Renraku_Saki: str5,
                        Keiyaku_No: this.props.customers.Keiyaku_No
                    });

                    this.props.get_kaketsuke_kojin({
                        Tatemono_Id: this.state.Tatemono_Id,
                        Room_Id: values.Room_Id,
                        nyuukyosha_name: this.props.customers.name
                    });
                } else {
                    this.setState({
                        Kokyaku_Name: "",
                        Renraku_Saki: "",
                        Keiyaku_No: "",
                    });
                }
            }, 1000)

        } else {
            this.setState({
                Room_Id: null
            });
        }
    }

    shokai_delete() {
        this.setState({
            Syokai_Date: null,
            Shokai_M: null,
            Shokai_H: null,
        });
    }

    onChangeTatemonoName(e: any) {
        this.setState({
            Tatemono_Name: e.target.value,
            Room_Name: "",
            Room_Id: null
        });
    }

    onChangeKokyaku_Name(e: any) {
        this.setState({
            Kokyaku_Name: e.target.value,
        });
    }

    onChangeRenraku_Saki(e: any) {
        this.setState({
            Renraku_Saki: e.target.value,
        });
    }

    onChangeRenraku_Kibou(e: any) {
        this.setState({
            Renraku_Kibou: e.target.value,
        });
    }

    onChangegyousha_renraku(e: any) {
        this.setState({
            gyousha_renraku: e.target.value,
        });
    }

    onChangeOutai_Joukyou_Name(event: any, values: any) {
        if (values) {
            this.setState({
                Outai_Joukyou_Name: values.value,
            });
        }
    }
    onChange_type(event: any, values: any) {
        this.setState({
            type: values.value,
        });
    }

    second_date = (date: any) => {
        this.setState({
            Next_Date: new Date(date),
        });
    };

    onChangeTatemono_or_P(e: any) {

        this.setState({
            Tatemono_or_P: e.target.value,
        });

        if (e.target.value == "駐車場") {

            // 駐車場の一覧を取る
            this.props.get_parking();

        } else {

            // 建物の一覧を取る
            this.props.get_tatemono();

        }
    }

    onChangeKokyaku_Kbn(event: any, values: any) {
        if (values) {
            this.setState({
                Kokyaku_Kbn: values.label,
            });
        } else {
            this.setState({
                Kokyaku_Kbn: null,
            });
        }
    }


    onChangeShokai_section_id(event: any, values: any) {
        this.setState({
            Shokai_section_id: values.tenpoNo,
        });
    }

    onChangeShokai_User_Id(event: any, values: any) {
        this.setState({
            Shokai_User_Id: values.value,
        });
    }

    onChangetaiou_tantou_user_id(event: any, values: any) {
        if (values) {
            this.setState({
                taiou_tantou_user_id: values.value,
            });
        } else {
            this.setState({
                taiou_tantou_user_id: null,
            });
        }
    }

    onChangeSyokai_Date = (date: any) => {
        this.setState({
            Syokai_Date: new Date(date),
            OutaiDate: new Date(date),
        });
    };

    onChangeShokai_H(e: any) {
        var time = e.target.value;
        if (e.target.value < 0) {
            time = 23;
        } else if (e.target.value > 23) {
            time = 0;
        }
        this.setState({
            Shokai_H: time,
            OutaiDate_H: time,
        });
    }

    onChangeShokai_M(e: any) {
        var time = e.target.value;
        if (e.target.value < 0) {
            time = 59;
        } else if (e.target.value > 59) {
            time = 0;
        }
        this.setState({
            Shokai_M: time,
            OutaiDate_M: time,
        });
    }

    onChangeowner_houkoku = (date: any) => {
        this.setState({
            owner_houkoku: new Date(date),
        });
    };

    onChangecomplete_date = (date: any) => {
        this.setState({
            complete_date: new Date(date),
        });
    };

    onChangesyokai_gyousha_no(event: any, values: any) {
        if (values) {
            this.setState({
                syokai_gyousha_no: values.Gyosha_No,
            });
        }
    }

    onChangesecond_gyousha_no(event: any, values: any) {
        this.setState({
            second_gyousha_no: values.Gyosha_No,
        });
    }


    onChangeTitle(e: any) {
        this.setState({
            Title: e.target.value,
        });
    }

    onChangeNaiyou(e: any) {
        this.setState({
            Naiyou: e.target.value,
        });
    }


    onChangeremarks(e: any) {
        this.setState({
            remarks: e.target.value,
        });
    }

    onChangeOutaiTanto_Id(event: any, values: any) {
        if (values) {
            this.setState({
                OutaiTanto_Id: values.value,
            });
        } else {
            this.setState({
                OutaiTanto_Id: null,
            });
        }
    }

    onChangeOutaiHoho_Name(event: any, values: any) {
        this.setState({
            OutaiHoho_Name: values.value,
        });
    }

    onChangeOutaiHoho_Biko(e: any) {
        this.setState({
            OutaiHoho_Biko: e.target.value,
        });
    }

    onChangeOutaiNaiyo(e: any) {
        this.setState({
            OutaiNaiyo: e.target.value,
        });
    }

    onChangeOutaiBiko(e: any) {
        this.setState({
            OutaiBiko: e.target.value,
        });
    }

    onChangemain_outai_sub(event: any, values: any) {
        if (values) {
            this.setState({
                main_outai_sub: values.id,
                symbol_name: '',
                symbol: '',
                outai_sub_name: values.name
            });
        }
    }



    save() {

        alert("※デモ画面のため、登録はされません");
        return;


        let checked = [{
            checked: this.state.checked1
        }, {
            checked: this.state.checked2
        }, {
            checked: this.state.checked3
        }, {
            checked: this.state.checked4
        }, {
            checked: this.state.checked5
        }, {
            checked: this.state.checked6
        }, {
            checked: this.state.checked7
        }, {
            checked: this.state.checked8
        }, {
            checked: this.state.checked9
        }, {
            checked: this.state.checked10
        }, {
            checked: this.state.checked11
        }, {
            checked: this.state.checked12
        }, {
            checked: this.state.checked13
        }, {
            checked: this.state.checked14
        }, {
            checked: this.state.checked15
        }, {
            checked: this.state.checked16
        }, {
            checked: this.state.checked17
        }, {
            checked: this.state.checked18
        }, {
            checked: this.state.checked19
        }, {
            checked: this.state.checked20
        }, {
            checked: this.state.checked21
        }, {
            checked: this.state.checked22
        }, {
            checked: this.state.checked23
        }, {
            checked: this.state.checked24
        }, {
            checked: this.state.checked25
        }, {
            checked: this.state.checked26
        }, {
            checked: this.state.checked27
        }, {
            checked: this.state.checked28
        }, {
            checked: this.state.checked29
        }, {
            checked: this.state.checked30
        }, {
            checked: this.state.checked31
        }, {
            checked: this.state.checked32
        }, {
            checked: this.state.checked33
        }, {
            checked: this.state.checked34
        }, {
            checked: this.state.checked35
        }, {
            checked: this.state.checked36
        }, {
            checked: this.state.checked37
        }, {
            checked: this.state.checked38
        }, {
            checked: this.state.checked39
        }, {
            checked: this.state.checked40
        }, {
            checked: this.state.checked41
        }, {
            checked: this.state.checked42
        }, {
            checked: this.state.checked43
        }, {
            checked: this.state.checked44
        }, {
            checked: this.state.checked45
        }, {
            checked: this.state.checked46
        }, {
            checked: this.state.checked47
        }, {
            checked: this.state.checked48
        }, {
            checked: this.state.checked49
        }, {
            checked: this.state.checked50
        }, {
            checked: this.state.checked51
        }, {
            checked: this.state.checked52
        }, {
            checked: this.state.checked53
        }, {
            checked: this.state.checked54
        }, {
            checked: this.state.checked55
        }, {
            checked: this.state.checked56
        }, {
            checked: this.state.checked57
        }, {
            checked: this.state.checked58
        }, {
            checked: this.state.checked59
        }, {
            checked: this.state.checked60
        }, {
            checked: this.state.checked61
        }, {
            checked: this.state.checked62
        }, {
            checked: this.state.checked63
        }, {
            checked: this.state.checked64
        }, {
            checked: this.state.checked65
        }, {
            checked: this.state.checked66
        }, {
            checked: this.state.checked67
        }];




        var checkers: number[] = [];

        var i = 1;
        checked.forEach(element => {
            if (element.checked == true) {
                checkers.push(i);
            }
            i++;
        });

        console.log(checkers);




        var data =
        {
            new: {
                Outai_No: this.props.Outai_No,
                Kokyaku_Kbn: this.state.Kokyaku_Kbn,
                Kokyaku_Name: this.state.Kokyaku_Name,
                Renraku_Saki: this.state.Renraku_Saki,
                Renraku_Kibou: this.state.Renraku_Kibou,
                Shokai_User_Id: this.state.Shokai_User_Id,
                Syokai_Date: this.state.Syokai_Date,
                Shokai_H: this.state.Shokai_H,
                Shokai_M: this.state.Shokai_M,
                Tatemono_Id: this.state.Tatemono_or_P == "建物" ? this.state.Tatemono_Id : this.state.Tatemono_Id,
                // Room_Id: this.state.Tatemono_or_P == "建物" ? this.state.Room_Id : this.state.Room_Id,
                Room_Id: this.state.Tatemono_or_P == "建物" ? this.state.Room_Id : "SysP" + this.state.Room_Id,
                Outai_Joukyou_Name: this.state.Outai_Joukyou_Name,
                Title: this.state.Title,
                Naiyou: this.state.Naiyou,
                main_outai_sub: this.state.main_outai_sub,
                updated_at: new Date(),
                updated_user_id: this.props.user_details.id,
                taiou_tantou_user_id: this.state.taiou_tantou_user_id,
                remarks: this.state.remarks,
                syokai_gyousha_no: this.state.syokai_gyousha_no,
                Shokai_section_id: this.state.Shokai_section_id,
                gyousha_renraku: this.state.gyousha_renraku,
                Keiyaku_No: this.state.Keiyaku_No,
                complete_date: this.state.complete_date,
                owner_houkoku: this.state.owner_houkoku,
                type: this.state.type,
                second_date: this.state.second_date,
                Next_Date: this.state.Next_Date,
                Next_Time: this.state.Next_Time
            },
            sub: {
                checkers
            }
        }


        console.log(data);

        this.props.outai_update(data);

        alert("登録しました");
        this.props.edit_after_toggle();


    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Row>
                        <Card className="col-lg-6" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">
                                <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>

                                    {this.props.customers.Group_Naiyou_No ?
                                        <span className="label" style={{ float: "left", backgroundColor: "green" }}>
                                            かけつけ</span> : ""}

                                    {
                                        this.props.kaketsuke_kojin[0] ?

                                            this.props.kaketsuke_kojin[0]['keiyaku_type'] == 3 ?
                                                <span className="label" style={{ float: "left", backgroundColor: "#90ee90" }}>
                                                    シード？</span>
                                                :

                                                <span className="label" style={{ float: "left", backgroundColor: "green" }}>
                                                    かけつけ</span> : ""}


                                    {this.props.demo_date[0].Group_Naiyou_No && this.props.demo_date[0].Tatemono_Id == this.state.Tatemono_Id ?
                                        <span className="label" style={{ float: "left", backgroundColor: "green" }}>
                                            かけつけ</span> : ""}

                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                {this.state.Tatemono_or_P == "建物" ?
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        defaultValue={{ Tatemono_Name: this.state.Tatemono_Name, Tatemono_Id: this.state.Tatemono_Id, Tatemono_Kana: this.props.demo_date[0].Tatemono_Kana }}
                                                        options={this.props.Tatemono}
                                                        getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"}
                                                        style={{ width: 400, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangeTatemono}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="建物名 (カナ)" />}
                                                    /> :
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.props.Parking}
                                                        defaultValue={{ P_Name: this.state.Tatemono_Name, P_Id: this.state.Tatemono_Id, P_Kana: this.props.demo_date[0].P_Kana }}
                                                        getOptionLabel={(option: any) => option.P_Name + " (" + option.P_Kana + ")"}
                                                        style={{ width: 400, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangeTatemono}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="駐車場名 (カナ)" />}
                                                    />}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            {this.state.room_disp ?
                                                <td colSpan={1} style={{ border: "0px" }}>
                                                    {this.state.Tatemono_or_P == "建物" ?
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={this.props.Room}
                                                            defaultValue={{ Room_Name: this.state.Room_Name, Room_Id: this.state.Room_Id }}
                                                            getOptionLabel={(option: any) => option.Room_Name}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeRoom}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="部屋名" />}
                                                        /> :
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={this.props.Parkingarea}
                                                            defaultValue={{ Room_Name: this.state.Room_Name, Room_Id: this.state.Room_Id }}
                                                            getOptionLabel={(option: any) => option.Room_Name}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeRoom}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="区画名" />}
                                                        />
                                                    }
                                                </td> : ""}
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.Kokyaku_Name}
                                                    onChange={this.onChangeKokyaku_Name} style={{ width: 400 }} id="standard-basic" label="顧客名" />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.Renraku_Saki}
                                                    onChange={this.onChangeRenraku_Saki} style={{ width: 400 }} id="standard-basic" label="連絡先" />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.Renraku_Kibou}
                                                    onChange={this.onChangeRenraku_Kibou} style={{ width: 400 }} id="standard-basic" label="連絡希望時間" />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <RadioGroup onChange={this.onChangegyousha_renraku} row aria-label="position" name="position">
                                                    <FormControlLabel
                                                        checked={this.state.gyousha_renraku == 1}
                                                        value="1"
                                                        control={<Radio color="primary" />}
                                                        label="業者連絡可能"
                                                    />
                                                    <FormControlLabel
                                                        checked={this.state.gyousha_renraku == 2}
                                                        value="2"
                                                        control={<Radio color="primary" />}
                                                        label="不可"
                                                    />
                                                    <FormControlLabel
                                                        checked={this.state.gyousha_renraku == 0}
                                                        value="0"
                                                        control={<Radio color="primary" />}
                                                        label="未確認"
                                                    />
                                                    <FormControlLabel
                                                        checked={this.state.gyousha_renraku == 3}
                                                        value="3"
                                                        control={<Radio color="primary" />}
                                                        label="報告不要"
                                                    />
                                                </RadioGroup>
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            {/* {this.props.demo_date[0].room_support_m_chat_id ? "" :
                                                <td colSpan={1} style={{ border: "0px" }}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={taiou_joukyou}
                                                        defaultValue={{ value: this.state.Outai_Joukyou_Name, key: this.state.Outai_Joukyou_Name }}
                                                        getOptionLabel={(option: any) => option.value}
                                                        style={{ width: 200, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangeOutai_Joukyou_Name}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="対応状況" />}
                                                    />
                                                </td>} */}




                                            {/* <td colSpan={1} style={{ border: "0px" }}>
                                               ああああ <Autocomplete
                                                    id="combo-box-demo"
                                                    defaultValue={{ value: this.state.type, key: this.state.type }}
                                                    options={type}
                                                    getOptionLabel={(option: any) => option.key}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChange_type}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受付方法" />}
                                                />
                                            </td> */}

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    defaultValue={{ value: this.state.taiou_tantou_user_id, label: this.state.taiou_user }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangetaiou_tantou_user_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="対応担当" />}
                                                />
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>

                                                {/* {this.props.demo_date[0].room_support_m_chat_id ? "" :
                                                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                        <KeyboardDatePicker

                                                            variant="inline"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label="対応完了日"
                                                            format="yyyy/MM/dd"
                                                            autoOk={true}
                                                            value={this.state.complete_date}
                                                            onChange={this.onChangecomplete_date}
                                                            style={{ width: 130 }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>}

                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker

                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="オーナー報告日"
                                                        format="yyyy/MM/dd"
                                                        autoOk={true}
                                                        value={this.state.owner_houkoku}
                                                        onChange={this.onChangeowner_houkoku}
                                                        style={{ width: 130, marginLeft: 10 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="col-lg-5" style={{ border: "0px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <RadioGroup onChange={this.onChangeTatemono_or_P} row aria-label="position" name="position" defaultValue="top">
                                                    <FormControlLabel
                                                        checked={this.state.Tatemono_or_P === '建物'}
                                                        value="建物"
                                                        control={<Radio color="primary" />}
                                                        label="建物"
                                                    />
                                                    <FormControlLabel
                                                        checked={this.state.Tatemono_or_P === '駐車場'}
                                                        value="駐車場"
                                                        control={<Radio color="primary" />}
                                                        label="駐車場"
                                                    />
                                                </RadioGroup>
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={kokyaku}
                                                    defaultValue={{ label: this.state.Kokyaku_Kbn, key: this.state.Kokyaku_Kbn }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200 }}
                                                    onChange={this.onChangeKokyaku_Kbn}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="顧客区分" />}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.shops}
                                                    defaultValue={{ tenpoNo: this.state.Shokai_section_id, tenpoName: this.state.Tenpo_Name_Short }}
                                                    getOptionLabel={(option: any) => option.tenpoName}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeShokai_section_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受電部署" />}
                                                />
                                            </td>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    defaultValue={{ value: this.state.Shokai_User_Id, label: this.state.shokai_user }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeShokai_User_Id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受電者" />}
                                                />
                                            </td>


                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            {/* <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    defaultValue={{ value: this.state.taiou_tantou_user_id, label: this.state.taiou_user }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangetaiou_tantou_user_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="対応担当" />}
                                                />
                                            </td> */}

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    defaultValue={{ value: this.state.type, key: this.state.type }}
                                                    options={type}
                                                    getOptionLabel={(option: any) => option.key}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChange_type}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受付方法" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker

                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="初回対応日"
                                                        format="yyyy/MM/dd"
                                                        autoOk={true}
                                                        value={this.state.Syokai_Date}
                                                        onChange={this.onChangeSyokai_Date}
                                                        style={{ width: 130 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>

                                                <TextField
                                                    autoComplete='off'
                                                    id="standard-number"
                                                    label="時"
                                                    type="number"
                                                    value={this.state.Shokai_H}
                                                    onChange={this.onChangeShokai_H}
                                                    style={{ width: 30, marginTop: 16 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <TextField
                                                    autoComplete='off'
                                                    id="standard-number"
                                                    label="分"
                                                    type="number"
                                                    value={this.state.Shokai_M}
                                                    onChange={this.onChangeShokai_M}
                                                    style={{ width: 30, marginTop: 16 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <i onClick={this.shokai_delete} style={{ paddingTop: 40, cursor: "pointer" }} className="fas fa-times"></i>


                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            {this.state.syokai_gyousha_no !== 0 ?
                                                <td colSpan={1} style={{ border: "0px" }}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.props.Gyousya}
                                                        defaultValue={{ Gyosha_No: this.state.syokai_gyousha_no, Gyosha_Name: this.state.Gyosha_Name }}
                                                        getOptionLabel={(option: any) => option.Gyosha_Name}
                                                        style={{ width: 200, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangesyokai_gyousha_no}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="初回手配業者" />}
                                                    />
                                                </td>
                                                :
                                                <td colSpan={1} style={{ border: "0px" }}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.props.Gyousya}
                                                        // defaultValue={{ Gyosha_No: this.state.syokai_gyousha_no, Gyosha_Name: this.state.Gyosha_Name }}
                                                        getOptionLabel={(option: any) => option.Gyosha_Name}
                                                        style={{ width: 200, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangesyokai_gyousha_no}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="初回手配業者" />}
                                                    />
                                                </td>}

                                            <td colSpan={1} style={{ border: "0px" }}>
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Gyousya}
                                                    defaultValue={{ Gyosha_No: this.state.second_gyousha_no, Gyosha_Name: this.state.Gyosha2_Name }}
                                                    getOptionLabel={(option: any) => option.Gyosha_Name}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangesecond_gyousha_no}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="次回手配業者" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker
                                                        onChange={this.second_date}
                                                        value={this.state.Next_Date}
                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="次回対応日"
                                                        format="yyyy/MM/dd"
                                                        style={{ width: 130 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>

                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={jikoku}
                                                    defaultValue={{ value: this.state.Next_Time }}
                                                    getOptionLabel={(option: any) => option.value}
                                                    style={{ width: 100 }}
                                                    onChange={this.Next_Time}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} />}
                                                />

                                            </td>


                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>

                            <CardContent style={{ float: "left" }} className="col-lg-12">

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="案件タイトル"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    variant="outlined"
                                    defaultValue={this.state.Title}
                                    onChange={this.onChangeTitle}
                                    style={{ marginTop: "10px" }}
                                />

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="案件内容"
                                    fullWidth
                                    multiline
                                    rows={6}
                                    variant="outlined"
                                    defaultValue={this.state.Naiyou}
                                    onChange={this.onChangeNaiyou}
                                    style={{ marginTop: "10px" }}
                                />

                                <div>
                                    <TextField
                                        autoComplete='off'
                                        id="outlined-read-only-input"
                                        label="業務課備考"
                                        fullWidth
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        defaultValue={this.state.remarks}
                                        onChange={this.onChangeremarks}
                                        style={{ marginTop: "10px" }}
                                    />
                                </div>
                            </CardContent>
                        </Card>




                        <Card className="col-lg-6" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.Outaisub}
                                        value={{ symbol: this.state.symbol, name: this.state.symbol_name == '' ? this.state.outai_sub_name : this.state.symbol_name }}
                                        // defaultValue={{ id: this.state.main_outai_sub, symbol: this.state.symbol, name: this.state.symbol_name !== '' ? this.state.outai_sub_name : this.state.symbol_name }}
                                        getOptionLabel={(option: any) =>
                                            // option.symbol == "入居時リクエスト" ? "" : option.symbol + ":" + option.name
                                            option.symbol + ":" + option.name
                                        }
                                        style={{ width: 500 }}
                                        onChange={this.onChangemain_outai_sub}
                                        renderInput={(params) => <TextField
                                            autoComplete='off' {...params} label="メイン区分" />}
                                    />
                                </div>

                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>

                                            <td style={{ border: "0px", backgroundColor: "#fff4c8", fontWeight: "bold" }}>
                                                <div>入</div>
                                                <div>居</div>
                                                <div>時</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </td>
                                            <td colSpan={1} style={{ border: "0px", backgroundColor: "#ffffe0" }}>
                                                <div>※入居1か月以内のリクエストの場合はチェック</div>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 210 }}
                                                    control={<GreenCheckbox checked={this.state.checked29} onChange={this.handleChange} name="checked29" value="入居時リクエスト" />}
                                                    label="設備"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 210 }}
                                                    control={<GreenCheckbox checked={this.state.checked30} onChange={this.handleChange} name="checked30" value="入居時リクエスト" />}
                                                    label="人的"
                                                />

                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#edfbff" }}>
                                                <div>設</div>
                                                <div>備</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </th>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#edfbff" }}>【共用部】</h5>



                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked1} onChange={this.handleChange} name="checked1" value="共用灯" />}
                                                    label="共用灯"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked31} onChange={this.handleChange} name="checked31" value="分電盤" />}
                                                    label="分電盤"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked2} onChange={this.handleChange} name="checked2" value="ポスト・宅配BOX" />}
                                                    label="ポスト・宅配BOX"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked32} onChange={this.handleChange} name="checked32" value="消防・警報機" />}
                                                    label="消防・警報機"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked4} onChange={this.handleChange} name="checked4" value="漏水 給排水管" />}
                                                    label="漏水 給排水管"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked33} onChange={this.handleChange} name="checked33" value="排水詰まり・溢れ" />}
                                                    label="排水詰まり・溢れ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked34} onChange={this.handleChange} name="checked34" value="受水槽・ポンプ" />}
                                                    label="受水槽・ポンプ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked35} onChange={this.handleChange} name="checked35" value="浄化槽・ブロワー" />}
                                                    label="浄化槽・ブロワー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked36} onChange={this.handleChange} name="checked36" value="マンホール" />}
                                                    label="マンホール"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked37} onChange={this.handleChange} name="checked37" value="植栽・除草" />}
                                                    label="植栽・除草"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked38} onChange={this.handleChange} name="checked38" value="外装関係" />}
                                                    label="外装関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked39} onChange={this.handleChange} name="checked39" value="清掃" />}
                                                    label="清掃"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked3} onChange={this.handleChange} name="checked3" value="ゴミ集積所" />}
                                                    label="ゴミ集積所"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked6} onChange={this.handleChange} name="checked6" value="その他" />}
                                                    label="その他"
                                                />

                                            </td>
                                        </tr>


                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#e0f4ff" }}>
                                                <div>設</div>
                                                <div>備</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </th>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#e0f4ff" }}>【居室内】</h5>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked7} onChange={this.handleChange} name="checked7" value="エアコン" />}
                                                    label="エアコン"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked8} onChange={this.handleChange} name="checked8" value="建具" />}
                                                    label="建具"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked41} onChange={this.handleChange} name="checked41" value="網戸" />}
                                                    label="網戸"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked42} onChange={this.handleChange} name="checked42" value="水栓(漏水)" />}
                                                    label="水栓(漏水)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked43} onChange={this.handleChange} name="checked43" value="水栓(漏水以外)" />}
                                                    label="水栓(漏水以外)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked10} onChange={this.handleChange} name="checked10" value="給水管漏水" />}
                                                    label="給水管漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked44} onChange={this.handleChange} name="checked44" value="排水管漏水" />}
                                                    label="排水管漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked45} onChange={this.handleChange} name="checked45" value="天井・階下漏水" />}
                                                    label="天井・階下漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked46} onChange={this.handleChange} name="checked46" value="雨漏り" />}
                                                    label="雨漏り"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked47} onChange={this.handleChange} name="checked47" value="排水臭" />}
                                                    label="排水臭"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked11} onChange={this.handleChange} name="checked11" value="排水詰まり" />}
                                                    label="排水詰まり"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked48} onChange={this.handleChange} name="checked48" value="給湯機（電気・ガス）" />}
                                                    label="給湯機（電気・ガス）"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked12} onChange={this.handleChange} name="checked12" value="ガスコンロ(IH含)" />}
                                                    label="ガスコンロ(IH含)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked13} onChange={this.handleChange} name="checked13" value="換気扇" />}
                                                    label="換気扇"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked14} onChange={this.handleChange} name="checked14" value="照明" />}
                                                    label="照明"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked15} onChange={this.handleChange} name="checked15" value="テレビ" />}
                                                    label="テレビ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked16} onChange={this.handleChange} name="checked16" value="インターネット" />}
                                                    label="インターネット"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked49} onChange={this.handleChange} name="checked49" value="トイレ(便器・タンク)" />}
                                                    label="トイレ(便器・タンク)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked50} onChange={this.handleChange} name="checked50" value="温水洗浄便座" />}
                                                    label="温水洗浄便座"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked51} onChange={this.handleChange} name="checked51" value="浴室" />}
                                                    label="浴室"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked52} onChange={this.handleChange} name="checked52" value="洗面" />}
                                                    label="洗面"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked53} onChange={this.handleChange} name="checked53" value="ガラス" />}
                                                    label="ガラス"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked9} onChange={this.handleChange} name="checked9" value="バルコニー" />}
                                                    label="バルコニー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked55} onChange={this.handleChange} name="checked55" value="インターフォン・チャイム" />}
                                                    label="インターフォン・チャイム"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked56} onChange={this.handleChange} name="checked56" value="カーテンレール" />}
                                                    label="カーテンレール"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked57} onChange={this.handleChange} name="checked57" value="コンセント・スイッチ" />}
                                                    label="コンセント・スイッチ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked58} onChange={this.handleChange} name="checked58" value="ブレーカー" />}
                                                    label="ブレーカー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked59} onChange={this.handleChange} name="checked59" value="レンタル家電" />}
                                                    label="レンタル家電"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked60} onChange={this.handleChange} name="checked60" value="内装関係" />}
                                                    label="内装関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked61} onChange={this.handleChange} name="checked61" value="美装" />}
                                                    label="美装"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked17} onChange={this.handleChange} name="checked17" value="その他" />}
                                                    label="その他"
                                                />
                                            </td>
                                        </tr>




                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#e0f4ff" }}>
                                                <div>人</div>
                                                <div>的</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </th>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#e0f4ff" }}>　</h5>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked18} onChange={this.handleChange} name="checked18" value="騒音" />}
                                                    label="騒音"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked19} onChange={this.handleChange} name="checked19" value="ゴミ分別" />}
                                                    label="ゴミ分別"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked62} onChange={this.handleChange} name="checked62" value="ペット躾" />}
                                                    label="ペット躾"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked20} onChange={this.handleChange} name="checked20" value="小動物" />}
                                                    label="小動物"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked21} onChange={this.handleChange} name="checked21" value="鍵関係" />}
                                                    label="鍵関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked22} onChange={this.handleChange} name="checked22" value="遊び・たむろ" />}
                                                    label="遊び・たむろ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked23} onChange={this.handleChange} name="checked23" value="不審者" />}
                                                    label="不審者"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked24} onChange={this.handleChange} name="checked24" value="害虫" />}
                                                    label="害虫"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked25} onChange={this.handleChange} name="checked25" value="迷惑駐車" />}
                                                    label="迷惑駐車"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked26} onChange={this.handleChange} name="checked26" value="自転車・バイク" />}
                                                    label="自転車・バイク"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked63} onChange={this.handleChange} name="checked63" value="捜査協力" />}
                                                    label="捜査協力"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked64} onChange={this.handleChange} name="checked64" value="安否確認" />}
                                                    label="安否確認"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked65} onChange={this.handleChange} name="checked65" value="事故" />}
                                                    label="事故"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked66} onChange={this.handleChange} name="checked66" value="問合せ・質問" />}
                                                    label="問合せ・質問"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked27} onChange={this.handleChange} name="checked27" value="その他" />}
                                                    label="その他"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked67} onChange={this.handleChange} name="checked67" value="保険・更新" />}
                                                    label="保険・更新"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked68} onChange={this.handleChange} name="checked68" value="カウント無し" />}
                                                    label="カウント無し"
                                                />

                                            </td>
                                        </tr>



                                    </tbody>
                                </table>


                            </CardContent>
                        </Card>
                    </Row>


                </div>



                <div style={{ float: "right", marginBottom: "10px", }}>

                    <Button
                        style={{
                            marginRight: "20px",
                            color: "#fff",
                            backgroundColor: "#5cb85c",
                            borderColor: "#4cae4c",
                            width: "200px"
                        }}
                        onClick={this.save}
                        variant="outlined" color="primary">
                        登録
          </Button>
                </div>


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        Parking: state.Adjustment.Parking,
        Parkingarea: state.Adjustment.Parkingarea,
        Gyousya: state.Adjustment.Gyousya,
        Outaisub: state.Adjustment.Outaisub,

        shops: state.masterReducer.shops,
        users_select: state.masterReducer.users_select,
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        customers: state.Adjustment.customers,
        kaketsuke_kojin: state.Adjustment.kaketsuke_kojin,


    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_tatemono(state: any) {
            dispatch(Adjustment.get_tatemono(state))
        },
        get_parking(state: any) {
            dispatch(Adjustment.get_parking(state))
        },
        get_parkingarea(state: any) {
            dispatch(Adjustment.get_parkingarea(state))
        },
        new_request(state: any) {
            dispatch(Adjustment.new_request(state))
        },
        outai_update(state: any) {
            dispatch(RequestDetailSaga.outai_update(state))
        },
        get_customers(state: any) {
            dispatch(Adjustment.get_customers(state))
        },
        get_kaketsuke_kojin(state: any) {
            dispatch(Adjustment.get_kaketsuke_kojin(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

