import React from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';

import * as Chat from './Saga/Chat';
var moment = require('moment-timezone');
const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    width: 250px;
    border-radius: 12px;
    background: White;
    margin-bottom: 2px;
    text-align: left;
`;

const Description = styled.div`
    font-size:10px;
`;
const List = styled.div`
    padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;width:250px;margin:5px;margin-left:-10px !important;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

class ChatText extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };
    }

    render() {
        return (
            <Balloon className="col-lg-12">
                <Chatting>
                    <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
                    <Says>
                        {this.props.msg.message_text}
                        {console.log("bbbbbbbbbbbbbbbbbbbbbbbbb")}
                        {console.log(this.props.msg)}
                        <Description>下記よりファイルをダウンロードしてください</Description>
                        <List className="row">
                            <div className="col-lg-12" style={{ fontWeight: "bold" }} onClick={() => this.props.download_chat_file(this.props.msg)}>ダウンロード</div>
                        </List>

                    </Says>
                </Chatting>
            </Balloon>

        )
    }
}

//コンテナ
const mapStateToProps = state => {
    return {


    }
}
function mapDispatchToProps(dispatch) {
    return {

        download_chat_file(state) {
            dispatch(Chat.download_chat_file(state))
        },


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatText);