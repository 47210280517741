import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router'
import logger from 'redux-logger';
// import rootSaga from './sagaes';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import * as Mail from './Components/Mail/Saga/Mail';
import * as Line from './Components/Line/Saga/Line';
import * as Chat from './Components/Chat/Saga/Chat';
import * as Sms from './Components/Sms/Saga/Sms';

import * as Adjustment from './Components/Adjustment/Saga/Adjustment';

import * as Master from './Store/Master';
import * as RentMoney from './Components/RentMoney/Saga/RentMoney';
import * as KyDetail from './Components/KyDetail/Saga/KyDetail';
import * as Rooms from './Components/Rooms/Saga/Rooms';
import * as Owner from './Components/Owner/Saga/Owner';

import * as Vacancy from './Components/Vacancy/Saga/Vacancy';

import * as Kys from './Components/Kys/Saga/Kys';

import * as Board from './Components/Board/Saga/Board';
import * as RequestDetail from './Components/RequestDetail/Saga/RequestDetail';
import * as Keiyaku from './Components/Keiyaku/Saga/Keiyaku';
import * as MessageList from './Components/MessageList/Saga/MessageList';
import * as MessageShare from './Components/MessageShare/Saga/MessageShare';
import * as CustomerLink from './Components/CustomerLink/Saga/CustomerLink';
import * as ContractsList from './Components/ContractsList/Saga/ContractsList';

import * as Calendar from './Components/Calendar/Saga/Calendar';

import * as Scenario from './Components/Scenario/Saga/Scenario';

// import * as Detail from './Components/Detail/Saga/Detail';
// import * as Rent from './Components/Rent/Saga/Rent';
// import * as Coming from './Components/Coming/Saga/Coming';

// import * as Reserve from './Components/Reserve/Saga/Reserve';
// import * as Monies from './Components/Accounting/Saga/Monies';

import { all } from 'redux-saga/effects';



export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const reducers = {

    masterReducer: Master.masterReducer,
    mailReducer: Mail.mailReducer,
    Adjustment: Adjustment.adjustmentReducer,
    RentMoney: RentMoney.rentmoneyReducer,
    KyDetail: KyDetail.kydetailReducer,
    roomsReducer: Rooms.roomsReducer,
    // rentContractMoniesReducer:Monies.rentContractMoniesReducer,
    line: Line.lineReducer,
    chat: Chat.chatReducer,
    sms: Sms.smsReducer,
    owner: Owner.ownerReducer,
    vacancy: Vacancy.vacancyReducer,
    kys: Kys.kysReducer,
    board: Board.boardReducer,
    RequestDetail: RequestDetail.RequestDetailReducer,
    Keiyaku: Keiyaku.KeiyakuReducer,
    MessageList: MessageList.MessageListReducer,
    MessageShare: MessageShare.MessageShareReducer,
    CustomerLink: CustomerLink.CustomerLinkReducer,
    ContractsList: ContractsList.ContractsListReducer,
    calendar: Calendar.calendarReducer,
    scenario: Scenario.scenarioReducer,


    // Reserve:Reserve.reserveReducer,
    // Coming:Coming.comingReducer,
};
const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers // rest of your reducers
});





export function* rootSaga() {
    yield all([
        ...Master.masterSaga,
        ...Mail.mailSaga,
        ...Adjustment.adjustmentSaga,
        ...RentMoney.rentmoneySaga,
        ...KyDetail.kydetailSaga,
        ...Rooms.roomsSaga,
        ...Line.lineSaga,
        ...Chat.chatSaga,
        ...Sms.smsSaga,
        ...Owner.ownerSaga,
        ...Vacancy.vacancySaga,
        ...Kys.kysSaga,
        ...Board.boardSaga,
        ...RequestDetail.RequestDetailSaga,
        ...Keiyaku.KeiyakuSaga,
        ...MessageList.MessageListSaga,
        ...MessageShare.MessageShareSaga,
        ...CustomerLink.CustomerLinkSaga,
        ...ContractsList.ContractsListSaga,
        ...Calendar.calendarSaga,
        ...Scenario.scenarioSaga,

        // ...Rent.rentSaga,
        // ...Reserve.reserveSaga,
        // ...Coming.comingSaga,
        // ...Monies.moniesSaga,
    ]);
}




export default function configureStore(preloadedState) {
    const store = createStore(
        rootReducer, // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk,
                logger,
                sagaMiddleware,
                // ... other middlewares ...
            ),
        ),
    )
    sagaMiddleware.run(rootSaga)

    return store
}
