import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';
import moment from 'moment'

import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi.ts_';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
// import * as rent_monies_api from '../Api/rent_monies_api';
// import * as bklist_api from '../Api/bklist_api';
// import * as sokinlist_api from '../Api/sokinlist_api';
// import * as shlist_api from '../Api/shlist_api';
import * as kys_api from '../Api/kys_api';


// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';
// import * as Kyousai from '../../Kyousai/Saga/Kyousai';
///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    // rent_contract_monies:any;
    kys: any;
    // searchky: any;
    // kys_biko: any;
    // rent_money_bklist: any;
    // rent_money_search: any;
    // sokinlist: any;
    // search: any;
    // shlist: any;

    // addky: any;
    // delky: any;
    // koumokulist: any;
    // pre_sokinlist: any;
    // owner_remittance_check_logs_list: any;
    // owner_remittance_check_logs_sum: any;
    // so_ymd_list: any;

    // so_ymd_list_sum: any;
    // remittancelogcheck_search_form: any;
    // search_diff: any;
    // search_contract_date_start: any;
    // search_contract_date_end: any;
    // search_tatemono_name: any;
    // parking_check_loading: number;
    // net_check_loading: number;

    // energization_check_loading: number;

    // electronics_check_loading: number;
    // wifi_check_loading: number;
    // search_loading: boolean;
    // owner_tel_check_loading: number;
}
const initialState: State = {

    // rent_contract_monies: [],
    kys: [],
    // searchky: [],
    // kys_biko: [],
    // rent_money_bklist: [],
    // rent_money_search: [],
    // sokinlist: [],
    // shlist: [],
    // search: {
    //     bk_no: null,
    //     date1: moment().add('month', -1).startOf('month').format("YYYY/MM/DD HH:mm:ss"),
    //     date2: moment().endOf('month').format("YYYY/MM/DD HH:mm:ss"),
    // },
    // addky: [],
    // delky: [],
    // koumokulist: [],
    // pre_sokinlist: [],
    // owner_remittance_check_logs_list: [],
    // so_ymd_list: [],
    // so_ymd_list_sum: 0,
    // owner_remittance_check_logs_sum: 0,
    // search_diff: false,
    // remittancelogcheck_search_form: {
    //     so_ymd: '2020/6/20',
    //     // search_contract_date_start: null,
    //     // search_contract_date_end: null,
    //     // search_tatemono_name: null,
    //     // search_completed: false,
    // },
    // search_contract_date_start: null,
    // search_contract_date_end: null,
    // search_tatemono_name: "",

    // parking_check_loading: 0,
    // net_check_loading: 0,

    // energization_check_loading: 0,

    // electronics_check_loading: 0,
    // wifi_check_loading: 0,
    // search_loading: false,
    // owner_tel_check_loading: 0,

    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

// export const get_rent_contract_monies = actionCreator<{rent_contract_id:number}>('GET_RENT_CONTRACT_MONIES');
// export const get_rent_contract_monies_success = actionCreator("GET_RENT_CONTRACT_MONIES_SUCCESS");
// export const get_rent_contract_monies_failure = actionCreator("GET_RENT_CONTRACT_MONIES_FAILURE");

// export const get_rent_contract_accounting = actionCreator("GET_RENT_CONTRACT_ACCOUNTING");
// export const get_rent_money_bklist = actionCreator<any>('GET_RENT_MONEY_BKLIST');
// export const change_rent_money_bklist = actionCreator<any>('CHANGE_RENT_MONEY_BKLIST');

export const get_kys = actionCreator<any>('GET_KYS');
export const change_kys = actionCreator<any>('CHANGE_KYS');
// export const change_ky = actionCreator<any>('CHANGE_KY');

// export const change_searchky = actionCreator<any>('CHANGE_SEARCHKY');

export const get_kys_biko = actionCreator<any>('GET_KYS_BIKO');
export const change_kys_biko = actionCreator<any>('CHANGE_KYS_BIKO');

// export const get_shlist = actionCreator<any>('GET_SHLIST');
// export const change_shlist = actionCreator<any>('CHANGE_SHLIST');

// export const get_remittance_details = actionCreator<any>('GET_REMITTANCE_DETAILS');
// export const change_remittance_details = actionCreator("CHANGE_REMITTANCE_DETAILS");
// // export const change_owner_remittance_check_logs_list = actionCreator("CHANGE_OWNER_REMITTANCE_CHECK_LOGS_LIST");

// export const change_rent_money_search = actionCreator<any>("CHANGE_RENT_MONEY_SEARCH");

// export const change_addky = actionCreator<any>("CHANGE_ADDKY");
// export const change_delky = actionCreator<any>("CHANGE_DELKY");

// export const get_koumokulist = actionCreator<any>("GET_KOUMOKULIST");
// export const change_koumokulist = actionCreator<any>("CHANGE_KOUMOKULIST");

// export const change_pre_sokinlist = actionCreator<any>("CHANGE_PRE_SOKINLIST");

// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const kysReducer = reducerWithInitialState(initialState)
    // .case(change_remittance_details, (state, payload) => ({ ...state, minories: payload }))
    .case(change_kys, (state, payload) => ({ ...state, kys: payload }))

    // .case(change_searchky, (state, payload) => ({ ...state, searchky: payload }))

    .case(change_kys_biko, (state, payload) => ({ ...state, kys_biko: payload }))

// .case(change_rent_moneies, (state, payload) => ({ ...state, rent_moneies: payload }))
// // .case(change_shlist, (state, payload) => ({ ...state, shlist: payload }))
// .case(change_rent_money_search, (state, payload) => ({ ...state, rent_money_search: payload }))
// .case(change_addky, (state, payload) => ({ ...state, addky: payload }))
// .case(change_delky, (state, payload) => ({ ...state, delky: payload }))
// .case(change_koumokulist, (state, payload) => ({ ...state, koumokulist: payload }))
// .case(change_pre_sokinlist, (state, payload) => ({ ...state, pre_sokinlist: payload }))


// .case(change_remittancelogcheck_search_form, (state, payload) => ({ ...state, remittancelogcheck_search_form: payload }))

// .case(change_owner_remittance_check_logs_sum, (state, payload) => ({ ...state, owner_remittance_check_logs_sum: payload }))
// .case(change_so_ymd_list_sum, (state, payload) => ({ ...state, so_ymd_list_sum: payload }))
// .case(change_search_diff, (state, payload) => ({ ...state, search_diff: payload }))

// .case(change_owner_remittance_check_logs_list, (state, payload) => ({ ...state, owner_remittance_check_logs_list: payload }))






// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const kysSaga = [
    //     takeEvery('GET_RENT_CONTRACT_MONIES', handleRequestMoney),
    // takeEvery('GET_RENT_CONTRACT_ACCOUNTING', handle_get_rent_contract_accounting),
    takeEvery('GET_KYS', handle_get_kys),

    takeEvery('GET_KYS_BIKO', handle_get_kys_biko),
    // 
    // takeEvery('GET_SOKINLIST', handle_get_sokinlist),

    // takeEvery('GET_SHLIST', handle_get_shlist),

    // takeEvery('GET_KOUMOKULIST', handle_get_koumokulist),

    // takeEvery('GET_SO_YMD_LIST', handle_get_so_ymd_list),

    // takeEvery('NET_CHECKED', handle_net_checked),

    // takeEvery('ENERGIZATION_CHECKED', handle_energization_checked),

    // takeEvery('ELECTRONICS_CHECKED', handle_electronics_checked),
    // takeEvery('WIFI_CHECKED', handle_wifi_checked),
    // // takeEvery('BIND_KYOUSAI_CONTRACT', handle_bind_kyousai_contract),

    // takeEvery('COMPLETED_CHECKED', handle_completed_checked),

    // takeEvery('ALL_SELECTED', handle_all_selected),

    // takeEvery('CHECK_COMPLETED', handle_check_completed),

    // takeEvery('CHECK_COMPLETED_CANCEL', handle_check_completed_cancel),

    // takeEvery('OWNER_TEL_CHECKED', handle_owner_tel_checked),

    // takeEvery('USERS_REQUEST', handleUsersRequest),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;


function* handle_get_kys(action: any) {

    const state = yield select();
    yield put(change_kys([]));
    // let search_form = state.ContractCheck.search_form
    // yield put(change_search_loading(true))
    // alert()
    // let search_id = state.kyousaiReducer.search_id
    const { payload, error } = yield call(kys_api.detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert()
        // console.log(payload)

        yield put(change_kys(payload));

        // const { payload2, error2 } = yield call(sh_furi_api.list, {
        yield put(get_kys_biko({ kys_no: action.payload }));
    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_kys_biko(action: any) {
    const state = yield select();

    // let search_form = state.ContractCheck.search_form
    // yield put(change_search_loading(true))

    // let search_id = state.kyousaiReducer.search_id
    const { payload, error } = yield call(kys_api.biko, { kys_no: action.payload });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)
        yield put(change_kys_biko(payload));
        // const { payload2, error2 } = yield call(sh_furi_api.list, {

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// function* handle_get_rent_moneies(action: any) {
//     const state = yield select();

//     // let search_form = state.ContractCheck.search_form
//     // yield put(change_search_loading(true))

//     // let search_id = state.kyousaiReducer.search_id
//     const { payload, error } = yield call(rent_monies_api.list, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         yield put(change_rent_moneies(payload));

//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


