import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';

import moment from 'moment'



interface Props {
    request_detail: any
}
interface State {
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        // this.state = {
        // }

    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    {this.props.request_detail.detail.map(function (value: any) {
                        if (value.ShosaiNaiyo !== "初回対応登録なし") {
                            return (
                                <Card style={{ marginTop: 10 }} className="col-lg-12">

                                    <div style={{ width: "100%", backgroundColor: "#d6e9c6" }}>
                                        <span style={{ display: "block", float: "left", width: 110 }}>
                                            {value.OutaiHoho_Name == "電話" ?
                                                <span className="label label-info">
                                                    {value.OutaiHoho_Name}
                                                </span> :
                                                <span className="label label-default">
                                                    {value.OutaiHoho_Name}
                                                </span>}
                                        </span>

                                        <span style={{ display: "block", float: "left", width: 150 }}>
                                            <span>
                                                {value.OutaiDate ? moment(moment(value.OutaiDate).tz("Asia/Tokyo").format('YYYY-MM-DD') + " " + value.OutaiDate_H + ":" + value.OutaiDate_M).tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm') : " "}
                                            </span>
                                        </span>

                                        <span style={{ display: "block", float: "left", width: 200 }}>
                                            <span className="label label-default">
                                                備考
                                            </span>
                                            <span>
                                                {value.OutaiHoho_Biko}
                                            </span>
                                        </span>

                                        <span style={{ display: "block", float: "left", width: 400 }}>
                                            <span className="label label-default">
                                                対応者
                                            </span>
                                            <span>
                                                {value.syosai_taiou_user}
                                            </span>
                                        </span>
                                    </div>

                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        {/* <div>{value.ShosaiNaiyo}</div> */}
                                        <TextField
                                            id="outlined-read-only-input"
                                            label="対応"
                                            fullWidth
                                            multiline
                                            defaultValue={value.ShosaiNaiyo}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            style={{ marginTop: "10px" }}
                                        />
                                        {value.Biko ?
                                            <CardContent style={{ float: "left", backgroundColor: "#f5f5f5" }} className="col-lg-12">
                                                {value.Biko}
                                            </CardContent>
                                            : ""}


                                    </CardContent>
                                </Card>
                            )
                        }
                    }, this)}
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        request_detail: state.Adjustment.request_detail,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

