import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Keiyaku from './Saga/Keiyaku';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Kihon from "./Kihon";
import SmsGrid from "./SmsGrid";
import TextField from '@material-ui/core/TextField';
import { Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import TemplateKanri from "./TemplateKanri";

const request = require('request');


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


interface Props {
    classes: any,
    checkout: any,
    customers: any,
    send_news_personal: any,
    tatemono_id: any,
    user_details: any,
    get_template_category_list: any,
    template_category_list: any,
    get_template: any,
    template_list: any,
    get_template_detail: any,
    template: any,
    noticetoggle: any
}

interface State {
    title: any,
    message: any,
    send_custmers: any,
    checked: boolean,
    template_disp: any,
    template_disp2: any,
    message_disp: any,
    Templatetoggle: any
}


class CustomerList extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            title: "",
            message: "いつも大変お世話になっております。\n\n 以上、宜しくお願い致します。",
            send_custmers: [],
            checked: false,
            template_disp: false,
            template_disp2: false,
            message_disp: true,
            Templatetoggle: false
        }

        this.onChangetitle = this.onChangetitle.bind(this);
        this.onChangemessage = this.onChangemessage.bind(this);
        this.send = this.send.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.template_open = this.template_open.bind(this);
        this.onchange_category = this.onchange_category.bind(this);
        this.onchange_template = this.onchange_template.bind(this);
        this.Templatetoggle = this.Templatetoggle.bind(this);

    }


    onChangetitle(e: any) {
        this.setState({
            title: e.target.value,
        });
    }

    onChangemessage(e: any) {
        this.setState({
            message: e.target.value,
        });
    }

    onCheck(e: any) {
        this.setState({ checked: !this.state.checked })
    }



    template_open() {
        if (this.state.template_disp == false) {
            this.props.get_template_category_list();
        }
        this.setState({ template_disp: !this.state.template_disp });
    }

    onchange_category(event: any, values: any) {
        this.setState({
            template_disp2: false
        });

        if (values) {
            this.props.get_template(values.id);

            setTimeout(() => {
                this.setState({
                    template_disp2: true
                });
            }, 1000)
        }

    }

    onchange_template(event: any, values: any) {
        if (values) {
            this.setState({
                message_disp: false
            });
            this.props.get_template_detail(values.id);
            setTimeout(() => {
                this.setState({
                    title: this.props.template[0].template_title,
                    message: this.props.template[0].template_contents,
                });
            }, 500)
            setTimeout(() => {
                this.setState({
                    message_disp: true
                });
            }, 500)
        }
    }

    Templatetoggle() {
        if (this.state.Templatetoggle == false) {
            this.props.get_template_category_list();
        }
        this.setState({ Templatetoggle: !this.state.Templatetoggle })

    }


    send(send_custmers: any) {
        // title タイトル
        // contents 内容
        // is_push 1: 0 プッシュ通知 有無
        // targets m_customers.id(配列）

        var rooms: any[] = [];

        send_custmers.forEach((value: any) => {
            console.log(value);
            rooms.push({
                Tatemono_Id: value.Tatemono_Id,
                Room_Id: value.Room_Id
            });
        });

        // alert("通知しました");

        var is_push = 0;
        if (this.state.checked == true) {
            is_push = 1;
        }


        var data = {
            title: this.state.title,
            contents: this.state.message,
            targets: rooms,
            is_push: is_push
        }

        console.log(data);

        this.props.send_news_personal(data);

        alert("お知らせを送信しました");
        this.props.noticetoggle();
    }

    render() {

        var send_custmers: any[] = [];
        // チェックを入れてない人、電話番号がない人を除外する
        this.props.customers.forEach((value: any) => {
            if (this.props.checkout.indexOf(String(value.m_customers_id)) != -1) {

            } else if (value.room_support_m_user_id) {
                send_custmers.push(value);
            }
        });

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <Paper square={false} style={{ marginTop: 10 }}>

                    <Row style={{ marginLeft: 10 }}>
                        <div>
                            <div style={{ fontSize: 20 }}>
                                送信対象：{send_custmers.length}件
                            </div>
                            <SmsGrid send_custmers={send_custmers} />
                        </div>


                        <div style={{ marginTop: 10, marginLeft: 10 }}>



                            <div>
                                <FormControlLabel
                                    control={<Checkbox onChange={this.onCheck} />}
                                    label="プッシュ通知を使用する"
                                />
                                {this.state.template_disp ?
                                    <Button style={{ marginLeft: 10 }}
                                        onClick={this.template_open}
                                        color="primary"
                                        variant="outlined">
                                        テンプレートを閉じる
                                    </Button>
                                    :
                                    <Button style={{ marginLeft: 10 }}
                                        onClick={this.template_open}
                                        color="primary"
                                        variant="outlined">
                                        テンプレートを使用する
                                    </Button>}
                            </div>

                            {this.state.template_disp ?

                                <div>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.template_category_list}
                                        getOptionLabel={(option: any) => option.category_title}
                                        style={{ width: 200, float: "left", marginTop: 12 }}
                                        onChange={this.onchange_category}
                                        renderInput={(params) => <TextField {...params} label="カテゴリー" />}
                                    />

                                    {this.state.template_disp2 ?
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.props.template_list}
                                            getOptionLabel={(option: any) => option.template_name}
                                            style={{ width: 200, float: "left", marginTop: 12, marginLeft: 15 }}
                                            onChange={this.onchange_template}
                                            renderInput={(params) => <TextField {...params} label="テンプレート" />}
                                        /> : ""}



                                    <Button
                                        style={{ float: "right" }}
                                        onClick={this.Templatetoggle}
                                        color="primary"
                                        variant="outlined">
                                        テンプレートを作成する
                                    </Button>

                                </div>

                                : ""}

                            {this.state.message_disp ?
                                <div>
                                    <TextField
                                        id="outlined-read-only-input"
                                        label="タイトル"
                                        rows={1}
                                        defaultValue={this.state.title}
                                        onChange={this.onChangetitle}
                                        variant="outlined"
                                        style={{ width: 800, marginTop: "10px" }}
                                    />
                                </div> : <div style={{ height: 300 }}></div>}

                            {this.state.message_disp ?
                                <div>
                                    <div>
                                        <TextField
                                            id="outlined-read-only-input"
                                            label="通知内容"
                                            defaultValue={this.state.message}
                                            multiline
                                            onChange={this.onChangemessage}
                                            rows={25}
                                            variant="outlined"
                                            style={{ width: 800, marginTop: "10px" }}
                                        />
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        文字数:{this.state.message.length}
                                    </div>
                                    <div style={{ marginTop: 10, marginLeft: 750 }}>
                                        <Button onClick={() => this.send(send_custmers)} color="primary" variant="outlined">送信</Button>
                                    </div>
                                </div> : <div style={{ height: 300 }}></div>}
                        </div>
                    </Row>
                </Paper>



                < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} size="keiyaku" fade={false}>
                    <ModalHeader toggle={this.Templatetoggle}>お知らせ用テンプレート管理</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TemplateKanri />
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        customers: state.Keiyaku.customers,
        user_details: state.masterReducer.user_details,
        template_category_list: state.Keiyaku.template_category_list,
        template_list: state.Keiyaku.template_list,
        template: state.Keiyaku.template,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        send_news_personal(state: any) {
            dispatch(Keiyaku.send_news_personal(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
        get_template_detail(state: any) {
            dispatch(Keiyaku.get_template_detail(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




