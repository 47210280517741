import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as tm_outai_sub_api from '../Api/tm_outai_sub_api';
import * as room_support_chat_forms_api from '../Api/room_support_chat_forms_api';
import * as room_support_m_applications_api from '../Api/room_support_m_applications_api';
import * as room_support_chat_templates_api from '../Api/room_support_chat_templates_api';
import * as room_support_chat_template_categorys_api from '../Api/room_support_chat_template_categorys_api';
import * as room_support_chat_templates2_api from '../Api/room_support_chat_templates2_api';
import * as room_support_chat_template_categorys2_api from '../Api/room_support_chat_template_categorys2_api';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {

    room_support_chat_template: any;

    room_support_chat_template_category_list: any;

    room_support_chat_template_list: any;

    tm_outai_sub_list: any;
    
    room_support_chat_forms_list: any;

    room_support_chat_template_category_id: any;

    save_modal_disp: boolean;
    category_save_modal_disp: boolean;

}
const initialState: State = {

    room_support_chat_template:0,

    room_support_chat_template_list:[],
    // room_support_chat_template_list: [
    //     {id:0, status:0, name:"初回問い合わせ", contents:[
    //         {"type":"text","text":"お問い合わせありがとうございます。"},
    //         {"type":"text","text":"\n%room_name%\n下記よりお問い合わせ内容を選択してください。"},
    //         {"type":"select","actions":[
    //             {"type":"postback","label":"設備に関するお問合せ","data":"1-1"},
    //             {"type":"postback","label":"カギの紛失","data":"1-2"},
    //             // {"type":"postback","label":"共用部の不具合","data":"7"},
    //             ]
    //         }
    //         ],
    //         editable:1, selected:0, main_outai_sub:0, free_input_next_template:"", remarks:"",
    //     },
    //         {id:1, status:1, name:"設備不良", contents:[
    //             {"type":"text","text":"お問い合わせの場所をお選びください。"},
    //             {"type":"select","actions":[
    //                 {"type":"postback","label":"エアコン","data":"2-1"},
    //                 {"type":"postback","label":"キッチン","data":"2-2"},
    //                 {"type":"postback","label":"前の質問に戻る","data":"0-0"}
    //                 ]
    //             }
    //             ],
    //         editable:1, selected:0, main_outai_sub:0, free_input_next_template:"", remarks:"",
    //         },
    //             {id:2, status:1, name:"エアコン", contents:[
    //                 {"type":"text","text":"お問い合わせ詳細をお選びください"},
    //                 {"type":"select","actions":[
    //                     {"type":"postback","label":"水漏れ","data":"3-1"},
    //                     {"type":"postback","label":"冷風/温風が出ない","data":"3-2"},
    //                     // {"type":"postback","label":"前の質問に戻る","data":"1-1"}
    //                     ]
    //                 }
    //                 ],
    //             editable:1, selected:0, main_outai_sub:0, free_input_next_template:"", remarks:"",
    //             },
    //                 {id:3, status:1, name:"水漏れ", contents:[
    //                     {"type":"text","text":"ベランダに出ているドレンホースの詰まりの可能性があるのでつまりの除去をお願いします。"},
    //                     {"type":"img","img":"drainhose.jpg"},
    //                     {"type":"text","text":"上記で解決しない場合は下記%コールセンター名%までご連絡下さい。"},
    //                     {"type":"tel","tel":"%コールセンターTEL%"}
    //                     ],
    //                 editable:0, selected:1, main_outai_sub:7, free_input_next_template:"", remarks:"",
    //                 },
    //                 {id:3, status:2, name:"冷風/温風が出ない", contents:[
    //                     {"type":"text","text":"動画を参考にコンセントの抜き差し（リセット）を試してください。"},
    //                     {"type":"movie","youtube_id":"HT9Kl3YGU-I"},
    //                     {"type":"text","text":"上記で解決しない場合は下記%コールセンター名%までご連絡下さい。"},
    //                     {"type":"tel","tel":"%コールセンターTEL%"}
    //                     ],
    //                 editable:0, selected:1, main_outai_sub:7, free_input_next_template:"", remarks:"",
    //                 },
        
    //             {id:2, status:2, name:"キッチン", contents:[
    //                 {"type":"tel","tel":"%コールセンターTEL%"}
    //                 ],
    //                 editable:1, selected:0, main_outai_sub:0, free_input_next_template:"", remarks:"",
    //             },
        
    //         {id:1, status:2, name:"カギの紛失", contents:[
    //             {"type":"text","text":"【注意事項】\n紛失時のカギの開錠費用や交換・複製・修理費用はお客様のご負担となります。また、ご連絡をいただいてから開錠するまでお時間がかかりますのであらかじめご了承ください。"},
    //             {"type":"text","text":"%コールセンター名%対応物件ですので、下記連絡先までお電話ください。"},
    //             {"type":"tel","tel":"%コールセンターTEL%"}
    //             ],
    //             editable:0, selected:1, main_outai_sub:21, free_input_next_template:"", remarks:"",
    //         },
        
                
    //     ],

    room_support_chat_template_category_list:[],
    
    tm_outai_sub_list: [],

    room_support_chat_forms_list: [],

    room_support_chat_template_category_id: 2,

    save_modal_disp:false,

    category_save_modal_disp:false,

}



///action 
const actionCreator = actionCreatorFactory();

export const change_room_support_chat_template = actionCreator("CHANGE_ROOM_SUPPORT_CHAT_TEMPLATE");

export const change_room_support_chat_template_category_id = actionCreator("CHANGE_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_ID");

export const get_room_support_chat_template_category_list = actionCreator("GET_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_LIST");
export const change_room_support_chat_template_category_list = actionCreator<any>("CHANGE_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_LIST");
export const update_room_support_chat_template_category_list = actionCreator('UPDATE_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_LIST');

export const get_room_support_chat_template_list = actionCreator("GET_ROOM_SUPPORT_CHAT_TEMPLATE_LIST");
export const change_room_support_chat_template_list = actionCreator("CHANGE_ROOM_SUPPORT_CHAT_TEMPLATE_LIST");
export const update_room_support_chat_template_list = actionCreator('UPDATE_ROOM_SUPPORT_CHAT_TEMPLATE_LIST');

export const get_tm_outai_sub_list = actionCreator('GET_TM_OUTAI_SUB_LIST');
export const change_tm_outai_sub_list = actionCreator('CHANGE_TM_OUTAI_SUB_LIST');

export const get_room_support_chat_forms_list = actionCreator('GET_ROOM_SUPPORT_CHAT_FORMS_LIST');
export const change_room_support_chat_forms_list = actionCreator('CHANGE_ROOM_SUPPORT_CHAT_FORMS_LIST');

export const change_save_modal_disp = actionCreator<boolean>('CHANGE_SAVE_MODAL_DISP');
export const change_category_save_modal_disp = actionCreator<boolean>('CHANGE_CATEGORY_SAVE_MODAL_DISP');


export const scenarioReducer = reducerWithInitialState(initialState)

    .case(change_room_support_chat_template, (state, payload) => ({ ...state, room_support_chat_template: payload }))
    .case(change_room_support_chat_template_category_id, (state, payload) => ({ ...state, room_support_chat_template_category_id: payload }))

    .case(change_room_support_chat_template_category_list, (state, payload) => ({ ...state, room_support_chat_template_category_list: payload }))

    .case(change_room_support_chat_template_list, (state, payload) => ({ ...state, room_support_chat_template_list: payload }))

    .case(change_tm_outai_sub_list, (state, payload) => ({ ...state, tm_outai_sub_list: payload }))

    .case(change_room_support_chat_forms_list, (state, payload) => ({ ...state, room_support_chat_forms_list: payload }))

    .case(change_save_modal_disp, (state, payload) => ({ ...state, save_modal_disp: payload }))
    .case(change_category_save_modal_disp, (state, payload) => ({ ...state, category_save_modal_disp: payload }))

    



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const scenarioSaga = [
    takeEvery('GET_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_LIST', handle_get_room_support_chat_template_category_list),
    takeEvery('UPDATE_ROOM_SUPPORT_CHAT_TEMPLATE_CATEGORY_LIST', handle_update_room_support_chat_template_category_list),

    takeEvery('GET_ROOM_SUPPORT_CHAT_TEMPLATE_LIST', handle_get_room_support_chat_template_list),
    takeEvery('UPDATE_ROOM_SUPPORT_CHAT_TEMPLATE_LIST', handle_update_room_support_chat_template_list),

    takeEvery('GET_TM_OUTAI_SUB_LIST', handle_get_tm_outai_sub_list),

    takeEvery('GET_ROOM_SUPPORT_CHAT_FORMS_LIST', handle_get_room_support_chat_forms_list),


];
// export default masterSaga;



// 応対種別
function* handle_get_tm_outai_sub_list(action: any) {

    const { payload, error } = yield call(tm_outai_sub_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_tm_outai_sub_list(payload))
    } else {

    }

}

// フォームリスト
function* handle_get_room_support_chat_forms_list(action: any) {

    const { payload, error } = yield call(room_support_m_applications_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_room_support_chat_forms_list(payload))
    } else {

    }

}

// カテゴリーリスト
function* handle_get_room_support_chat_template_category_list(action: any): any  {

    const state = yield select();
    // alert(state.scenario.room_support_chat_template)
    const { payload, error } = yield call(state.scenario.room_support_chat_template == 0 ? room_support_chat_template_categorys_api.list : room_support_chat_template_categorys2_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert(payload.length)

        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa")
        // console.log(payload)



        // 成功時アクション呼び出し
        yield put(change_room_support_chat_template_category_list(payload))
    } else {

    }

}

// カテゴリーリスト 更新
function* handle_update_room_support_chat_template_category_list(action: any): any{

    const state = yield select();

    let room_support_chat_template_category_list = state.scenario.room_support_chat_template_category_list

    const { payload, error } = yield call(state.scenario.room_support_chat_template == 0 ? room_support_chat_template_categorys_api.update : room_support_chat_template_categorys2_api.update, room_support_chat_template_category_list);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert(payload.length)
        // yield put(change_room_support_chat_template_category_list([]))
        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa")
        // console.log(payload)
        yield put(change_category_save_modal_disp(true))


        // 成功時アクション呼び出し
// console.log("aaaaaaaaaaaaaaaaaaaaa")
// console.log(payload)
        yield put(change_room_support_chat_template_category_list(JSON.parse(JSON.stringify(payload))))
        yield put(change_room_support_chat_template_category_list(payload))
    } else {

    }

}

// リスト
function* handle_get_room_support_chat_template_list(action: any): any {
// alert(action.payload)
    const state = yield select();

    // let room_support_chat_template_category_id = state.scenario.room_support_chat_template_category_id

    const { payload, error } = yield call(state.scenario.room_support_chat_template == 0 ? room_support_chat_templates_api.list : room_support_chat_templates2_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert(payload.length)

        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa")
        // console.log(payload)
        let list = payload.map(function (value: any) {

            value.contents = JSON.parse(value.contents)
            // value.contents.map(function (value: any) {
            //     if(value.contents){
            //         // console.log(Object.keys(value.contents))
            //         var array = Object.keys(value.contents).map(function (key: any) {
            //             // console.log(value.contents[key])
            //             return value.contents[key];
            //         });
            //         value.contents = array
            //     }

            return value;
        });

        console.log(list)


        // 成功時アクション呼び出し
        yield put(change_room_support_chat_template_list(list))
    } else {

    }

}

// 基本情報更新
function* handle_update_room_support_chat_template_list(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let room_support_chat_template_list = state.scenario.room_support_chat_template_list
    let room_support_chat_template_category_id = state.scenario.room_support_chat_template_category_id

    const { payload, error } = yield call(state.scenario.room_support_chat_template == 0 ? room_support_chat_templates_api.update : room_support_chat_templates2_api.update, {room_support_chat_template_category_id:room_support_chat_template_category_id, room_support_chat_template_list:room_support_chat_template_list});

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(change_save_modal_disp(false))
        // let rent_m_corporates_list = state.corporation.rent_m_corporates_list

        // // 一覧の情報更新
        // rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
        //     if (value.id === payload.id) {
        //         // alert(payload.title)
        //         value.kubun = payload.kubun //1本社　2支店　3その他
        //         value.name = payload.name
        //         value.kana = payload.kana
        //         value.post = payload.post
        //         value.add1 = payload.add1
        //         value.add2 = payload.add2
        //         value.tel1 = payload.tel1
        //         value.url = payload.url
        //         value.capital = payload.capital
        //         value.establish = payload.establish
        //         value.start_of_business = payload.start_of_business
        //         value.president = payload.president
        //         value.position = payload.position
        //         value.office = payload.office
        //         value.employee = payload.employee
        //         value.vision = payload.vision
        //         value.lat = payload.lat
        //         value.lng = payload.lng
        //         value.is_display = payload.is_display
        //         value.remarks_person = payload.remarks_person
        //         value.remarks_attention = payload.remarks_attention
        //         value.remarks_request = payload.remarks_request
        //         value.remarks_information = payload.remarks_information
        //     }
        //     return value
        // })

        // //一覧から削除
        // let result = rent_m_corporates_list.filter((value: any) => {
        //     return (!(value.id == payload.id && value.is_display == true));
        // });
        // rent_m_corporates_list = result

        // // 成功時アクション呼び出し
        // // ブログ一覧更新
        // yield put(change_rent_m_corporates_list(rent_m_corporates_list))
        // yield put(get_rent_m_corporates_list_search())
        // // 情報更新
        // yield put(change_rent_m_corporates(payload))



    } else {

    }
}