import React from 'react';
import Chip from '@material-ui/core/Chip';
import CallIcon from '@material-ui/icons/Call';

import styled from 'styled-components';

var moment = require('moment-timezone');


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        height:300px;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;font-weight:bold;text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;


const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 10px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Kidoku = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 25px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Name = styled.div`
    position: relative; 
    margin: 0 0 0 0px;
    bottom: 0;
    margin-right:60px;
    font-size:12px;
    color:#FFF;
`;
// position: absolute;
const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 60px 0 60px;
    padding: 5px;
    max-width: 250px;
    border-radius: 12px;
    background: #33beff;
    margin-bottom: 2px;
    text-align: left;
    color:white;

`;


const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;


export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],
            text: JSON.parse(this.props.msg.template),
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
        };
    }

    render() {


        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Name>
                        <span style={{ color: "black" }}>
                            <Chip label="自動" size="small" variant="outlined" />
                        </span>
                    </Name>
                    <Time>
                        <span style={{ color: "black" }}>
                            {moment.utc(this.props.msg.created_at).format('MM/DD HH:mm')}
                        </span>
                    </Time>
                    <Kidoku>
                        <span style={{ color: "black" }}>
                            {this.props.msg.read_at ? "既読" : ""}
                        </span>
                    </Kidoku>

                    <Says>
                        <MsgArea>
                            {/* {this.state.text.text} */}
                            {/* {this.state.text.text ? <span dangerouslySetInnerHTML={{ __html: this.state.text.text.replace(/\r?\n/g, '<br/>') }} />:""} */}
                            <CallIcon style={{float:"left", marginTop:10, marginRight:20, marginLeft:20}}></CallIcon>
                            <div style={{float:"left", marginRight:40}}>
                                <div style={{marginLeft:5}}>問い合わせ先</div>
                                <div>{this.state.text.tel}</div>
                            </div>
                            {/* <span dangerouslySetInnerHTML={{ __html: this.props.msg.template.replace(/\r?\n/g, '<br/>') }} /> */}
                        </MsgArea>
                    </Says>

                </Chatting>
            </Balloon>

            // <Balloon className="col-lg-12">
            //     <Chatting>

            //         <List>

            //             {this.state.lists.map((data, index) => {
            //                 return (
            //                     <ListItem key={index}>
            //                         <div><img className="l_img" border="0" src={"https://www.homestation.jp/manage/common/img/building/" + data.split('_')[0] + "/1/1/1"} width="200" alt="" /></div>
            //                         <Title>{data.split('_')[2]}-{data.split('_')[3]}</Title>
            //                         <Detail>{data.split('_')[5]}/{data.split('_')[4]}円/{data.split('_')[6]}</Detail>
            //                         <Menu1 className="col-xs-12">詳細ページ</Menu1>
            //                         {/* <Menu2 className="col-xs-12">見学予約</Menu2> */}
            //                     </ListItem>
            //                 )
            //             })}

            //         </List>
            //     </Chatting>
            //     <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
            // </Balloon>

        )
    }
}
