import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import * as Keiyaku from './Saga/Keiyaku';
import Search from "./Search";
import Grid from "./Grid";
import Customer from '../Customer/Index';
import moment from 'moment'
import ChatGrid from "./ChatGrid";
import * as Sms from '../Sms/Saga/Sms';
import * as Chat from '../Chat/Saga/Chat';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NewSmsModal from "./NewSmsModal";
import { teal } from '@material-ui/core/colors';

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});


interface Props {
    customerdetail: any,
    get_smses: any,
    get_sms_share: any,
    get_chatlist: any,
    get_chats: any,
    get_template_category_list: any
}



interface State {
    reqtoggle: boolean,
    smstoggle: boolean,
    tel: any
}

class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            reqtoggle: false,
            smstoggle: false,
            tel: null
        }

        this.reqtoggle = this.reqtoggle.bind(this);
        this.smstoggle = this.smstoggle.bind(this);
        this.chatopen = this.chatopen.bind(this);

    }




    requestopen = (rowIndex: any) => {
        this.reqtoggle();
    }
    reqtoggle() {
        this.setState({ reqtoggle: !this.state.reqtoggle })
    }




    chatopen = (m_customer_id: any, room_support_m_user_id: any) => {
        this.props.get_sms_share({ m_customer_id: m_customer_id });

        this.props.get_chatlist({
            m_customer_id: m_customer_id,
            room_support_m_user_id: room_support_m_user_id
        });

        this.props.get_chats(0);
    }
    smstoggle = (tel: any) => {
        if (this.state.smstoggle == true) {
        } else {
            this.props.get_template_category_list();
        }
        this.setState({
            smstoggle: !this.state.smstoggle,
            tel: tel
        })
    }

    render() {

        return (
            <div>
                {this.props.customerdetail[0] ? <Card style={{ marginTop: 10 }} className="col-lg-12">
                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                        <table className="col-lg-6" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約No.</th>
                                    <td colSpan={2} >
                                        {this.props.customerdetail[0].Keiyaku_No}
                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約者1</th>

                                    <td colSpan={2} >
                                        {this.props.customerdetail[0].name}
                                    </td>

                                    {/* 顧客情報を開くボタン     */}
                                    <i onClick={this.requestopen} style={{ cursor: "pointer", fontSize: "20px", color: "blue", margin: "10px" }} className="fas fa-user"></i>
                                    {/* SMSを開くボタン */}
                                    <i onClick={() => this.smstoggle(this.props.customerdetail[0].tel)} style={{ cursor: "pointer", fontSize: "30px", color: "blue", margin: "10px" }} className="fas fa-sms"></i>
                                    {/* チャットを開くボタン */}
                                    {this.props.customerdetail[0].room_support_m_user_id !== "" ?
                                        <i onClick={() => this.chatopen(this.props.customerdetail[0].id, this.props.customerdetail[0].room_support_m_user_id)} className="fas fa-comments" style={{ cursor: "pointer", fontSize: "20px", color: "blue", margin: "10px" }}></i>
                                        : ""}
                                </tr>

                                {this.props.customerdetail.map((d: any) => {
                                    var name1 = this.props.customerdetail[0].name.replace("　", "").replace(" ", "")
                                    var name2 = d.name.replace("　", "").replace(" ", "")
                                    if (name1 !== name2) {
                                        return <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >入居者</th>
                                            <td colSpan={2} >
                                                {d.name}
                                            </td>
                                            {d.room_support_m_user_id !== "" ?
                                                <i onClick={() => this.chatopen(d.id, d.room_support_m_user_id)} className="fas fa-comments" style={{ cursor: "pointer", fontSize: "20px", color: "blue", margin: "10px" }}></i>
                                                : ""}
                                        </tr>
                                    }
                                })}
                                {/* <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約者2</th>
                                    <td colSpan={2} >
                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約者3</th>
                                    <td colSpan={2} >
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        {/* <ChatGrid room_support_m_user_id={this.props.customerdetail[0].room_support_m_user_id} /> */}
                    </CardContent>
                </Card> : ""}



                {this.props.customerdetail[0] ? <Card style={{ marginTop: 10 }} className="col-lg-12">
                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">
                        <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約日</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].Keiyaku_Date ? moment(this.props.customerdetail[0].Keiyaku_Date).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約期間</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].Keiyaku_Kikan_S ? moment(this.props.customerdetail[0].Keiyaku_Kikan_S).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                    </td>
                                    <td style={{ textAlign: "center", border: "0px" }} colSpan={1} >
                                        ～
                                    </td><td colSpan={1} >
                                        {this.props.customerdetail[0].Keiyaku_Kikan_E ? moment(this.props.customerdetail[0].Keiyaku_Kikan_E).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >使用目的</th>
                                    <td colSpan={1} >
                                        住居
                                    </td>
                                </tr>
                                {/* <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約金送金日</th>
                                    <td colSpan={1} >

                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約終了通知期間</th>
                                    <td colSpan={1} >

                                    </td>
                                    <td style={{ textAlign: "center", border: "0px" }} colSpan={1} >
                                        ～
                                    </td><td colSpan={1} >

                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >賃料発生日</th>
                                    <td colSpan={1} >

                                    </td>
                                </tr> */}
                            </tbody>
                        </table>


                        <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >更新内容</th>
                                    <td colSpan={1} >

                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約分類No.</th>
                                    <td colSpan={1} >

                                    </td>
                                    <td colSpan={1} >

                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >契約更新業務</th>
                                    <td colSpan={1} >

                                    </td>
                                </tr>

                                {/* <tr style={{ border: "0px" }}>
                                    <td style={{ border: "0px" }} colSpan={1} >滞納補償</td>
                                </tr> */}
                            </tbody>
                        </table>


                    </CardContent>
                </Card> : ""}
                {this.props.customerdetail[0] ?
                    <Card style={{ marginTop: 10 }} className="col-lg-12">
                        <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">


                            <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >契約担当者</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].eigyou_name}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >仲介店舗</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].Tenpo_Name}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >宅地建物取引士</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].takken_name}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >管理業者</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].Kanri_Corp_Name}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >請求担当者</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].seikyu_name}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >家賃保証会社</th>
                                        <td colSpan={1} >
                                            {this.props.customerdetail[0].Hoshou_Gaisha_Name}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card> : ""}
                {/* {this.props.customerdetail[0] ?
                    <Card style={{ marginTop: 10 }} className="col-lg-12">
                        <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">
                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >契約時備考</th>
                                        <td colSpan={4} >
                                            {this.props.customerdetail[0].bikou}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card> : ""} */}


                < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="kokyaku" fade={false}>
                    <ModalHeader toggle={this.reqtoggle}>顧客情報</ModalHeader>
                    <Customer />
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

                < Modal isOpen={this.state.smstoggle} toggle={this.smstoggle} size="request" fade={false}>
                    {/* <ModalHeader toggle={this.smstoggle}>SMS</ModalHeader> */}
                    <DialogTitle id="customized-dialog-title" onClose={this.smstoggle} >SMS</DialogTitle>
                    <NewSmsModal
                        tel={this.state.tel}
                        smstoggle={this.smstoggle}
                    />
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        customerdetail: state.Keiyaku.customerdetail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_smses(state: any) {
            dispatch(Sms.get_smses(state))
        },
        get_sms_share(state: any) {
            dispatch(Sms.get_sms_share(state))
        },
        get_chats(state: any) {
            dispatch(Chat.get_chats(state))
        },
        get_chatlist(state: any) {
            dispatch(Keiyaku.get_chatlist(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




