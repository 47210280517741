import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from '../RequestDetail/demoIndex';
import * as RequestDetailSaga from '../RequestDetail/Saga/RequestDetail';
import * as Adjustment from './Saga/Adjustment';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import * as Chat from '../Chat/Saga/Chat';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';
import jaLocale from "date-fns/locale/ja";
import { format } from 'date-fns'
const { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});

var moment = require('moment-timezone');

class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getDatePickerHeaderText(date) {
    return format(date, 'M月d日(E)', { locale: this.locale })
  }
}



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const ipad_columns =
  [
    { key_name: 'created_at', name: '受付日', width: 75, resizable: true },
    { key_name: 'lasts', name: '最終対応', width: 75, resizable: true },
    { key_name: 'Tatemono_Name', name: '物件名', width: 150, resizable: true },
    { key_name: 'Room_Name', name: '部屋名', width: 60, resizable: true },
    { key_name: 'Title', name: '案件タイトル', width: 100, resizable: true },
    { key_name: 'remarks', name: '業務課備考', width: 90, resizable: true },
    { key_name: 'taiou_user', name: '対応担当', width: 60, resizable: true },
    { key_name: 'Outai_Joukyou_Name', name: '対応状況', width: 55, resizable: true },
    { key_name: 'Kokyaku_Name', name: '顧客名', width: 100, resizable: true },
    { key_name: 'shokai_user', name: '受電者', width: 70, resizable: true },
  ]


const columns =
  [
    // { key_name: 'importance', name: '★', width: 20, resizable: true },
    // { key_name: 'main_outai_sub', name: '区分', width: 25, resizable: true },
    // { key_name: 'Link', name: '', width: 30, resizable: true },
    { key_name: 'importance', name: '★', width: 25, resizable: true },
    { key_name: 'Outai_No', name: 'No.', width: 50, resizable: true },
    { key_name: 'created_at', name: '受付日', width: 75, resizable: true },
    { key_name: 'lasts', name: '最終対応', width: 75, resizable: true },
    { key_name: 'Tatemono_Name', name: '物件名', width: 150, resizable: true },
    { key_name: 'Room_Name', name: '部屋名', width: 60, resizable: true },
    { key_name: 'Title', name: '案件タイトル', width: 100, resizable: true },
    { key_name: 'remarks', name: '業務課備考', width: 90, resizable: true },
    { key_name: 'taiou_user', name: '対応担当', width: 60, resizable: true },
    { key_name: 'Outai_Joukyou_Name', name: '対応状況', width: 55, resizable: true },
    { key_name: 'Kokyaku_Name', name: '顧客名', width: 100, resizable: true },
    { key_name: 'Renraku_Saki', name: '連絡先', width: 80, resizable: true },
    { key_name: 'Kokyaku_Kbn', name: '顧客区分', width: 55, resizable: true },
    { key_name: 'shokai_user', name: '受電者', width: 70, resizable: true },
    // { key_name: 'Shoyuusha_Tantou_Name', name: '営業担当', width: 60, resizable: true },
    { key_name: 'type', name: '', width: 60, resizable: true },
    { key_name: 'Syokai_Date', name: '初回対応日', width: 75, resizable: true },
    { key_name: 'Gyosha_Name', name: '手配業者', width: 120, resizable: true },
    { key_name: 'owner_houkoku', name: 'オーナー報告', width: 75, resizable: true },
    { key_name: 'Gyosha2_Name', name: '手配業者', width: 90, resizable: true },
    { key_name: 'complete_date', name: '完了日', width: 75, resizable: true },
    { key_name: 'Keiyaku_Date', name: '契約日', width: 75, resizable: true },
    { key_name: 'completed_remarks', name: '完了結果', width: 75, resizable: true },
    { key_name: 'not_published_flag', name: '掲載', width: 50, resizable: true },
  ]


const Links = ({ column, props, rowIndex, mouse_hover, requestopen }) =>
  <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} >
    <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
      <a className="fas fa-window-restore"></a>
    </Cell>
  </div>


const Base = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  (() => {
    if (props.request_list[rowIndex][column.key_name]) {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Tooltip placement="bottom-start" title={props.request_list[rowIndex][column.key_name]}>
          <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
            {column.key_name == "not_published_flag" ? "　" : props.request_list[rowIndex][column.key_name]}
          </Cell></Tooltip>
      </div>
    } else {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        {column.key_name == "not_published_flag" ? <CheckIcon /> : "　"}
      </div>
    }
  })()

const Dates = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  (() => {
    if (props.request_list[rowIndex][column.key_name]) {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
          {/* {props.request_list[rowIndex][column.key_name] ? moment(props.request_list[rowIndex][column.key_name]).utc().format('YYYY-MM-DD') : ""} */}
          {props.request_list[rowIndex][column.key_name] ? moment(props.request_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
        </Cell>
      </div>
    } else {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}> 　</div>
    }
  })()


const Users = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  (() => {

    if (props.request_list[rowIndex].room_support_m_chat_id) {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Tooltip placement="bottom-start" title={props.request_list[rowIndex][column.key_name]}><Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
          <span className="label" style={{ backgroundColor: "orange" }}>
            アプリ
          </span>
        </Cell></Tooltip>
      </div>
    } else if (props.request_list[rowIndex][column.key_name]) {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Tooltip placement="bottom-start" title={props.request_list[rowIndex][column.key_name]}><Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} > {props.request_list[rowIndex][column.key_name]} </Cell></Tooltip>
      </div>
    } else {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}> 　</div>
    }
  })()

const Tatemonos = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
    <Tooltip placement="bottom-start" title={props.request_list[rowIndex]['Tatemono_Name'] ? props.request_list[rowIndex]['Tatemono_Name'] : props.request_list[rowIndex]['P_Name']}>
      <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
        {props.request_list[rowIndex]['Tatemono_Name'] ? props.request_list[rowIndex]['Tatemono_Name'] : props.request_list[rowIndex]['P_Name']}
      </Cell>
    </Tooltip>
  </div>

const Rooms = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
    <Tooltip placement="bottom-start" title={props.request_list[rowIndex]['Room_Name'] ? props.request_list[rowIndex]['Room_Name'] : props.request_list[rowIndex]['PA_Name']}>
      <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
        {props.request_list[rowIndex]['Room_Name'] ? props.request_list[rowIndex]['Room_Name'] : props.request_list[rowIndex]['PA_Name']}
      </Cell>
    </Tooltip>
  </div>

const Types = ({ column, props, rowIndex, requestopen, zoom_text, mouse_hover }) =>
  (() => {

    if (props.request_list[rowIndex].room_support_m_chat_id) {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Tooltip placement="bottom-start" title={props.request_list[rowIndex][column.key_name]}>
          <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
            アプリ
          </Cell></Tooltip>
      </div>
    } else {
      return <div onMouseEnter={() => mouse_hover(props.request_list[rowIndex]["Outai_No"])} style={{ cursor: "pointer" }} onClick={() => requestopen(props.request_list[rowIndex]["Outai_No"])}>
        <Tooltip placement="bottom-start" title={props.request_list[rowIndex][column.key_name]}>
          <Cell style={{ whiteSpace: "nowrap", fontSize: zoom_text }} >
            {props.request_list[rowIndex][column.key_name]}
          </Cell></Tooltip>
      </div>
    }
  })()


class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      reqtoggle: false,
      zoom_text: 12,
      zoom_height: 30,
      zoom_width: 0,
      now_date: new Date(),
      colSortDirs: {},
      select_row: 0,
      param_on: false,
      ipad_height: 0,
      outai_no: 0,
      kanryou_parse_date: new Date()
    };




    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);
    this.zoomin = this.zoomin.bind(this);
    this.zoomout = this.zoomout.bind(this);
    this.mouse_hover = this.mouse_hover.bind(this);
    this.tab_open = this.tab_open.bind(this);
    this.ipad_mode = this.ipad_mode.bind(this);
    this.kanryou_parse_date = this.kanryou_parse_date.bind(this);
    this.get_kanryou_parse = this.get_kanryou_parse.bind(this);


    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
  }

  kanryou_parse_date = (date) => {
    this.setState({
      kanryou_parse_date: new Date(date),
    });
    // this.props.get_complete_percent({ date: new Date(date) });

  };

  get_kanryou_parse() {
    // this.props.get_complete_percent({ date: this.state.kanryou_parse_date });
  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key_name === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.request_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }




  mouse_hover(Outai_No) {
    this.setState({ select_row: Outai_No })
  }

  tab_open = (Outai_No) => {
    window.open(`/demo/` + Outai_No, '_blank', 'noopener')

    // var url = "https://roomsupport.homestation.jp/demo/" + Outai_No;
    // window.open(url, '_blank', 'noopener');
  }
  requestopen = (Outai_No) => {
    // window.open(`/demo/` + Outai_No, '_blank', 'noopener')

    this.setState({ outai_no: Outai_No })
    this.reqtoggle();
  }


  requestopen2 = (Outai_No) => {
    this.setState({ param_on: true });
    // this.props.get_request_detail({ Outai_No: Outai_No });
    this.reqtoggle();
  }
  reqtoggle() {
    // 閉じた時にデータを空にする
    // リストを検索しなおす
    // チャットを閉じる
    if (this.state.reqtoggle == true) {
      // this.props.get_request_detail({ Outai_No: 0 });
      // this.props.req_search();
      // this.props.get_chat_close();
    }
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }

  zoomin() {
    var zoom_text = this.state.zoom_text + 5;
    var zoom_height = this.state.zoom_height + 5;
    var zoom_width = this.state.zoom_width + 15;
    this.setState({
      zoom_text: zoom_text,
      zoom_height: zoom_height,
      zoom_width: zoom_width
    })
  }

  zoomout() {
    var zoom_text = this.state.zoom_text - 5;
    var zoom_height = this.state.zoom_height - 5;
    var zoom_width = this.state.zoom_width - 15;
    this.setState({
      zoom_text: zoom_text,
      zoom_height: zoom_height,
      zoom_width: zoom_width
    })
  }

  ipad_mode() {
    this.setState({
      ipad_height: this.props.request_list.length * 30,
      columns: ipad_columns
    })
  }

  _rowClassNameGetter(index) {

    if (this.props.request_list[index]) {
      var dateFrom = moment(this.state.now_date)
      var dateTo = moment(this.props.request_list[index]["lasts"])
      // 経過日数を見る
      var diff = dateFrom.diff(dateTo) / 86400000;


      if (this.state.select_row == this.props.request_list[index]["Outai_No"]) {
        return 'pushed-row';
      } else
        if (this.props.request_list[index]["Outai_Joukyou_Name"] == "完了") {
          // 完了は灰色  
          return 'compreted-row';
        }

        // かけつけじゃない場合で対応担当が設定されてない
        else if (this.props.request_list[index]["taiou_user"] == null && this.props.request_list[index]["Gyosha_Name"] == null) {
          return 'compreted-new';
        }
        else if (this.props.request_list[index]["process_flag"] == 0) {
          return 'compreted-chat';
        }
        // 応対中でなおかつ7日経過してたらオレンジ色
        else if (this.props.request_list[index]["Outai_Joukyou_Name"] == "応対中" && diff > 7) {
          return 'compreted-orange';
        }
    }
  }

  render() {

    if (this.props.params && this.state.param_on == false) {
      this.requestopen2(this.props.params);
    }

    return (
      <div className="requestlist">
        <Button onClick={this.zoomin} size="small" variant="outlined" color="primary">
          <i className="fas fa-search-plus"></i>
        </Button>
        <Button onClick={this.zoomout} size="small" variant="outlined" color="primary">
          <i className="fas fa-search-minus"></i>
        </Button>

        {this.props.request_list[0] ?
          <span style={{ marginLeft: 20 }}>{this.props.request_list.length}件</span> : ""}

        <span style={{ fontSize: 15 }}>
          <span>
            <span style={{ marginLeft: 50, color: "#ffff00" }}>■</span> 対応担当未設定
          </span>

          <span>
            <span style={{ marginLeft: 20, color: "#ffad33" }}>■</span> 7日以上未対応
          </span>

          <span>
            <span style={{ marginLeft: 20, color: "lightgray" }}>■</span> 完了
          </span>








        </span>



        <Table
          rowHeight={this.state.zoom_height}
          rowsCount={this.props.request_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={this.props.pathname == "/demo" ? window.innerWidth - 70 : window.innerWidth - 110}
          height={window.innerHeight - 370 + this.state.ipad_height}
          headerHeight={30}
        // rowClassNameGetter={this._rowClassNameGetter}
        >
          {this.props.inset_contents[0] ?
            this.props.inset_contents.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap", fontSize: this.state.zoom_text }}>{value.name.replace("窓", "")}</SortHeaderCell>}
                cell={({ rowIndex, ...props }) => (
                  value.key_name === "type" ? <Types column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Types>
                    : value.key_name === "Room_Name" ? <Rooms column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Rooms>
                      : value.key_name === "Tatemono_Name" ? <Tatemonos column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Tatemonos>
                        : value.key_name === "Link" ? <Links column={value} props={this.props} rowIndex={rowIndex} mouse_hover={this.mouse_hover} requestopen={this.requestopen}></Links>
                          : value.key_name === "shokai_user" ? <Users column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Users>
                            : value.key_name === "lasts" || value.key_name === "created_at" || value.key_name === "Keiyaku_Date" || value.key_name === "complete_date" || value.key_name === "second_date" || value.key_name === "Syokai_Date" ? <Dates column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Dates>
                              : <Base column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Base>
                )}
                width={this.props.inset_contents.filter(a => a.key_name === value.key_name)[0].width + this.state.zoom_width}
                isResizable={true}
                fixed={value.frozen}
              />
            }, this)

            :

            this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key_name}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key_name]}
                  style={{ whiteSpace: "nowrap", fontSize: this.state.zoom_text }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key_name]}</Cell>)}
                cell={({ rowIndex, ...props }) => (
                  value.key_name === "type" ? <Types column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Types>
                    : value.key_name === "Room_Name" ? <Rooms column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Rooms>
                      : value.key_name === "Tatemono_Name" ? <Tatemonos column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Tatemonos>
                        : value.key_name === "Link" ? <Links column={value} props={this.props} rowIndex={rowIndex} mouse_hover={this.mouse_hover} requestopen={this.requestopen}></Links>
                          : value.key_name === "shokai_user" ? <Users column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Users>
                            : value.key_name === "lasts" || value.key_name === "created_at" || value.key_name === "Keiyaku_Date" || value.key_name === "complete_date" || value.key_name === "second_date" || value.key_name === "Syokai_Date" ? <Dates column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Dates>
                              : <Base column={value} props={this.props} rowIndex={rowIndex} requestopen={this.requestopen} zoom_text={this.state.zoom_text} mouse_hover={this.mouse_hover}></Base>
                )}
                width={this.state.columns.filter(a => a.key_name === value.key_name)[0].width + this.state.zoom_width}
                isResizable={true}
                fixed={value.frozen}
              />
            }, this)}
        </Table >


        < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="request" fade={false}>
          {/* <ModalHeader toggle={this.reqtoggle}>リクエスト情報</ModalHeader> */}
          <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle} >リクエスト情報</DialogTitle>

          <RequestDetail
            outai_no={this.state.outai_no}
            reqtoggle={this.reqtoggle}
            ky={this.state.ky} />
          <ModalFooter>
          </ModalFooter>
        </Modal >

      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    // room_details: state.roomsReducer.room_details,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // kanri_m_follows: state.Adjustment.kanri_m_follows,
    // kanri_m_follows_disp: state.Adjustment.kanri_m_follows_disp,
    request_list: state.Adjustment.demorequest_list,
    request_detail: state.Adjustment.request_detail,
    inset_contents: state.Adjustment.inset_contents,
    complete_percent: state.Adjustment.complete_percent,
    pathname: state.router.location.pathname,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_request_detail(state) {
    //   dispatch(Adjustment.get_request_detail(state))
    // },
    // get_chat_close(state) {
    //   dispatch(Chat.get_chat_close(state))
    // },
    // get_kouji_list(state) {
    //   dispatch(RequestDetailSaga.get_kouji_list(state))
    // },
    // get_complete_percent(state) {
    //   dispatch(Adjustment.get_complete_percent(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);