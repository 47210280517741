import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import * as firebase from "firebase";
import '@firebase/firestore' // 👈 If you're using firestore

// import ReduxSagaFirebase from 'redux-saga-firebase'
import { eventChannel } from 'redux-saga'
import { call, put, takeEvery, take, select, delay, all } from 'redux-saga/effects';
import * as LinesApi from '../Api/LinesApi';
import * as TimeLineApi from '../Api/TimeLineApi';
import * as TimeLineResponsesApi from '../Api/TimeLineResponsesApi';
import * as room_support_m_devices_api from '../Api/room_support_m_devices_api';


import * as chat_send_api from '../Api/chat_send_api';
import * as LineUserResponsesApi from '../Api/LineUserResponsesApi';
import { reset } from 'redux-form';
// import firebaseConfig from '../../../firebase-config';
// // 初期化
// const firebaseApp = firebase.initializeApp(firebaseConfig)

// const rsf = new ReduxSagaFirebase(firebaseApp)

// import console = require('console');
// import * as Common from './Common';

export interface ChatState {
    chats: any;
    chat_id: any;
    chat_bottom: boolean;
    chat_send: boolean;
    get_chat_open: any;
    chat_open_flg: boolean;
    process_flag: any;
    process_user: any;
    chatimage: any;
    chat_images: any;
    chat_file_data: any;
    chat_send_modal: number;
    chat_send_file_loading: boolean;
    chat_send_list_loading: boolean;
    Outai_No: any;
    completion_at: any;
    new_chat_id: any;

    chat_file_dialog_disp: boolean;
    room_support_m_devices: any;
    chat_image_disp: boolean;
    c: any;

}

const initialState: ChatState = {
    c: [],
    // chats: [],
    chats: [
        {
            contents
                :
                "初回質問",
            created_at
                :
                "2022-11-29 14:51:39",
            editable
                :
                1,
            message_id
                :
                105834,
            read_at
                :
                "2022-11-29 14:51:40",
            room_support_m_chat_id
                :
                12774,
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"text\",\"text\":\"お問い合わせありがとうございます。\\n対象物件:寺崎荘 103\\n下記よりお問い合わせ内容を選択してください。\\n※営業時間外に頂戴したお問い合わせにつきましては、誠に恐れ入りますが、翌営業日以降のお返事となります。 何卒ご理解の程宜しくお願い致します。 \\n\\n営業時間：平日10:00～18：00\\n(GW、お盆、年末年始を除く)\\n\\n※GW、お盆、年末年始はお休みです。詳細はお知らせなどでご確認ください。\"}",
            template_selected
                :
                "",
            timestamp
                :
                1669701099633,
            type
                :
                5
        },
        {
            contents
                :
                "初回質問",
            created_at
                :
                "2022-11-29 14:51:39",
            editable
                :
                1,
            message_id
                :
                105835,
            read_at
                :
                "2022-11-29 14:51:40",
            room_support_m_chat_id
                :
                12774,
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"select\",\"actions\":[{\"type\":\"postback\",\"label\":\" 鍵・玄関ドア\",\"data\":\"1-1\"},{\"type\":\"postback\",\"label\":\"お部屋内の設備\",\"data\":\"1-2\"},{\"type\":\"postback\",\"label\":\"エントランス・共用部・駐車場・建物設備\",\"data\":\"1-3\"},{\"type\":\"postback\",\"label\":\"騒音\",\"data\":\"1-4\"},{\"type\":\"postback\",\"label\":\"盗難・空き巣\",\"data\":\"1-5\"},{\"type\":\"postback\",\"label\":\"人命に関わること・火災\",\"data\":\"1-6\"},{\"type\":\"postback\",\"label\":\"害虫・鳥獣被害\",\"data\":\"1-7\"},{\"type\":\"postback\",\"label\":\"各種手続き\",\"data\":\"1-8\"},{\"type\":\"postback\",\"label\":\"その他質問など\",\"data\":\"1-9\"}]}",
            template_selected
                :
                "1-2",
            timestamp
                :
                1669701099645,
            type
                :
                5
        },
        {
            contents
                :
                "",
            created_at
                :
                "2022-11-29 14:51:44",
            editable
                :
                1,
            message_id
                :
                105836,
            read_at
                :
                "2022-11-29 14:51:44",
            room_support_m_chat_id
                :
                "12774",
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"text\",\"text\":\"お問い合わせの場所をお選びください。\"}",
            template_selected
                :
                "",
            timestamp
                :
                1669701104084,
            type
                :
                5
        },
        {
            contents
                :
                "",
            created_at
                :
                "2022-11-29 14:51:44",
            editable
                :
                1,
            message_id
                :
                105837,
            read_at
                :
                "2022-11-29 14:51:44",
            room_support_m_chat_id
                :
                "12774",
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"select\",\"actions\":[{\"type\":\"postback\",\"label\":\"キッチンのトラブル\",\"data\":\"1-1\"},{\"type\":\"postback\",\"label\":\"洗面所・トイレ・浴室のトラブル\",\"data\":\"1-2\"},{\"type\":\"postback\",\"label\":\"建具・内装・その他設備\",\"data\":\"1-3\"},{\"type\":\"postback\",\"label\":\"エアコンのトラブル\",\"data\":\"1-4\"},{\"type\":\"postback\",\"label\":\"前の質問に戻る\",\"data\":\"back\"}]}",
            template_selected
                :
                "1-4",
            timestamp
                :
                1669701104084,
            type
                :
                5
        },
        {
            contents
                :
                "",
            created_at
                :
                "2022-11-29 14:51:46",
            editable
                :
                1,
            message_id
                :
                105838,
            read_at
                :
                "2022-11-29 14:51:46",
            room_support_m_chat_id
                :
                "12774",
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"text\",\"text\":\"下記よりお選びください。\"}",
            template_selected
                :
                "",
            timestamp
                :
                1669701106687,
            type
                :
                5
        },
        {
            contents
                :
                "",
            created_at
                :
                "2022-11-29 14:51:46",
            editable
                :
                1,
            message_id
                :
                105839,
            read_at
                :
                "2022-11-29 14:51:47",
            room_support_m_chat_id
                :
                "12774",
            room_support_m_user_id
                :
                "system@global-center.co.jp",
            template
                :
                "{\"type\":\"select\",\"actions\":[{\"type\":\"postback\",\"label\":\"エアコンまたはリモコンが動かない\",\"data\":\"1-1\"},{\"type\":\"postback\",\"label\":\"送風口や壁との設置面より水漏れしている\",\"data\":\"1-2\"},{\"type\":\"postback\",\"label\":\"異音がする\",\"data\":\"1-3\"},{\"type\":\"postback\",\"label\":\"エアコン室外機から水漏れ\",\"data\":\"1-4\"},{\"type\":\"postback\",\"label\":\"冷風、温風が出ない\",\"data\":\"1-5\"},{\"type\":\"postback\",\"label\":\"前の質問に戻る\",\"data\":\"back\"}]}",
            template_selected
                :
                "",
            timestamp
                :
                1669701106687,
            type
                :
                5
        },
        {
            contents
                :
                "上階に人が居る時は絶対音が\nします。",
            created_at
                :
                "2023-02-26 10:16:04",
            editable
                :
                0,
            message_id
                :
                123049,
            read_at
                :
                "2023-02-26 10:16:04",
            room_support_m_chat_id
                :
                "14773",
            room_support_m_user_id
                :
                "EsQtss1uUravLMztPVVDTcsPGK72",
            template
                :
                "",
            template_selected
                :
                "",
            timestamp
                :
                1677374163463,
            type
                :
                1
        }
    ],

    chat_id: 0,
    chat_bottom: false,

    process_flag: 1,
    process_user: "",

    chat_send: false,
    get_chat_open: [],
    chat_open_flg: false,
    chatimage: [],
    chat_images: {},
    chat_file_data: null,
    chat_send_modal: 0,
    chat_send_file_loading: false,
    chat_send_list_loading: false,
    Outai_No: 0,
    completion_at: 0,
    new_chat_id: [],

    chat_file_dialog_disp: false,
    room_support_m_devices: [],
    chat_image_disp: false,
}



///action
const actionCreator = actionCreatorFactory();

export const new_chat = actionCreator<any>('NEW_CHAT');
export const new_chat_success = actionCreator<any>('NEW_CHAT_SUCCESS');


export const set_Outai_No = actionCreator<any>('SET_OUTAI_NO');
export const set_Outai_No_success = actionCreator<any>('SET_OUTAI_NO_SUCCESS');


export const set_process_flag = actionCreator<any>('SET_PROCESS_FLAG');
export const set_process_flag_success = actionCreator<any>('SET_PROCESS_FLAG_SUCCESS');
export const set_process_user_success = actionCreator<any>('SET_PROCESS_USER_SUCCESS');
export const set_completion_at_success = actionCreator<any>('SET_COMPLETION_AT_SUCCESS');


export const get_chats = actionCreator<any>('GET_CHATS');
export const change_chats = actionCreator<any>("CHANGE_CHATS");
export const change_chat_id = actionCreator<any>("CHANGE_CHAT_ID");


export const send_chat_message = actionCreator<any>('SEND_CHAT_MESSAGE');
export const send_chat_outai = actionCreator<any>('SEND_CHAT_OUTAI');


export const change_chat_bottom = actionCreator<any>('CHANGE_CHAT_BOTTOM');

export const change_chat_send = actionCreator<any>('CHANGE_CHAT_SEND');


export const get_chat_open = actionCreator<any>('GET_CHAT_OPEN');
export const get_chat_close = actionCreator<any>('GET_CHAT_CLOSE');


export const change_chat_open_flg = actionCreator<any>('CHANGE_CHAT_OPEN_FLG');

export const get_chat_images = actionCreator<any>('GET_CHAT_IMAGES');
export const change_chat_images = actionCreator<any>('CHANGE_CHAT_IMAGES');

export const change_chat_file_data = actionCreator<any>('CHANGE_CHAT_FILE_DATA');


export const upload_chat_file = actionCreator<any>('UPLOAD_CHAT_FILE');

export const download_chat_file = actionCreator<any>('DOWNLOAD_CHAT_FILE');

export const change_chat_send_modal = actionCreator<number>('CHANGE_CHAT_SEND_MODAL');



export const change_chat_send_file_loading = actionCreator<boolean>('CHANGE_CHAT_SEND_FILE_LOADING');
export const change_chat_send_list_loading = actionCreator<boolean>('CHANGE_CHAT_SEND_LIST_LOADING');

export const change_chat_file_dialog_disp = actionCreator<boolean>('CHANGE_CHAT_FILE_DIALOG_DISP');

export const get_room_support_m_devices = actionCreator('GET_ROOM_SUPPORT_M_DEVICE');
export const change_room_support_m_devices = actionCreator('CHANGE_ROOM_SUPPORT_M_DEVICES');
export const change_chat_image_disp = actionCreator<boolean>('CHANGE_CHAT_IMAGE_DISP');




export const chatReducer = reducerWithInitialState(initialState)
    .case(new_chat_success, (state, payload) => ({ ...state, new_chat_id: payload }))

    .case(set_Outai_No_success, (state, payload) => ({ ...state, Outai_No: payload }))
    .case(set_completion_at_success, (state, payload) => ({ ...state, completion_at: payload }))

    .case(set_process_flag_success, (state, payload) => ({ ...state, process_flag: payload }))
    .case(set_process_user_success, (state, payload) => ({ ...state, process_user: payload }))


    .case(change_chats, (state, payload) => ({ ...state, c: payload }))
    .case(change_chat_bottom, (state, payload) => ({ ...state, chat_bottom: payload }))
    .case(change_chat_send, (state, payload) => ({ ...state, chat_send: payload }))
    .case(change_chat_open_flg, (state, payload) => ({ ...state, chat_open_flg: payload }))
    .case(change_chat_id, (state, payload) => ({ ...state, chat_id: payload }))

    .case(change_chat_images, (state, payload) => ({ ...state, chat_images: payload }))

    .case(change_chat_file_data, (state, payload) => ({ ...state, chat_file_data: payload }))
    .case(change_chat_send_modal, (state, payload) => ({ ...state, chat_send_modal: payload }))
    .case(change_chat_send_file_loading, (state, payload) => ({ ...state, chat_send_file_loading: payload }))

    .case(change_chat_file_dialog_disp, (state, payload) => ({ ...state, chat_file_dialog_disp: payload }))

    .case(change_room_support_m_devices, (state, payload) => ({ ...state, room_support_m_devices: payload }))
    .case(change_chat_image_disp, (state, payload) => ({ ...state, chat_image_disp: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const chatSaga = [takeEvery('GET_CHATS', handle_get_chats),
takeEvery('NEW_CHAT', handle_new_chat),

takeEvery('SET_OUTAI_NO', handle_set_Outai_No),

takeEvery('SEND_CHAT_MESSAGE', handle_send_chat_message),
takeEvery('SEND_CHAT_OUTAI', handle_send_chat_outai),


takeEvery('GET_CHAT_OPEN', handle_get_chat_open),
takeEvery('GET_CHAT_CLOSE', handle_get_chat_close),

takeEvery('GET_CHAT_IMAGES', handle_get_chat_images),

takeEvery('UPLOAD_CHAT_FILE', handle_upload_chat_file),

takeEvery('DOWNLOAD_CHAT_FILE', handle_download_chat_file),

takeEvery('SET_PROCESS_FLAG', handle_set_process_flag),

takeEvery('GET_ROOM_SUPPORT_M_DEVICE', handle_get_room_support_m_devices),

];



// 新しいチャットを作る　帰ってきたidでチャットを開く
function* handle_new_chat(action: any) {
    const { payload, error } = yield call(chat_send_api.chat_new, action.payload);
    if (payload && !error) {
        yield put(new_chat_success(payload));

    } else {

    }

}

// 応対NOをセットする
function* handle_set_Outai_No(action: any) {
    yield put(set_Outai_No_success(action.payload));
}

function* handle_send_chat_outai(action: any) {

    const { payload, error } = yield call(chat_send_api.chat_insert, action.payload);

}



function* handle_set_process_flag(action: any) {
    yield put(set_process_flag_success(action.payload.process_flag));
    yield put(set_process_user_success(action.payload.process_user));
    yield put(set_Outai_No_success(action.payload.Outai_No))
    yield put(set_completion_at_success(action.payload.completion_at))

}



function* handle_get_chats(action: any) {
    yield put(change_chat_bottom(true));
    yield put(change_chat_open_flg(true))
    yield put(change_chat_id(action.payload));
    yield put(change_chat_open_flg(true))

}







function* handle_get_chat_open(action: any) {


    const { payload, error } = yield call(TimeLineApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert()
        // 成功時アクション呼び出し
        // yield put(change_chats(payload))
        yield put(change_chat_bottom(true));
        // yield put(change_get_mail_details_loading(0));
        yield put(change_chat_open_flg(true))

    } else {


    }
}



function* handle_get_chat_close(action: any) {

    yield put(change_chat_open_flg(false))
}



function* handle_send_chat_message(action: any) {

    if (action.payload.contents) {
        yield put(change_chat_send(true));
    }
    const { payload, error } = yield call(chat_send_api.send, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(change_chat_send(false));


    } else {


    }
}




function* handle_get_chat_images(action: any) {



    const { payload, error } = yield call(LinesApi.get_images, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        let bloburl = URL.createObjectURL(payload)
        // console.log(bloburl)
        // 成功時アクション呼び出し
        yield put(change_chat_images({
            id: action.payload,
            bloburl: bloburl
        }))



    } else {


    }
}

// 画像送信
function* handle_upload_chat_file(action: any) {

    const state = yield select();
    yield put(change_chat_send_file_loading(true));

    // alert(state.chat.chat_file_data.name)
    // alert(state.chat.chat_file_data.mimeType)
    // alert(state.chat.chat_id)

    const { payload, error } = yield call(chat_send_api.upload, { room_support_m_chat_id: state.chat.chat_id, name: state.chat.chat_file_data.name, file: state.chat.chat_file_data.file, mime_type: state.chat.chat_file_data.mime_type });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(change_chat_file_data(null));
        yield put(change_chat_send_file_loading(false));
        yield put(change_chat_send_modal(0));

        yield put(change_chat_file_dialog_disp(false));


    } else {
        alert(error)

    }
}



function* handle_download_chat_file(action: any) {

    const state = yield select();

    const { payload, error } = yield call(LinesApi.download_file, action.payload.file_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.message_text;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_room_support_m_devices(action: any) {
    const state = yield select();

    const { payload, error } = yield call(room_support_m_devices_api.detail, { chat_id: state.chat.chat_id });
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_room_support_m_devices(payload))

        let flg = true
        payload.forEach(function (value: any) {

            if (value.app_versions && value.app_versions != "") {
                if (value.app_versions.split(".").length > 1) {
                    if (parseInt(value.app_versions.split(".")[0]) <= 2) {
                        if (parseInt(value.app_versions.split(".")[0]) < 2) {
                            flg = false
                        } else {
                            if (parseInt(value.app_versions.split(".")[1]) <= 2) {
                                flg = false
                            }
                        }

                    }

                } else {
                    flg = false
                }
            } else {
                flg = false
            }

        });
        yield put(change_chat_image_disp(flg))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}