import React, { Component } from 'react';
// import firebase from "firebase";
import firebase from "firebase/app"
// import 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
import Fab from '@material-ui/core/Fab';
// import { firebaseDb } from '../../Store/FirebaseStore';
import './Css/Chat.css';
import ChatRightText from './ChatRightText';
import ChatRightImg from './ChatRightImg';
import ChatRightType1 from './ChatRightType1';
import ChatRightType2 from './ChatRightType2';
import ChatRightType3 from './ChatRightType3';
import ChatRightType4 from './ChatRightType4';
import ChatRightType5 from './ChatRightType5';
import ChatRightType5Select from './ChatRightType5Select';
import ChatRightType5Tel from './ChatRightType5Tel';
import ChatRightType5Link from './ChatRightType5Link';
import ChatLeftText from './ChatLeftText';
import ChatLeftText2 from './ChatLeftText2';

import ChatLeftImg from './ChatLeftImg';
import ChatLeftFile from './ChatLeftFile';
import ChatTime from './ChatTime';
import ChatForm from './ChatForm.js';
import ChatRightFile from './ChatRightFile';
import { smsDB } from '../../Store/FirebaseStore';
import throttle from 'lodash.throttle';
import Chip from '@material-ui/core/Chip';
import * as RequestDetailSaga from '../RequestDetail/Saga/RequestDetail';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import * as MessageList from '../MessageList/Saga/MessageList';
import * as Adjustment from '../Adjustment/Saga/Adjustment';

import * as Chat from './Saga/Chat';
let unsub
var chats
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }



var timestamp = ""
class Chats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            reflesh: false,
            button_disp: true
        };
        console.log(this.props.chats)
        chats = this.props.chats;


        this.chat_process_on = this.chat_process_on.bind(this);

        this.props.get_room_support_m_devices()
            ;

    }


    format(value) {

        // alert(value.type)

        if (parseInt(value.type) === 5) {
            var template = JSON.parse(value.template);
            // if (value.template_selected == "") {
            if (template.type == "tel") {
                return <ChatRightType5Tel msg={value} />
            } else if (template.type == "link" || template.type == "form") {
                return <ChatRightType5Link msg={value} />
            } else if (template.type == "select") {
                // var template = JSON.parse(value.template);
                // alert(template.type)
                var text = "";

                if (template.actions) {
                    template.actions.forEach(element => {
                        if (element.data == value.template_selected) {
                            text = element.label
                        }
                    });
                    console.log("-----")
                    console.log(value.created_at);
                    // return <ChatLeftText2 msg={text} template_actions={template.actions} value={value} created_at={value.created_at} />
                    return <ChatRightType5Select msg={text} template_actions={template.actions} value={value} created_at={value.created_at} />
                } else if (template.type == 'floorSelect') {
                    // 階数を選んだ時の表示
                    console.log("-----aaaaaa")
                    console.log(value);
                    var kai = value.template_selected.split('-')[1]
                    return <ChatLeftText2 msg={kai} created_at={value.created_at} />
                }
            } else {
                if (JSON.parse(value.template).text != "") {
                    return <ChatRightType5 msg={value} />
                }

            }

        } else {


            if (value.room_support_m_user_id === 'system@global-center.co.jp') {



                if (value.type === 1) {
                    return <ChatRightText msg={value} />
                } else if (value.type === 2) {
                    if (this.props.chat_image_disp) {
                        return <ChatRightImg msg={value} />
                    }

                    // } else if (value.type === 3) {
                    //     return <ChatRightFile msg={value} />
                } else if (value.type === '1') {//最初の
                    return <ChatRightType1 msg={value} />
                } else if (value.type === '2') {//店舗
                    return <ChatRightType2 msg={value} />
                } else if (value.type === '3') {//間取り
                    return <ChatRightType3 msg={value} />
                } else if (value.type === '4') {//賃料
                    return <ChatRightType4 msg={value} />
                } else if (parseInt(value.type) === 5) {//物件
                    // return <ChatRightType5 msg={value} />
                } else if (value.type === "file") {//ファイル
                    return <ChatRightFile msg={value} />
                } else {
                    //message = snapshot.val().type;
                }

            } else {

                // alert(value.type +" " +JSON.stringify(value))
                if (value.type === 1) {
                    return <ChatLeftText msg={value} />
                } else if (value.type === 3) {//ファイル
                    return <ChatLeftFile msg={value} />
                } else if (value.type === 2) {
                    // if (this.props.chat_image_disp) {
                        return <ChatLeftImg msg={value} />
                    // }

                } else {
                    // return <ChatLeftText msg={value} />
                }
            }
        }




    }


    async componentDidMount() {

        this.props.change_chat_bottom(true);


        if (unsub) {
            // alert("exist")
            // unsub()
        }


        this.props.chats.map(function (value) {
            timestamp = value.timestamp > timestamp ? value.timestamp : timestamp
        })
        // let times = Math.floor(new Date().getTime() / 1000)
        // alert(timestamp)
        // alert(times)
        // var db_response = firebase.firestore();

        // unsub = smsDB.collection("chat").where("chat_user_id", "==", this.props.chat_user_details.id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")
        // unsub = smsDB.collection("sms")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        //             // alert(timestamp)
        //             alert("ddd")
        //             console.log(change.doc.data())
        //             this.props.change_chats(chats.concat(change.doc.data()))
        //             this.props.change_chat_bottom(true);
        //             // this.setState({ reflesh: !this.state.reflesh })
        //         }, this)
        //     });

    }
    componentDidUpdate() {
        chats = this.props.chats

    }
    componentWillUnmount() {
        // alert("unmount")
    }



    chat_process_on() {
        this.setState({ button_disp: false })

        var data = {
            user: this.props.user_details.id,
            id: this.props.chat_id
        }
        this.props.chat_kanryou(data);

        setTimeout(() => {

            this.props.newmessage_customers(this.props.user_details.amSectionId);


            if (this.props.chatlist.m_customers) {
                this.props.get_chatlist({
                    m_customer_id: this.props.chatlist.m_customers[0].id,
                    room_support_m_user_id: this.props.chatlist.m_customers[0].room_support_m_user_id
                });
            }

            if (this.props.Outai_No) {
                this.props.get_request_detail({ Outai_No: this.props.Outai_No });
            }

        }, 1000)
    }

    render() {


        return (


            <div className="chat-bc" id="chat-bc">
                {this.props.chats.map(function (value) {
                    return this.format(value)
                }, this)}


                <div style={{ marginTop: 20, marginBottom: 110 }}>
                    {this.props.process_flag == 1 ?

                        <span>
                            <Chip label={this.props.process_user + " にて確認済み"} variant="outlined" />

                        </span> :

                        this.state.button_disp ? <button
                            onClick={this.chat_process_on}
                            className="btn btn-primary m-10"
                            style={{ margin: "5px" }}
                        >チャット確認済みにする</button>
                            : "確認済み"
                    }
                </div>
            </div>





        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        chat_bottom: state.chat.chat_bottom,
        chat_user_details: state.chat.chat_user_details,
        chats: state.chat.chats,
        chat_open_flg: state.chat.chat_open_flg,
        process_flag: state.chat.process_flag,
        process_user: state.chat.process_user,
        chat_id: state.chat.chat_id,
        user_details: state.masterReducer.user_details,
        chatlist: state.Keiyaku.chatlist,
        chat_image_disp: state.chat.chat_image_disp,
        Outai_No: state.chat.Outai_No,


        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_chat_message(state) {
            dispatch(Chat.send_chat_message(state))
        },

        change_chats(state) {
            dispatch(Chat.change_chats(state))
        },
        change_chat_bottom(state) {
            dispatch(Chat.change_chat_bottom(state))
        },
        get_chat_close(state) {
            dispatch(Chat.get_chat_close(state))
        },
        get_chat_open(state) {
            dispatch(Chat.get_chat_open(state))
        },
        chat_kanryou(state) {
            dispatch(RequestDetailSaga.chat_kanryou(state))
        },
        get_chats(state) {
            dispatch(Chat.get_chats(state))
        },
        get_chatlist(state) {
            dispatch(Keiyaku.get_chatlist(state))
        },
        newmessage_customers(state) {
            dispatch(MessageList.newmessage_customers(state))
        },
        get_room_support_m_devices(state) {
            dispatch(Chat.get_room_support_m_devices(state))
        },
        get_request_detail(state) {
            dispatch(Adjustment.get_request_detail(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Chats);
