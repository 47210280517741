import React from 'react';
import Button from '@material-ui/core/Button';

import styled from 'styled-components';

var moment = require('moment-timezone');
const Balloon = styled.div`
position:relative;
padding:0px;
margin: 10px 0;
margin-top:15px;
`;
const Chatting = styled.div`
width: 100%;
text-align: right;
`;
const Say = styled.div`
display: inline-block;
position: relative; 
margin: 0 60px 0 60px;
padding: 0px;
max-width: 280px;
border-radius: 12px;

margin-bottom: 2px;
text-align: left;

`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;bottom: 0px;
    font-size:10px;
    color:#FFF;
`;
const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;


export default class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = this.props.created_at ? new Date(this.props.created_at) : "";
        console.log("-----------------");
        console.log(this.props);
        this.state = {
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };

    }

    render() {
        return (

            <Balloon className="col-xs-12" >
                {/* <Faceicon><FaceiconImg src={this.props.msg.img_url} /></Faceicon>
                <DisplayName>{this.props.msg.display_name}</DisplayName> */}
                <Chatting>
                    <Say>
                        {/* <MsgArea> */}
                            {/* {this.props.msg} */}
                            
                            
                            {/* <span dangerouslySetInnerHTML={{ __html: this.props.msg.contents.replace(/\r?\n/g, '<br/>') }} /> */}
                        {/* </MsgArea> */}

                        {
                            this.props.template_actions.map(element => {
                                if (element.data == this.props.value.template_selected) {
                                    return <Button disabled={true} style={{border:"1px solid gray", backgroundColor:"#00ccbb", marginTop:3, marginBottom:3, marginLeft:5, marginRight:5, color:"white"}}>{element.label}</Button>
                                }else if (this.props.value.template_selected == "") {
                                    return <Button disabled={true} style={{border:"1px solid gray", backgroundColor:"white", marginTop:3, marginBottom:3, marginLeft:5, marginRight:5, color:"black"}}>{element.label}</Button>
                                }else{
                                    return <Button disabled={true} style={{border:"1px solid gray", backgroundColor:"#CCCCCC", marginTop:3, marginBottom:3, marginLeft:5, marginRight:5, color:"gray"}}>{element.label}</Button>
                                }

                            })
                        }
                        
  
                    </Say>
                    {/* <Time>
                        <span style={{ color: "black" }}>
                            {moment.utc(this.props.created_at).format('MM/DD HH:mm')}
                        </span>
                    </Time> */}
                    {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
                </Chatting>
            </Balloon>

        )
    }
}
