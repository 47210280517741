import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as CustomerLinkApi from '../Api/CustomerLink';


// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    list: any,
    linklist: any,
    linking: any,
    family: any
}
const initialState: State = {
    list: [],
    linklist: [],
    linking: [],
    family: []
}



///action 
const actionCreator = actionCreatorFactory();

export const get_list = actionCreator('GET_LIST');
export const get_list_success = actionCreator('GET_LIST_SUCCESS');

export const get_linklist = actionCreator('GET_LINKLIST');
export const get_linklist_search = actionCreator('GET_LINKLIST_SEARCH');
export const get_linklist_success = actionCreator('GET_LINKLIST_SUCCESS');

export const linking = actionCreator('LINKING');
export const linking_success = actionCreator('LINKING_SUCCESS');

export const get_family = actionCreator('GET_FAMILY');
export const get_family_success = actionCreator('GET_FAMILY_SUCCESS');

export const linking_same = actionCreator('LINKING_SAME');

export const linking_notsame = actionCreator('LINKING_NOTSAME');

export const link_delete = actionCreator('LINK_DELETE');
export const devices_delete = actionCreator('DEVICES_DELETE');


export const CustomerLinkReducer = reducerWithInitialState(initialState)
    .case(get_list_success, (state, payload) => ({ ...state, list: payload }))
    .case(get_linklist_success, (state, payload) => ({ ...state, linklist: payload }))
    .case(linking_success, (state, payload) => ({ ...state, linking: payload }))
    .case(get_family_success, (state, payload) => ({ ...state, family: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const CustomerLinkSaga = [
    takeEvery('GET_LIST', handle_get_list),
    takeEvery('GET_LINKLIST', handle_get_linklist),
    takeEvery('GET_LINKLIST_SEARCH', handle_get_linklist_search),

    takeEvery('LINKING', handle_linking),
    takeEvery('LINKING_SAME', handle_linking_same),
    takeEvery('LINKING_NOTSAME', handle_linking_notsame),
    takeEvery('GET_FAMILY', handle_get_family),
    takeEvery('LINK_DELETE', handle_link_delete),
    takeEvery('DEVICES_DELETE', handle_devices_delete),


];
// export default masterSaga;


function* handle_get_list(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.get_notlinks, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_list_success(payload));
    } else {
        alert(error)
    }
}


function* handle_get_linklist(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.get_links, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_linklist_success(payload));
    } else {
        alert(error)
    }
}



function* handle_get_linklist_search(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.get_links_search, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_linklist_success(payload));
    } else {
        alert(error)
    }
}


function* handle_get_family(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.get_familys, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_family_success(payload));
    } else {
        alert(error)
    }
}


function* handle_linking(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.linking, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(linking_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_linking_same(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.linking_same, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(linking_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_linking_notsame(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.linking_notsame, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(linking_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_link_delete(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.link_delete, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
    } else {
        // alert(error)
    }
}



function* handle_devices_delete(action: any) {
    const { payload, error } = yield call(CustomerLinkApi.devices_delete, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
    } else {
        // alert(error)
    }
}


