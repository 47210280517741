
const authentication = require('react-azure-adb2c2').default;






export const send = (values) => {

    // let url = "http://localhost:5000/v1/sms/sms_sendonly";
    let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/sms_sendonly`;

    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


