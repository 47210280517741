
const authentication = require('../../../react-azure-adb2c2').default;






export const list = (values) => {

    // alert()
    let url = process.env.REACT_APP_API_LINE_URL + `/api/TimeLineResponses/` + values + '/20';
    // let url = `https://localhost:44385/api/TimeLine/`+values+'/40';

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



