
export const detail = (values: any) => {


    // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

    // let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/owner/remittance/check/log`;
    let url = `https://192.168.0.202/fuelphp/public/contraction/rest/list/kysreact.json`

    // let url = `http://localhost:5000/api/v1/owner/remittance/check/logs`;
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',

        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
        body: JSON.stringify({ kys_no: values }),
        // headers: { 'Content-type': 'application/json' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

export const biko = (values: any) => {


    // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

    // let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/owner/remittance/check/log`;
    let url = `https://192.168.0.202/fuelphp/public/contraction/rest/list/kysbikoreact.json`

    // let url = `http://localhost:5000/api/v1/owner/remittance/check/logs`;
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',

        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
        body: JSON.stringify(values),
        // headers: { 'Content-type': 'application/json' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}