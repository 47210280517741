import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Kys from '../Kys/Index';
import * as KysSaga from '../Kys/Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as Line from '../Line/Saga/Line';
import * as Chat from '../Chat/Saga/Chat';
import * as Sms from '../Sms/Saga/Sms';
import * as Mail from '../Mail/Saga/Mail';
import FileGrid from "./FileGrid";
import EditOutai from "./EditOutai";
import Button from '@material-ui/core/Button';

interface Props {
}



interface State {
}


class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
        }
    }
    render() {


        return (
            <div>
                契約情報出したい
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




