import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import './Css/Sms.css';

var moment = require('moment-timezone');



const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
margin-top:15px;
`;

const Smsting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #7fff97;
    margin-bottom: 2px;
    text-align: left;
    &:after {
        content: "";
        position: absolute;
        top: 3px; 
        right: -19px;
        border: 8px solid transparent;
        border-left: 18px solid #7fff97;
        -ms-transform: rotate(-35deg);
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
    }
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;

export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '1',
            messages: [],

            date_time: moment(this.props.msg.date).tz("Asia/Tokyo").format('MM/DD HH:mm'),

        };
    }

    render() {

        var send_user = this.props.msg.send_section_name + " " + this.props.msg.send_user_name;

        return (
            <Balloon className="col-xs-12">
                <Smsting>
                    {/* <Time style={{ color: "#000000" }}>{this.props.msg.returnsmsdatetime}</Time> */}
                    <Time style={{ color: "#000000" }}>{moment.utc(this.props.msg.returnsmsdatetime, 'YYYY年MM月DD日 HH:mm:ss').format('MM/DD HH:mm')}</Time>
                    <Says>
                        <MsgArea >  <span dangerouslySetInnerHTML={{ __html: this.props.msg.message.replace(/\r?\n/g, '<br/>') }} /></MsgArea>
                    </Says>
                    <Tooltip id="SmsTooltip" placement="bottom-start" title={send_user}>
                        <i style={{ marginLeft: 10, fontSize: 20 }} className="fas fa-user-circle"></i>
                    </Tooltip>
                </Smsting>
            </Balloon>
        )
    }
}
