
const authentication = require('../../../react-azure-adb2c2').default;






export const send = (values) => {

    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/send/text/`;
    // let url = process.env.REACT_APP_API_LINE_URL + `/api/LineSends/`;
    // let url = `https://localhost:44385/api/LineSends/`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const send_line_stock_rooms = (values) => {
    // let url = `http://localhost:5000/v1/line/send/carousel/`;

    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/send/carousel/`;
    // let url = `https://localhost:44385/api/LineSends/`;

    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const upload = (values) => {

    // let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/image/`;
    // let url = process.env.REACT_APP_API_LINE_URL + `/api/LineSends/`;
    // let url = `http://localhost:5000/v1/line/send/upload`;
    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/send/upload/`;
    const token = authentication.getAccessToken();


    var formData = new FormData();
    formData.append("files", values.file[0]);
    formData.append("user_id", values.user_id);
    formData.append("name", values.name);
    formData.append("rent_response_id", values.rent_response_id);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))


    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
