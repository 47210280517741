import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as request_detail_api from '../Api/request_detail_api';


// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    resあああponselist: string[];
//    responseedさit: number;
//}
export interface State {
    request_file: any;
    past_request: any;
    keiyaku: any;
    kouji: any;
    excel: any;
    new_syousai: any;
    file_delete: any;
    kouji_list: any;
    ikkatsu_file: any;
    sms_list: any;
    app_file: any;
}
const initialState: State = {
    request_file: [],
    past_request: [],
    keiyaku: [],
    kouji: [],
    excel: [],
    new_syousai: [],
    file_delete: [],
    kouji_list: [],
    ikkatsu_file: [],
    sms_list: [],
    app_file: []
}



///action 
const actionCreator = actionCreatorFactory();

export const get_request_file = actionCreator('GET_REQUEST_FILE');
export const get_request_file_success = actionCreator('GET_REQUEST_FILE_SUCCESS');

export const get_request_excels = actionCreator('GET_REQUEST_EXCELS');
export const get_request_excels_success = actionCreator('GET_REQUEST_EXCELS_SUCCESS');


export const outai_update = actionCreator('OUTAI_UPDATE');
export const outai_complete = actionCreator('OUTAI_COMPLETE');
export const outai_delete = actionCreator('OUTAI_DELETE');

export const chat_kanryou = actionCreator('CHAT_KANRYOU');
export const chat_complet = actionCreator('CHAT_COMPLET');


export const syousai_insert = actionCreator('SYOSAI_INSERT');
export const syousai_insert_success = actionCreator('SYOSAI_INSERT_SUCCESS');


export const syousai_update = actionCreator('SYOSAI_UPDATE');

export const get_past_request = actionCreator('GET_PAST_REQUEST');
export const get_past_request_success = actionCreator('GET_PAST_REQUEST_SUCCESS');

export const get_keiyaku = actionCreator('GET_KEIYAKU');
export const get_keiyaku_success = actionCreator('GET_KEIYAKU_SUCCESS');

export const get_kouji = actionCreator('GET_KOUJI');
export const get_kouji_success = actionCreator('GET_KOUJI_SUCCESS');

export const delete_request_file = actionCreator('DELETE_REQUEST_FILE');
export const delete_request_file_success = actionCreator('DELETE_REQUEST_FILE_SUCCESS');

export const get_kouji_list = actionCreator('GET_KOUJI_LIST');
export const get_kouji_list_success = actionCreator('GET_KOUJI_LIST_SUCCESS');

export const chat_plus = actionCreator('CHAT_PLUS');
export const sms_insert = actionCreator('SMS_INSERT');

export const get_file = actionCreator('GET_FILE');
export const get_file_success = actionCreator('GET_FILE_SUCCESS');

export const get_sms = actionCreator('GET_SMS');
export const get_sms_success = actionCreator('GET_SMS_SUCCESS');

export const get_app_files = actionCreator('GET_APP_FILES');
export const get_app_files_success = actionCreator('GET_APP_FILES_SUCCESS');



export const RequestDetailReducer = reducerWithInitialState(initialState)
    .case(get_request_file_success, (state, payload) => ({ ...state, request_file: payload }))
    .case(get_past_request_success, (state, payload) => ({ ...state, past_request: payload }))
    .case(get_keiyaku_success, (state, payload) => ({ ...state, keiyaku: payload }))
    .case(get_kouji_success, (state, payload) => ({ ...state, kouji: payload }))
    .case(get_request_excels_success, (state, payload) => ({ ...state, excel: payload }))
    .case(syousai_insert_success, (state, payload) => ({ ...state, new_syousai: payload }))
    .case(delete_request_file_success, (state, payload) => ({ ...state, file_delete: payload }))
    .case(get_kouji_list_success, (state, payload) => ({ ...state, kouji_list: payload }))
    .case(get_file_success, (state, payload) => ({ ...state, ikkatsu_file: payload }))
    .case(get_sms_success, (state, payload) => ({ ...state, sms_list: payload }))
    .case(get_app_files_success, (state, payload) => ({ ...state, app_file: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const RequestDetailSaga = [
    takeEvery('GET_REQUEST_FILE', handle_get_request_file),
    takeEvery('GET_REQUEST_EXCELS', handle_get_request_excels),

    takeEvery('SYOSAI_INSERT', handle_syousai_insert),
    takeEvery('SYOSAI_UPDATE', handle_syousai_update),
    takeEvery('OUTAI_UPDATE', handle_outai_update),
    takeEvery('OUTAI_COMPLETE', handle_outai_complete),
    takeEvery('OUTAI_DELETE', handle_outai_delete),
    takeEvery('CHAT_KANRYOU', handle_chat_kanryou),
    takeEvery('CHAT_COMPLET', handle_chat_complet),
    takeEvery('GET_KOUJI', handle_get_kouji),
    takeEvery('GET_PAST_REQUEST', handle_get_past_request),
    takeEvery('GET_KEIYAKU', handle_get_keiyaku),
    takeEvery('DELETE_REQUEST_FILE', handle_delete_request_file),
    takeEvery('GET_KOUJI_LIST', handle_get_kouji_list),
    takeEvery('CHAT_PLUS', handle_chat_plus),
    takeEvery('SMS_INSERT', handle_sms_insert),
    takeEvery('GET_FILE', handle_get_file),
    takeEvery('GET_SMS', handle_get_sms),

];
// export default masterSaga;


function* handle_get_sms(action: any) {
    const { payload, error } = yield call(request_detail_api.get_sms_by_mobilenumber, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_sms_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_file(action: any) {
    const { payload, error } = yield call(request_detail_api.get_file, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_file_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_kouji_list(action: any) {
    const { payload, error } = yield call(request_detail_api.kouji_list_for_request, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_kouji_list_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_request_excels(action: any) {
    const { payload, error } = yield call(request_detail_api.get_request_excels, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_request_excels_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_kouji(action: any) {

    const { payload, error } = yield call(request_detail_api.get_kouji, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_kouji_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_keiyaku(action: any) {

    const { payload, error } = yield call(request_detail_api.get_keiyaku, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_keiyaku_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_past_request(action: any) {

    const { payload, error } = yield call(request_detail_api.past_request, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_past_request_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_request_file(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.request_getfile, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_request_file_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_syousai_insert(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.insert, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(syousai_insert_success(payload));
    } else {
        // alert("error")
    }
}


function* handle_syousai_update(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.update, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}


function* handle_outai_delete(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.Outai_delete, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}

function* handle_outai_update(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.Outai_update, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}

function* handle_outai_complete(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.Outai_complete, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}


function* handle_chat_kanryou(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.chat_kanryou, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}


function* handle_chat_complet(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.chatcomplet_byid, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_request_file_success(payload));
    } else {
        // alert("error")
    }
}

function* handle_delete_request_file(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.request_file_delete, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(delete_request_file_success(payload));
    } else {
        // alert("error")
    }
}


function* handle_chat_plus(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.Outai_chat_plus, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
    } else {
    }
}

function* handle_sms_insert(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_detail_api.sms_insert, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
    } else {
    }
}