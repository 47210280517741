import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import * as RequestDetailSaga from './Saga/RequestDetail';
import * as Adjustment from '../Adjustment/Saga/Adjustment';

import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from './Index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
    return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        var { onSortChange, sortDir, children, ...props } = this.props;
        return (
            <Cell {...props} onClick={this._onSortChange}>

                {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

            </Cell>
        );
    }

    _onSortChange(e) {

        e.preventDefault();

        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}
const columns =
    [
        { key: 'link', name: '', width: 30, resizable: true },
        { key: 'sended_at', name: '送信日時', width: 200, resizable: true },
        { key: 'user_name_all', name: '送信担当', width: 130, resizable: true },
        { key: 'message', name: '内容', width: 600, resizable: true },
        { key: 'return_code', name: '送信結果', width: 100, resizable: true },
    ]

const Base = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
    (() => {
        if (props[rowIndex][column.key]) {
            return <div onMouseEnter={() => mouse_hover(props[rowIndex]["id"])} style={{ cursor: "pointer" }}>
                <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ overflowWrap: 'break-word' }} > {props[rowIndex][column.key]} </Cell></Tooltip>
            </div>
        } else {
            return <div style={{ cursor: "pointer" }} onClick={() => requestopen(props[rowIndex]["Outai_No"])}>　　</div>
        }
    })()

const Date = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
    (() => {
        if (props[rowIndex][column.key]) {
            return <div onMouseEnter={() => mouse_hover(props[rowIndex]["id"])} style={{ cursor: "pointer" }}>
                {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).utc().format('YYYY-MM-DD HH:mm:ss') : ""}
            </div>
        } else {
            return <div style={{ cursor: "pointer" }} onClick={() => requestopen(props[rowIndex]["Outai_No"])}>　　</div>
        }
    })()


const Link = ({ column, props, rowIndex, url_open, mouse_hover }) =>
    (() => {
        var url = "https://roomsupport.homestation.jp/RequestDetail/" + props[rowIndex]["Outai_No"];
        return <div onMouseEnter={() => mouse_hover(props[rowIndex]["id"])} >
            <Cell style={{ whiteSpace: "nowrap" }} >
                <a href={url} className="fas fa-external-link-alt" target="_blank"></a>
            </Cell>
        </div>
    })()


const Result = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
    (() => {
        if (props[rowIndex][column.key]) {
            return <div onMouseEnter={() => mouse_hover(props[rowIndex]["id"])} style={{ cursor: "pointer" }}>
                <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ overflowWrap: 'break-word' }} > {props[rowIndex][column.key] == "200" ? "成功" : "失敗"} </Cell></Tooltip>
            </div>
        } else {
            return <div style={{ cursor: "pointer" }} onClick={() => requestopen(props[rowIndex]["Outai_No"])}>　　</div>
        }
    })()


class CustomerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: columns,
            reflash: false,
            reqtoggle: false,

            colSortDirs: {},
            Renraku_Saki: props.request_detail.detail[0].Renraku_Saki.replace("-", "").replace("-", "").replace("　", "").replace(" ", "").replace(/\r?\n/g, ""),

        };



        this.onChangeRenraku_Saki = this.onChangeRenraku_Saki.bind(this);
        this.search = this.search.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);
        this.reqtoggle = this.reqtoggle.bind(this);
        this.url_open = this.url_open.bind(this);
        this.mouse_hover = this.mouse_hover.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

    }
    _onColumnResizeEndCallback(newColumnWidth, columnKey) {

        let columnsset = this.state.columns.map(function (value) {
            if (value.key === columnKey) {
                value.width = newColumnWidth
            }
            return value
        })

        this.setState({ columns: columnsset })

    }


    mouse_hover(Outai_No) {
        this.setState({ select_row: Outai_No })
    }



    _onSortChange(columnKey, sortDir) {

        let seisan_follow_search_list = this.props.request_detail.detail;
        seisan_follow_search_list.sort((indexA, indexB) => {
            var valueA = indexA[columnKey];
            var valueB = indexB[columnKey];
            var sortVal = 0;
            if (valueA > valueB) {
                sortVal = 1;
            }
            if (valueA < valueB) {
                sortVal = -1;
            }

            if (!valueA && !valueB) {
                sortVal = 0
            }
            if (!valueA) {
                sortVal = -1
            }
            if (!valueB) {
                sortVal = 1
            }


            if (sortVal !== 0 && sortDir === SortTypes.DESC) {
                sortVal = sortVal * -1;
            }

            return sortVal;
        });
        this.setState({
            // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
            colSortDirs: {
                [columnKey]: sortDir,
            },
        });
    }

    requestopen = (Outai_No) => {
        this.props.get_request_detail({ Outai_No: Outai_No });
        this.props.tabreset();
    }
    reqtoggle() {
        if (this.state.reqtoggle == true) {
            this.props.get_sms(0);
        }
        this.setState({ reqtoggle: !this.state.reqtoggle })
    }

    url_open(outai_no) {
        var url = 'https://www.homestation.jp/manage/request/detail?no=' + outai_no;
        window.open(url, '_blank')
    }

    _rowClassNameGetter(index) {

        if (this.props.sms_list[index]) {
            if (this.state.select_row == this.props.sms_list[index]["id"]) {
                return 'pushed-row';
            }
        }
    }
    onChangeRenraku_Saki(e) {
        this.setState({
            Renraku_Saki: e.target.value,
        });
    }
    search() {
        this.props.get_sms(this.state.Renraku_Saki);
    }

    render() {

        return (
            <div id="seisanlist">
                <div>
                    電話番号：<TextField
                        value={this.state.Renraku_Saki}
                        onChange={this.onChangeRenraku_Saki}
                        style={{ width: 200 }}
                        id="standard-basic" />
                    <Button
                        style={{
                            marginLeft: "50px"
                        }}
                        onClick={this.search}
                        variant="outlined" color="primary">
                        検索
          </Button>※番号以外の文字があると検索できません
                </div>
                <Table
                    rowHeight={200}
                    rowsCount={this.props.sms_list.length}
                    // rowsCount={10}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    width={'100%'}
                    height={window.innerHeight - 200}
                    headerHeight={30}
                    rowClassNameGetter={this._rowClassNameGetter}>

                    {columns.map(function (value, i) {
                        return <Column
                            columnKey={value.key}
                            header={<SortHeaderCell
                                onSortChange={this._onSortChange}
                                sortDir={this.state.colSortDirs[value.key]}
                                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                            // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                            cell={({ rowIndex, ...props }) => (
                                value.key === "return_code" ? <Result column={value} props={this.props.sms_list} rowIndex={rowIndex} url_open={this.url_open} mouse_hover={this.mouse_hover}></Result>
                                    : value.key === "link" ? <Link column={value} props={this.props.sms_list} rowIndex={rowIndex} url_open={this.url_open} mouse_hover={this.mouse_hover}></Link>
                                        : value.key === "sended_at" ? <Date column={value} props={this.props.sms_list} rowIndex={rowIndex} requestopen={this.requestopen} mouse_hover={this.mouse_hover}></Date>
                                            : <Base column={value} props={this.props.sms_list} rowIndex={rowIndex} requestopen={this.requestopen} mouse_hover={this.mouse_hover}></Base>
                            )}
                            width={this.state.columns.filter(a => a.key === value.key)[0].width}
                            isResizable={true}
                            fixed={value.frozen}

                        />
                    }, this)}
                </Table >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        request_detail: state.Adjustment.request_detail,
        sms_list: state.RequestDetail.sms_list,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_sms(state) {
            dispatch(RequestDetailSaga.get_sms(state))
        },
        get_request_detail(state) {
            dispatch(Adjustment.get_request_detail(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);