import React, { Component } from 'react';

import { connect } from 'react-redux';
import "./Css/Mail.css";
import * as Mail from './Saga/Mail';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
// import { AlertAddAlert } from 'material-ui/svg-icons';

// タイマー用
let timer;


const Todiv = styled.div`
display: inline;
margin-right:5px;
border:1px solid #dcdcdc;
font-weight:normal;
border-radius: 10px;        /* CSS3草案 */  
-webkit-border-radius: 10px;    /* Safari,Google Chrome用 */  
-moz-border-radius: 10px;   /* Firefox用 */
padding:1px 3px;
margin-bottom:3px;
margin-top:3px;
`;



const StyledWrapper = styled.div`
  display: inline-block;
  .react-autosuggest__input{
    border:none;
    padding:0px;
    width:100%;
  }
`



// 入力値に対するサジェスト項目を取得するロジック
// const getSuggestions = (value) => {
//   const inputValue = value.trim().toLowerCase();
//   const inputLength = inputValue.length;

//   return inputLength === 0 ? [] : this.props.mail_addresses.filter((lang) =>
//     lang.name.toLowerCase().slice(0, inputLength) === inputValue,
//   );
// };

// サジェスト項目が Object の場合、サジェスト選択時に Object のどの項目を表示するか決める
const getSuggestionValue = (suggestion) => suggestion.name;

// サジェスト部分のレンダリングロジック
const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.name}
  </div>
);


class MailAddressComponent extends Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      tomails: [],
      ccmails: [],
      mails: this.props.mails != null ? this.props.mails.split(',') : [],
      change: false
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.addaddress = this.addaddress.bind(this);
    // if(this.props.mails){
    //     this.props.mails.push(this.props.mails)
    //   }
  }

  onChange = (event, { newValue, method }) => {

    if (method === "enter") {
      alert()
    }

    this.setState({
      value: newValue,
    });
  };

  addaddress(event) {
    if (event.target.value !== "") {
      // var str = this.props.mails.join(',');

      let mails = [];
      if (this.props.type === "to") {
        mails = this.props.send_mail_details_address.toMailall != null ? this.props.send_mail_details_address.toMailall.split(',') : [];
      } else if (this.props.type === "cc") {
        mails = this.props.send_mail_details_address.cc != null ? this.props.send_mail_details_address.cc.split(',') : [];

      } else if (this.props.type === "bcc") {
        mails = this.props.send_mail_details_address.bcc != null ? this.props.send_mail_details_address.bcc.split(',') : [];

      }

      this.setState({
        value: "",
      });

      let mailsset = mails.filter(n => n !== event.target.value);
      mailsset = mails.concat(event.target.value);

      mailsset = mailsset.join(',');

      let send_mail_details_address = this.props.send_mail_details_address;
      // alert(state.target.value)
      if (this.props.type === "to") {
        send_mail_details_address.toMailall = mailsset;
      } else if (this.props.type === "cc") {
        send_mail_details_address.cc = mailsset;
      } else if (this.props.type === "bcc") {
        send_mail_details_address.bcc = mailsset;
      }

      this.props.update_send_mail_editor_state(send_mail_details_address)

      // this.props.handleChange(state);
      // this.props.change_send_to_addresses(this.props.type,mailsset);

    }
    this.props.change_send_mail_address_disp()
  }

  onKeyDown = (event) => {
    console.log(event)
    console.log("イベント")
    if (event.keyCode === 13) {
      this.addaddress(event)
    }

    console.log(this.state.tomails)
    //   this.setState(prevState => ({
    //     change: !prevState.change,

    // }));

  };
  handleClick(e) {
    // this.setState.mails=this.state.mails.filter(n => n !== e);

    let mails = [];
    if (this.props.type === "to") {
      mails = this.props.send_mail_details_address.toMailall != null ? this.props.send_mail_details_address.toMailall.split(',') : [];
    } else if (this.props.type === "cc") {
      mails = this.props.send_mail_details_address.cc != null ? this.props.send_mail_details_address.cc.split(',') : [];
    } else if (this.props.type === "bcc") {
      mails = this.props.send_mail_details_address.bcc != null ? this.props.send_mail_details_address.bcc.split(',') : [];
    }

    // this.setState({ mails: this.props.mails.filter(n => n !== e)})
    let mailsset = [];
    mailsset = mails.filter(n => n !== e);

    mailsset = mailsset.filter(n => n !== e).join(',');
    // this.props.send_mail_detailsa.toMailall=mailsset;
    let send_mail_details_address = this.props.send_mail_details_address;
    // alert(state.target.value)

    if (this.props.type === "to") {
      send_mail_details_address.toMailall = mailsset;
    } else if (this.props.type === "cc") {
      send_mail_details_address.cc = mailsset;
    } else if (this.props.type === "bcc") {
      send_mail_details_address.bcc = mailsset;
    }

    this.props.update_send_mail_editor_state(send_mail_details_address)

    this.props.change_send_mail_address_disp()
    // var state={};
    // state.target={};
    // state.target.name="toMailall";
    // state.target.value=mailsset;
    // this.props.handleChange(state);
  }





  onBlur = (event) => {
    console.log(event)
    console.log("イベント")
    this.addaddress(event)

  };














  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    // alert(method)
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {

    let customer = {
      UserId: this.props.user_details.id,
      Address: value,
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      console.log(timer)
      this.props.get_mail_addresses(customer)
      console.log(customer)
    }, 400);

  };

  // onSuggestionsFetchRequested = ({ value }) => {
  //   fetch(`https://swapi.co/api/people/?search=${value}`)
  //     .then(response => response.json())
  //     .then(data => this.setState({ suggestions: data.results }))
  // }



  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    // console.log('onSuggestionsClearRequested');
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value } = this.state;

    // Autosuggest will pass through all these props to the input element.
    const inputProps = {
      // placeholder: 'Type a programming language',
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onBlur: this.onBlur
    };
    // console.log(this.state)
    // console.log(this.props.send_mail_details)
    console.log(this.props.send_mail_details)
    console.log(this.props.send_mail_details_address.toMailall)
    console.log("再描画")

    // Finally, render it!
    return (
      <span>

        {this.props.mails != null ? this.props.mails.split(',').map((value, i) => <Todiv value={value} key={i}>{value}<span key={i} value={value} style={{ fontWeight: "normal", marginLeft: "5px" }} /><i className="fas fa-times" onClick={() => this.handleClick(value)}></i></Todiv>) : ""}


        <StyledWrapper>
          <Autosuggest
            suggestions={this.props.mail_addresses}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}

            onSuggestionSelected={this.onSuggestionSelected}

          />
        </StyledWrapper>
      </span>
    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {
    send_mail_details_address: state.mailReducer.send_mail_details,
    mail_detailsa: state.mailReducer.mail_details,
    mail_addresses: state.mailReducer.mail_addresses,
    user_details: state.masterReducer.user_details,
    send_to_addresses: state.mailReducer.send_to_addresses,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_mail_addresses(state) {
      dispatch(Mail.get_mail_addresses(state))
    },
    change_send_to_addresses(state) {
      dispatch(Mail.change_send_to_addresses(state))
    },
    update_send_mail_editor_state(state) {
      dispatch(Mail.update_send_mail_editor_state(state))
    },
    change_send_mail_address_disp(state) {
      dispatch(Mail.change_send_mail_address_disp(state))
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailAddressComponent);
