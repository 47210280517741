const authentication = require('../../../react-azure-adb2c2').default;





export const get_tatemono = (values: any) => {
  //a 
  // let url = `http://localhost:5000/v1/tm/tatemono/list2`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/tatemono/kanrilist`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_customers = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_detail = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/detail`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/detail`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};



export const allsend = (values: any) => {

  // let url = "http://localhost:5000/v1/sms/allsend";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/allsend`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};



export const news_personal = (values: any) => {

  // let url = "http://localhost:5000/v1/room/support/news/room";
  let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + `/v1/news/room`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};




export const get_chatlist = (values: any) => {
  console.log("kieeeeeeeeeeeeeeeeeeeeeeeee");
  console.log(values);
  // let url = `http://localhost:5000/v1/room/support/chats/chatlist/` + values.room_support_m_user_id + '/' + values.totyu + '/' + values.automatic;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/chats/chatlist/` + values.room_support_m_user_id + '/' + values.totyu + '/' + values.automatic;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const news_by_tatemono_id = (values: any) => {

  // let url = "http://localhost:5000/v1/news/targets/news_by_tatemono_id";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/targets/news_by_tatemono_id`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_template_category = (values: any) => {
  // let url = "http://localhost:5000/v1/news/template/category/list";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/template/category/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_template_bycategory_id = (values: any) => {
  // let url = "http://localhost:5000/v1/news/template/list/" + values;
  let url = process.env.REACT_APP_API_PM_URL + "/v1/news/template/list/" + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_template_byid = (values: any) => {
  // let url = "http://localhost:5000/v1/news/template/template/" + values;
  let url = process.env.REACT_APP_API_PM_URL + "/v1/news/template/template/" + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};




export const insert_template_category = (values: any) => {

  // let url = "http://localhost:5000/v1/news/template/category/insert";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/template/category/insert`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const insert_template = (values: any) => {

  // let url = "http://localhost:5000/v1/news/template/insert";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/template/insert`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const update_template_category = (values: any) => {

  // let url = "http://localhost:5000/v1/news/template/category/update";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/template/category/update`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const update_template = (values: any) => {

  // let url = "http://localhost:5000/v1/news/template/update";
  let url = process.env.REACT_APP_API_PM_URL + `/v1/news/template/update`;

  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};