import React, { Component } from 'react';
import { connect } from 'react-redux';

import CkEditor,{getEditorNamespace} from 'ckeditor4-react';
import * as Mail from './Saga/Mail';

class CkEditorComponent extends Component {

  constructor(props) {
    super(props);
 
    this.state = {data:""
      // editorState: EditorState.createEmpty(),
      // editorState: editorState,
    };
    
  }
  componentDidMount(){


  }


  onChange = (editorState) => {
  
  }


  name = (text) => {
    console.log(window.CKEDITOR.instances)
  // alert(Object.keys(window.CKEDITOR.instances)[0])
  let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
  editor.insertText("dd");
  // editor.insertText(text);
  }

  focus = () => {
 
    this.editor.focus();
    this.editor.insertText("eddd");
  };


 
  render() {
  
   
    return (
      <div>
      <CkEditor
                  
               
                    data={this.props.send_mail_details.htmlUtf8}
         
                    
                    onChange={ ( event, editor ) => {
               
                        const data = event.editor.getData();
                        let send_mail_details = this.props.send_mail_details;
                        send_mail_details.htmlUtf8=data
                     
                          this.props.change_send_mail_details(send_mail_details)


                        // this.props.handleEditorChange(data)
                        // this.setState({
                        //   data,
                        // });
                       
                       
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event ) => {
                        console.log( 'Blur.', event );
                        console.log(  event.editor );
                        const data = event.editor.getData();
                        let send_mail_details = this.props.send_mail_details;
                        send_mail_details.htmlUtf8=data
                   
                          this.props.change_send_mail_details(send_mail_details)
                        // this.props.send_mail_details.htmlUtf8=data
                        // this.onChange()
            
                        this.props.update_send_mail_editor_state(send_mail_details)
                        // this.props.handleEditorSave(data)
                        this.setState({
                          data,
                        });
                    } }

             
                    onFocus={ 
//                       editor => {
// this.setState({editor:editor})

                        
//                       }
this.props.toggleselectclose
// this.props.togglemypageclose
// this.props.toggleTemplateSelectClose
                // this.props.change_send_edit_contents 
                    
                   }
                    onSelectionChange={ editor => {
                      console.log()

                    }}
                    
             
                    config={{
                      uploadUrl: 'localhost',
height:this.props.editor_textarea_height,
enterMode:2,//エンターを押したときにpタグじゃなbrを挿入
                      language :'ja',
                      extraPlugins:'image2,uploadimage,colorbutton,colordialog',
                      allowedContent : true,
  
                      toolbar: [[ 'Bold'],[ 'Italic'],[ 'image'],[ 'Link'],[ 'Format','Font','FontSize'],[ 'FontSize'],[ 'TextColor','BGColor' ],[ 'Table'],[ 'Undo'],[ 'Redo']]
                      
                  }}
                />

      </div>
    );
  }
}





const mapStateToProps = (state) => {
  return {

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_send_mail_details(state) {
      dispatch(Mail.change_send_mail_details(state))
    },
    update_send_mail_editor_state(state) {
          dispatch(Mail.update_send_mail_editor_state(state))
      } ,


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CkEditorComponent);