import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as Sms from '../Sms/Saga/Sms';
import * as RequestDetailSaga from './Saga/RequestDetail';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import ChatTemplateGrid from '../Chat/ChatTemplateGrid';
import TemplateKanri from "../Keiyaku/TemplateKanri";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import { format } from 'date-fns'
import moment from 'moment'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Row, Col } from 'reactstrap';

const { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


var taiou_joukyou = [
    { value: "応対中", key: "応対中" },
    { value: "完了", key: "完了" },
    { value: "一旦保留", key: "一旦保留" },
    { value: "業者対応完了", key: "業者対応完了" },
]


interface Props {
    request_detail: any,
    outai_complete: any,
    chat_complet: any,
    kanryou: any,
    users_select: any,
    Outaisub: any,
    demo_date:any
}
interface State {
    Outai_Joukyou_Name: any,
    complete_date: any,
    completed_title: any,
    completed_remarks: any,
    not_published_flag: boolean,
    taiou_tantou_user_id: any,
    taiou_user: any,
    symbol: any,
    symbol_name: any,
    outai_sub_name: any,
    main_outai_sub: any,

}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            Outai_Joukyou_Name: "完了",
            complete_date: props.demo_date[0].complete_date ? moment(props.demo_date[0].complete_date).tz("utc").format('YYYY-MM-DD') : moment().tz("utc").format('YYYY-MM-DD'),
            completed_title: props.demo_date[0].completed_title ? props.demo_date[0].completed_title : props.demo_date[0].Title,
            completed_remarks: props.demo_date[0].completed_remarks ? props.demo_date[0].completed_remarks : "",
            not_published_flag: props.demo_date[0].not_published_flag == 1 ? true : false,
            taiou_tantou_user_id: this.props.demo_date[0].taiou_tantou_user_id,
            taiou_user: props.demo_date[0].taiou_user,
            symbol: props.demo_date[0].symbol,
            symbol_name: "",
            outai_sub_name: props.demo_date[0].outai_sub_name,
            main_outai_sub: props.demo_date[0].main_outai_sub,

        }

        this.save = this.save.bind(this);
        this.onChangejoukyou = this.onChangejoukyou.bind(this);
        this.complete_date = this.complete_date.bind(this);
        this.completed_title = this.completed_title.bind(this);
        this.completed_remarks = this.completed_remarks.bind(this);
        this.not_published_flag = this.not_published_flag.bind(this);
        this.taiou_tantou_user_id = this.taiou_tantou_user_id.bind(this);
        this.onChangemain_outai_sub = this.onChangemain_outai_sub.bind(this);

    }

    not_published_flag = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    taiou_tantou_user_id(event: any, values: any) {
        if (values) {
            this.setState({
                taiou_tantou_user_id: values.value,
            });
        }
    }


    onChangemain_outai_sub(event: any, values: any) {
        if (values) {
            this.setState({
                main_outai_sub: values.id,
                symbol_name: '',
                symbol: '',
                outai_sub_name: values.name
            });
        }
    }

    onChangejoukyou = (e: any, values: any) => {
        if (values) {
            this.setState({
                Outai_Joukyou_Name: values.value
            });
        } else {
            this.setState({
                Outai_Joukyou_Name: "応対中"
            });
        }
    };


    complete_date = (date: any) => {
        this.setState({
            complete_date: new Date(date),
        });
    };


    completed_remarks(e: any) {
        this.setState({
            completed_remarks: e.target.value,
        });
    }
    completed_title(e: any) {
        this.setState({
            completed_title: e.target.value,
        });
    }


    save() {

        
        alert("※デモ画面のため、保存はされません");
        return;

        // 完了の時に完了内容が入ってないとNG
        if (this.state.Outai_Joukyou_Name == '完了') {
            if (!this.state.completed_title) {
                alert("タイトルを入力してください");
                return;
            }
            if (!this.state.completed_remarks) {
                alert("完了結果を入力してください");
                return;
            }
        }

        var not_published_flag = 0;

        if (this.state.not_published_flag == true) {
            not_published_flag = 1;
        }

        // チャットがある場合はチャットとリクエストを完了状態に、チャット無しならリクエストのみ完了に
        if (this.state.Outai_Joukyou_Name == '完了' && this.props.demo_date[0].room_support_m_chat_id) {
            var result = window.confirm('チャットを完了状態にします。お客様からはチャットが送れなくなります。よろしいですか？');
            if (result) {

                this.props.outai_complete({
                    Outai_Joukyou_Name: "完了",
                    Outai_No: this.props.demo_date[0].Outai_No,
                    room_support_m_chat_id: this.props.demo_date[0].room_support_m_chat_id,
                    complete_date: this.state.complete_date,
                    completed_title: this.state.completed_title,
                    completed_remarks: this.state.completed_remarks,
                    taiou_tantou_user_id: this.state.taiou_tantou_user_id,
                    main_outai_sub: this.state.main_outai_sub,
                    not_published_flag: not_published_flag
                });

                this.props.chat_complet({
                    id: this.props.demo_date[0].room_support_m_chat_id
                })


                alert("登録しました");
                this.props.kanryou();

            }
        } else {

            this.props.outai_complete({
                Outai_Joukyou_Name: this.state.Outai_Joukyou_Name,
                Outai_No: this.props.demo_date[0].Outai_No,
                complete_date: this.state.complete_date,
                completed_title: this.state.completed_title,
                completed_remarks: this.state.completed_remarks,
                taiou_tantou_user_id: this.state.taiou_tantou_user_id,
                main_outai_sub: this.state.main_outai_sub,
                not_published_flag: not_published_flag
            });


            alert("登録しました");
            this.props.kanryou();
        }


    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">

                            <Row>
                                <div style={{ marginTop: 20 }}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={taiou_joukyou}
                                        defaultValue={{ value: "完了", key: "完了" }}
                                        getOptionLabel={(option: any) => option.value}
                                        style={{ width: 160, marginTop: 12 }}
                                        onChange={this.onChangejoukyou}
                                        renderInput={(params) => <TextField
                                            autoComplete='off' {...params} label="対応状況" />}
                                    />
                                </div>
                                {this.state.Outai_Joukyou_Name == "完了" ?
                                    <div style={{ marginTop: 20 }}>
                                        <Autocomplete
                                            defaultValue={{ value: this.state.taiou_tantou_user_id, label: this.state.taiou_user }}
                                            id="combo-box-demo"
                                            options={this.props.users_select}
                                            getOptionLabel={(option: any) => option.label}
                                            style={{ width: 160, marginTop: 12, marginLeft: 10 }}
                                            onChange={this.taiou_tantou_user_id}
                                            renderInput={(params) => <TextField
                                                autoComplete='off' {...params} label="対応担当" />}
                                        />
                                    </div> : ""}
                            </Row>


                            {this.state.Outai_Joukyou_Name == "完了" ?
                                <Row>
                                    <div>
                                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                            <KeyboardDatePicker
                                                variant="inline"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="完了日"
                                                format="yyyy/MM/dd"
                                                style={{ width: 160 }}
                                                value={this.state.complete_date}
                                                onChange={this.complete_date}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div>
                                        <Autocomplete
                                            style={{ width: 160, marginLeft: 10, marginTop: 16 }}
                                            id="combo-box-demo"
                                            options={this.props.Outaisub}
                                            value={{ symbol: this.state.symbol, name: this.state.symbol_name == '' ? this.state.outai_sub_name : this.state.symbol_name }}
                                            getOptionLabel={(option: any) =>
                                                option.symbol + ":" + option.name
                                            }
                                            onChange={this.onChangemain_outai_sub}
                                            renderInput={(params) => <TextField
                                                autoComplete='off' {...params} label="メイン区分" />}
                                        />
                                    </div>
                                </Row> : ""}


                            {this.state.Outai_Joukyou_Name == "完了" ?
                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="タイトル"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    variant="outlined"
                                    defaultValue={this.state.completed_title}
                                    onChange={this.completed_title}
                                    style={{ marginTop: "20px" }}
                                /> : ""}

                            {this.state.Outai_Joukyou_Name == "完了" ?
                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="結果"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    variant="outlined"
                                    defaultValue={this.state.completed_remarks}
                                    onChange={this.completed_remarks}
                                    style={{ marginTop: "10px" }}
                                /> : ""}



                            {this.state.Outai_Joukyou_Name == "完了" ?
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={this.not_published_flag}
                                        checked={this.state.not_published_flag}
                                        color="primary"
                                        name="not_published_flag" />}

                                    label="掲載しない"
                                /> : ""}

                            {this.state.Outai_Joukyou_Name == "完了" ?
                                <div>
                                    <div style={{ marginTop: 5 }}>
                                        ※掲載する場合、完了結果はオーナー様へ報告します。
                                    </div>
                                    <div>
                                        分かりやすく、失礼のない内容で登録してください
                                    </div></div> : ""}

                            <div style={{ marginTop: 10 }}>
                                <Button
                                    style={{
                                        marginTop: 30,
                                        color: "#fff",
                                        backgroundColor: "#5cb85c",
                                        borderColor: "#4cae4c",
                                        width: "200px"
                                    }}
                                    onClick={this.save}
                                    variant="outlined" color="primary">
                                    登録
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        request_detail: state.Adjustment.request_detail,
        users_select: state.masterReducer.users_select,
        Outaisub: state.Adjustment.Outaisub,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        outai_complete(state: any) {
            dispatch(RequestDetailSaga.outai_complete(state))
        },
        chat_complet(state: any) {
            dispatch(RequestDetailSaga.chat_complet(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

