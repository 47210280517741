import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Board_api from '../Api/Board_api';


///types
const moment = require('moment')

export interface State {
    chat_data_list: any,
    outai_sub_list: any,
    entered_list: any,
    search_status: boolean

}
const initialState: State = {
    chat_data_list: [],
    outai_sub_list: [],
    entered_list: [],
    search_status: false,

}



///action 
const actionCreator = actionCreatorFactory();


export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_true_success = actionCreator('SEARCH_STATUS_TRUE_SUCCESS');
export const search_status_false_success = actionCreator('SEARCH_STATUS_FALSE_SUCCESS');

export const get_chat_data_list = actionCreator('GET_CHAT_DATA_LIST');
export const get_chat_data_list_success = actionCreator('GET_CHAT_DATA_LIST_SUCCESS');

export const get_outai_sub_list = actionCreator('GET_OUTAI_SUB_LIST');
export const get_outai_sub_list_success = actionCreator('GET_OUTAI_SUB_LIST_SUCCESS');

export const get_entered_list_list = actionCreator('GET_ENTERED_LIST');
export const get_entered_list_success = actionCreator('GET_ENTERED_LIST_SUCCESS');




export const boardReducer = reducerWithInitialState(initialState)
    .case(search_status_true_success, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false_success, (state, payload) => ({ ...state, search_status: false }))
    .case(get_chat_data_list_success, (state, payload) => ({ ...state, chat_data_list: payload }))
    .case(get_outai_sub_list_success, (state, payload) => ({ ...state, outai_sub_list: payload }))
    .case(get_entered_list_success, (state, payload) => ({ ...state, entered_list: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const boardSaga = [
    takeEvery('GET_CHAT_DATA_LIST', handle_get_get_chat_data_list),
    takeEvery('GET_OUTAI_SUB_LIST', handle_get_outai_sub_list),
    takeEvery('GET_ENTERED_LIST', handle_get_entered_list_list),
    takeEvery('SEARCH_STATUS_TRUE', handle_search_status_true),


];

function* handle_search_status_true(action: any) {
    yield put(search_status_true_success());
}

function* handle_get_get_chat_data_list(action: any) {
    const { payload, error } = yield call(Board_api.get_chatdata, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_chat_data_list_success(payload));
        yield put(search_status_false_success());

    } else {
        // alert(error)
    }
}

function* handle_get_outai_sub_list(action: any) {
    const { payload, error } = yield call(Board_api.get_subdata, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_outai_sub_list_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_entered_list_list(action: any) {
    const { payload, error } = yield call(Board_api.get_entered, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_entered_list_success(payload));
        yield put(search_status_false_success());

    } else {
        // alert(error)
    }
}


