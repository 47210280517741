import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Sms.css';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as MessageShare from './Saga/MessageShare';
import * as Sms from '../Sms/Saga/Sms';



import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';


interface Props {
    share_open_flg: any,
    shops: any,
    share_check: any,
    sms_id: any,
    user_details: any,
    share_list: any,
    get_sms_share: any,
    messages: any,
    m_customer_id: any,
    share_disp: any
}

interface State {
    disp: boolean,
    edit: boolean,
    main: boolean
}
const GreenCheckbox = withStyles({
    root: {},
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const RedCheckbox = withStyles({
    root: {},
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            disp: false,
            edit: false,
            main: false,
        }
        this.edit = this.edit.bind(this);
        this.main = this.main.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }



    // 画面のオンオフ
    edit() {
        if (this.props.messages == "sms") {
            this.props.get_sms_share({ sms_id: this.props.sms_id });
        } else if (this.props.messages == "chat") {
            this.props.get_sms_share({ m_customer_id: this.props.m_customer_id });
        }
        this.setState({ edit: !this.state.edit })
    }

    // チェックした時
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        var main_section_flag = 0;

        if (this.state.main) {
            main_section_flag = 1;
        }


        if (this.props.messages == "sms") {
            this.props.share_check({ id: event.target.name, check: event.target.checked, sms_id: this.props.sms_id, user_id: this.props.user_details.id, main_section_flag: main_section_flag });
        } else if (this.props.messages == "chat") {
            this.props.share_check({ id: event.target.name, check: event.target.checked, m_customer_id: this.props.m_customer_id, user_id: this.props.user_details.id, main_section_flag: main_section_flag });
        }
    };


    // メイン対応部署と共有部署の切替
    main() {
        this.setState({ main: !this.state.main })
    }


    render() {
        if (this.props.share_disp) {
            return (
                <div>
                    <div className="share">
                        <div style={{ margin: 5 }}>
                            {this.state.edit ?
                                <div><div>
                                    {this.props.shops.map((e: any) => {
                                        if (e.tenpoNo !== -1
                                            && e.tenpoNo !== 16 // 法人営業
                                            && e.tenpoNo !== 21 // 社長室
                                            && e.tenpoNo !== 23 // 本部
                                            && e.tenpoNo !== 27 //  退職
                                            && e.tenpoNo !== 24 // 賃貸営業部
                                            && e.tenpoNo !== 28 // 退去清算
                                            && e.tenpoNo !== 29 //　営業推進部
                                            && e.tenpoNo !== 30 // 営業支援部
                                            && e.tenpoNo !== 33 // 法務
                                            && e.tenpoNo !== 34 // 賃貸管理部
                                            && e.tenpoNo !== 36 // マンスリー
                                            && e.tenpoNo !== 37 // 内定者
                                            && e.tenpoNo !== 38 // 休職中
                                            && e.tenpoNo !== 39　// 事務局
                                            && e.tenpoNo !== 44 // 工事部
                                            && e.tenpoNo !== 45 // 管理事業
                                            && e.tenpoNo !== 48 // 開発営業
                                            && e.tenpoNo !== 49 // 顧問
                                            && e.tenpoNo !== 50 // 協力業者
                                            && e.tenpoNo !== 51 // 巡ああ回
                                        ) {

                                            var result = 0;
                                            this.props.share_list.forEach((element: any) => {
                                                if (element.Tenpo_No == e.tenpoNo) {
                                                    result = 1;
                                                    if (element.main_section_flag == 1) {
                                                        result = 2;
                                                    }
                                                    return;
                                                }
                                            });
                                            if (result == 0) {
                                                return <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 110 }}
                                                    control={<GreenCheckbox onChange={this.handleChange} name={e.tenpoNo} />}
                                                    label={e.tenpoNameShort}
                                                />
                                            } else if (result == 2) {
                                                return <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 110, backgroundColor: "red" }}
                                                    control={<RedCheckbox defaultChecked onChange={this.handleChange} name={e.tenpoNo} />}
                                                    label={e.tenpoNameShort}
                                                />
                                            } else {
                                                return <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 110 }}
                                                    control={<GreenCheckbox defaultChecked onChange={this.handleChange} name={e.tenpoNo} />}
                                                    label={e.tenpoNameShort}
                                                />
                                            }
                                        }
                                    })}

                                    <i onClick={this.edit} style={{ margin: 5, marginTop: 30 }} className="far fa-check-circle"></i>

                                </div>
                                    <div style={{ display: "block", float: "left" }}>

                                        {this.state.main ?
                                            <Button size="small" color="primary" variant="contained" onClick={this.main}>
                                                共有部署を設定する
                            </Button>
                                            :
                                            <Button size="small" color="secondary" variant="contained" onClick={this.main}>
                                                メイン対応部署を設定する
                            </Button>}

                                    </div></div> :
                                <span>

                                    {this.props.share_list[0] ? <span>
                                        共有中：
                                      {this.props.share_list.map((e: any) => {
                                        if (e.main_section_flag == 1) {
                                            return <Button variant="outlined" style={{ color: "white", backgroundColor: "red" }}>{e.Tenpo_Name_Short}</Button>
                                        } else {
                                            return <Button variant="outlined" style={{ color: "white" }}>{e.Tenpo_Name_Short}</Button>
                                        }
                                    })}
                                    </span>
                                        : <span>共有なし</span>}
                                    <i onClick={this.edit} style={{ margin: 5 }} className="fas fa-plus-circle"></i>
                                </span>}
                        </div>
                    </div>
                </div >
            );
        } else {
            return (
                <div>
                    <div className="share">
                        <div style={{ margin: 5 }}>

                        </div>
                    </div>
                </div >
            );
        }

    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        shops: state.masterReducer.shops,
        sms_id: state.sms.sms_id,
        share_list: state.sms.share_list,
        user_details: state.masterReducer.user_details,
        m_customer_id: state.Keiyaku.m_customer_id,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        share_check(state: any) {
            dispatch(MessageShare.share_check(state))
        },
        get_sms_share(state: any) {
            dispatch(Sms.get_sms_share(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




