
  // Initialize Firebase
export default {
    apiKey: "AIzaSyBUtDEu8UWuOfJe-5FSwy7lpUKBlnfDm3E",
    authDomain: "homestation-a0c27.firebaseapp.com",
    databaseURL: "https://homestation-a0c27.firebaseio.com",
    projectId: "homestation-a0c27",
    storageBucket: "homestation-a0c27.appspot.com",
    messagingSenderId: "1026531992576"
  };

