import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as seisan_follow_api from '../Api/seisan_follow_api';
import * as t_kaiyaku_api from '../Api/t_kaiyaku_api';
// import * as t_checkout_flow_api from '../Api/t_checkout_flow_api';
import * as kanri_m_follows_api from '../Api/kanri_m_follows_api';
import * as kanri_m_follow_settings_api from '../Api/kanri_m_follow_settings_api';
import * as request_api from '../Api/request_api';


// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    Tatemono: any;
    Room: any;
    Parking: any;
    Parkingarea: any;
    Gyousya: any;
    Outaisub: any;
    files: any;
    customers: any;
    filefile: any;
    up_file: any;
    imgs: any;

    // rent_contract_monies:any;
    seisan_follow_list: any;
    seisan_follow_search_list: any;
    seisan_follow_search: any;
    tantou: any;
    kanri_m_follows: any;
    kanri_m_follows_disp: any;
    kanri_m_follows_none: any;
    kanri_m_follow_settings: any;
    setting_save: boolean;
    setting_save_popover: boolean;
    // search_id: number;
    // search_detail: any;
    // search_modal: boolean;
    // grid_update: boolean;
    // csv_loading: boolean;
    // search_list: any;
    request_list: any;
    demorequest_list: any;
    request_syubetsu: any;
    request_detail: any;
    new_outai_no: any;
    kaketsuke_kojin: any;
    search_status: boolean;
    notset_contents: any;
    inset_contents: any;
    complete_percent: any;
    request_list_csv: any;
}
const initialState: State = {
    Tatemono: [],
    Room: [],
    Parking: [],
    Parkingarea: [],
    Gyousya: [],
    Outaisub: [],
    files: [],
    customers: [],
    filefile: [],
    up_file: [],
    imgs: [],
    // rent_contract_monies: [],
    seisan_follow_list: [],
    seisan_follow_search_list: [],
    seisan_follow_search: {
        tatemono_name: "",
        tousha_hoshu: true,
        yanushi_hoshu: true,
        yanushi_tugou: true,
        sonota_hoshu: true,
        nyuukyosha_hoshu: true,
        kei_name: "", shoyuusha_tantou_no: 0,
        week1: false,
        flg: 0,
        kuushitsu_joukyou_no: 0
    },
    tantou: [],
    kanri_m_follows: [],
    kanri_m_follows_disp: [],
    kanri_m_follows_none: [],
    kanri_m_follow_settings: {},
    setting_save: false,
    setting_save_popover: false,
    // search_id: 0,
    // search_detail: {},
    // search_modal: false,
    // grid_update: false,
    // csv_loading: false,
    // search_list: { name: "", tatemono_name: "", start_date: null, end_date: null, search_completed: false }
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],
    request_list: [],
    request_syubetsu: [],
    request_detail: [],
    new_outai_no: [],
    kaketsuke_kojin: [],
    search_status: false,
    notset_contents: [],
    inset_contents: [],
    complete_percent: [],
    request_list_csv: [],
    demorequest_list: [{
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "伊藤 太郎(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111111,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "101",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件１",
        Title: "騒音",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: 12774,
        second_date: null,
        shokai_user: "佐藤",
        taiou_user: "佐藤",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "田中 花子(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111112,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "102",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件２",
        Title: "蛇口劣化",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "佐藤",
        taiou_user: "佐藤",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "田中 次郎(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111113,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "104",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件３",
        Title: "トイレ漏水",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "佐藤",
        taiou_user: "佐藤",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "井上 和則(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111114,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "202",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件４",
        Title: "コンセント不具合",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-03",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "鈴木",
        taiou_user: "鈴木",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "吉田 良子(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111115,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "304",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件５",
        Title: "宅配ボックスが開かない",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "鈴木",
        taiou_user: "鈴木",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "内田 内子(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111116,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "202",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件６",
        Title: "ゴミ分別に関して",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "佐藤",
        taiou_user: "佐藤",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "白井 花子(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111117,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "403",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件７",
        Title: "給湯器エラー",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "山本",
        taiou_user: "山本",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "木村 太郎(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111118,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "304",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件８",
        Title: "モニターフォン不具合",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "山本",
        taiou_user: "山本",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "伊藤 士郎(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111119,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "104",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件９",
        Title: "インターネットが繋がりにくい",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-16",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "山本",
        taiou_user: "山本",
        type: "電話"
    }, {
        Gyosha2_Name: null,
        Gyosha_Name: null,
        Keiyaku_Date: "2020-03-29",
        Kokyaku_Kbn: "入居者",
        Kokyaku_Name: "木村 卓也(テスト)",
        Last_Date: null,
        Outai_Joukyou_Name: "応対中",
        Outai_No: 111120,
        PA_Name: null,
        P_Name: null,
        Renraku_Saki: "00011110000",
        Room_Name: "204",
        Shoyuusha_Tantou_Name: "金田",
        Syokai_Date: "2023-02-16",
        Tatemono_Name: "テスト物件１０",
        Title: "浴室不具合",
        complete_date: "",
        completed_remarks: null,
        completed_title: null,
        created_at: "2023-02-16",
        importance: null,
        is_kaketsuke: false,
        is_kenan: false,
        is_long: false,
        is_seikyuu: false,
        lasts: "2023-02-05",
        main_outai_sub: 66,
        not_published_flag: 0,
        owner_houkoku: "",
        process_flag: 1,
        remarks: "",
        room_support_m_chat_id: null,
        second_date: null,
        shokai_user: "山本",
        taiou_user: "山本",
        type: "電話"
    }
]
}



///action 
const actionCreator = actionCreatorFactory();

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_true_success = actionCreator('SEARCH_STATUS_TRUE_SUCCESS');
export const search_status_false_success = actionCreator('SEARCH_STATUS_FALSE_SUCCESS');


export const get_notset_contents = actionCreator('GET_NOTSET_CONTENTS');
export const get_notset_contents_success = actionCreator('GET_NOTSET_CONTENTS_SUCCESS');
export const get_inset_contents = actionCreator('GET_INSET_CONTENTS');
export const get_inset_contents_success = actionCreator('GET_INSET_CONTENTS_SUCCESS');
export const change_notset_contents = actionCreator('CHANGE_NOTSET_CONTENTS');
export const change_inset_contents = actionCreator('CHANGE_INSET_CONTENTS');
export const insert_notset_contents = actionCreator('INSERT_NOTSET_CONTENTS');
export const insert_notset_contents_success = actionCreator('INSERT_NOTSET_CONTENTS_SUCCESS');

export const get_complete_percent = actionCreator('GET_COMPLETE_PERCENT');
export const get_complete_percent_success = actionCreator('GET_COMPLETE_PERCENT_SUCCESS');

export const get_kaketsuke_kojin = actionCreator('GET_KAKETSUKE_KOJIN');
export const get_kaketsuke_kojin_success = actionCreator('GET_KAKETSUKE_KOJIN_SUCCESS');

export const get_tatemono = actionCreator('GET_TATEMONO');
export const get_tatemono_success = actionCreator('GET_TATEMONO_SUCCESS');

export const get_room = actionCreator('GET_ROOM');
export const get_room_success = actionCreator('GET_ROOM_SUCCESS');

export const get_parking = actionCreator('GET_PARKING');
export const get_parking_success = actionCreator('GET_PARKING_SUCCESS');

export const get_parkingarea = actionCreator('GET_PARKINGAREA');
export const get_parkingarea_success = actionCreator('GET_PARKINGAREA_SUCCESS');

export const get_gyousya = actionCreator('GET_GYOUSYA');
export const get_gyousya_success = actionCreator('GET_GYOUSYA_SUCCESS');

export const get_outaisub = actionCreator('GET_OUTAISUB');
export const get_outaisub_success = actionCreator('GET_OUTAISUB_SUCCESS');

export const get_customers = actionCreator('GET_CUSTMERS');
export const get_customers_success = actionCreator('GET_CUSTMERS_SUCCESS');

export const new_request = actionCreator('NEW_REQUEST');
export const new_request_success = actionCreator('NEW_REQUEST_SUCCESS');


export const get_seisan_follow_list = actionCreator('GET_SEISAN_FOLLOW_LIST');
export const change_seisan_follow_list = actionCreator("CHANGE_SEISAN_FOLLOW_LIST");


export const change_seisan_follow_search_list = actionCreator<any>("CHANGE_SEISAN_FOLLOW_SEARCH_LIST");
export const change_seisan_follow_search = actionCreator<any>("CHANGE_SEISAN_FOLLOW_SEARCH");

export const set_seisan_follow_search_list = actionCreator("SET_SEISAN_FOLLOW_SEARCH_LIST");

export const update_kaiyaku_owner_renraku = actionCreator<any>("UPDATE_KAIYAKU_OWNER_RENRAKU");

export const update_completion_owner_report_at = actionCreator<any>("UPDATE_COMPLETION_OWNER_REPORT_AT");

export const change_tantou = actionCreator<any>("CHANGE_TANTOU");

export const get_kanri_m_follows = actionCreator<any>("GET_KANRI_M_FOLLOWS");
export const change_kanri_m_follows = actionCreator<any>("CHANGE_KANRI_M_FOLLOWS");
export const change_kanri_m_follows_disp = actionCreator<any>("CHANGE_KANRI_M_FOLLOWS_DISP");

export const change_kanri_m_follows_none = actionCreator<any>("CHANGE_KANRI_M_FOLLOWS_NONE");

export const change_kanri_m_follow_settings = actionCreator<any>("CHANGE_KANRI_M_FOLLOW_SETTINGS");

export const update_kanri_m_follow_settings = actionCreator<any>("UPDATE_KANRI_M_FOLLOW_SETTINGS");

export const change_kanri_m_follow_settings_save = actionCreator<any>("CHANGE_KANRI_M_FOLLOW_SETTINGS_SAVE");
export const change_kanri_m_follow_settings_save_popover = actionCreator<any>("CHANGE_KANRI_M_FOLLOW_SETTINGS_SAVE_POPOVER");


export const get_request_list = actionCreator('GET_REQUEST_LIST');
export const get_request_list_success = actionCreator('GET_REQUEST_LIST_SUCCESS');
export const get_request_list_csv_success = actionCreator('GET_REQUEST_LIST_CSV_SUCCESS');
export const get_demorequest_list = actionCreator('GET_DEMOREQUEST_LIST');


export const get_syubetsu_list = actionCreator('GET_SYUBETSU_LIST');
export const get_syubetsu_list_success = actionCreator('GET_SYUBETSU_LIST_SUCCESS');

export const get_request_detail = actionCreator('GET_REQUEST_DETAIL');
export const get_request_detail_success = actionCreator('GET_REQUEST_DETAIL_SUCCESS');

export const search_request = actionCreator('SEARCH_REQUEST');
export const search_request_success = actionCreator('SEARCH_REQUEST_SUCCESS');

export const file_upload = actionCreator('FILE_UPLOAD');
export const file_upload_success = actionCreator('FILE_UPLOAD_SUCCESS');


export const file_update = actionCreator('FILE_UPDATE');


export const file_download = actionCreator('FILE_DOWNLOAD');
export const file_download_success = actionCreator('FILE_DOWNLOAD_SUCCESS');


export const img_download = actionCreator('IMG_DOWNLOAD');
export const img_download_success = actionCreator('IMG_DOWNLOAD_SUCCESS');

export const importance = actionCreator('IMPORTANCE');



export const adjustmentReducer = reducerWithInitialState(initialState)
    .case(get_notset_contents_success, (state, payload) => ({ ...state, notset_contents: payload }))
    .case(get_inset_contents_success, (state, payload) => ({ ...state, inset_contents: payload }))


    .case(search_status_true_success, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false_success, (state, payload) => ({ ...state, search_status: false }))

    .case(get_complete_percent_success, (state, payload) => ({ ...state, complete_percent: payload }))

    .case(get_kaketsuke_kojin_success, (state, payload) => ({ ...state, kaketsuke_kojin: payload }))

    .case(get_tatemono_success, (state, payload) => ({ ...state, Tatemono: payload }))
    .case(get_room_success, (state, payload) => ({ ...state, Room: payload }))
    .case(get_parking_success, (state, payload) => ({ ...state, Parking: payload }))
    .case(get_parkingarea_success, (state, payload) => ({ ...state, Parkingarea: payload }))

    .case(get_gyousya_success, (state, payload) => ({ ...state, Gyousya: payload }))
    .case(get_outaisub_success, (state, payload) => ({ ...state, Outaisub: payload }))
    .case(file_download_success, (state, payload) => ({ ...state, filefile: payload }))
    .case(file_upload_success, (state, payload) => ({ ...state, up_file: payload }))

    .case(get_customers_success, (state, payload) => ({ ...state, customers: payload }))
    .case(img_download_success, (state, payload) => ({ ...state, imgs: payload }))
    .case(new_request_success, (state, payload) => ({ ...state, new_outai_no: payload }))


    .case(change_seisan_follow_list, (state, payload) => ({ ...state, seisan_follow_list: payload }))

    .case(change_seisan_follow_search_list, (state, payload) => ({ ...state, seisan_follow_search_list: payload }))
    .case(change_seisan_follow_search, (state, payload) => ({ ...state, seisan_follow_search: payload }))
    .case(change_tantou, (state, payload) => ({ ...state, tantou: payload }))
    .case(change_kanri_m_follows, (state, payload) => ({ ...state, kanri_m_follows: payload }))

    .case(change_kanri_m_follows_none, (state, payload) => ({ ...state, kanri_m_follows_none: payload }))
    .case(change_kanri_m_follow_settings, (state, payload) => ({ ...state, kanri_m_follow_settings: payload }))

    .case(change_kanri_m_follows_disp, (state, payload) => ({ ...state, kanri_m_follows_disp: payload }))

    .case(change_kanri_m_follow_settings_save, (state, payload) => ({ ...state, kanri_m_follow_settings_save: payload }))
    .case(change_kanri_m_follow_settings_save_popover, (state, payload) => ({ ...state, kanri_m_follow_settings_save_popover: payload }))
    .case(get_request_list_success, (state, payload) => ({ ...state, request_list: payload }))
    .case(get_request_list_csv_success, (state, payload) => ({ ...state, request_list_csv: payload }))
    .case(get_syubetsu_list_success, (state, payload) => ({ ...state, request_syubetsu: payload }))
    .case(get_request_detail_success, (state, payload) => ({ ...state, request_detail: payload }))
    .case(search_request_success, (state, payload) => ({ ...state, request_list: payload }))







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const adjustmentSaga = [
    takeEvery('GET_NOTSET_CONTENTS', handle_get_notset_contents),
    takeEvery('GET_INSET_CONTENTS', handle_get_inset_contents),
    takeEvery('CHANGE_NOTSET_CONTENTS', handle_change_notset_contents),
    takeEvery('CHANGE_INSET_CONTENTS', handle_change_inset_contents),
    takeEvery('INSERT_NOTSET_CONTENTS', handle_insert_notset_contents),

    takeEvery('SEARCH_STATUS_TRUE', handle_search_status_true),

    takeEvery('GET_COMPLETE_PERCENT', handle_get_complete_percent),


    takeEvery('GET_KAKETSUKE_KOJIN', handle_get_kaketsuke_kojin),
    takeEvery('GET_TATEMONO', handle_get_tatemono),
    takeEvery('GET_ROOM', handle_get_room),
    takeEvery('GET_PARKING', handle_get_parking),
    takeEvery('GET_PARKINGAREA', handle_get_parkingarea),

    takeEvery('GET_GYOUSYA', handle_get_gyousya),
    takeEvery('GET_OUTAISUB', handle_get_outaisub),
    takeEvery('NEW_REQUEST', handle_new_request),
    takeEvery('GET_CUSTMERS', handle_get_customers),


    // takeEvery('GET_SEISAN_FOLLOW_LIST', handle_get_seisan_follow_list),





    // takeEvery('UPDATE_COMPLETION_OWNER_REPORT_AT', handle_update_completion_owner_report_at),

    takeEvery('GET_KANRI_M_FOLLOWS', handle_get_kanri_m_follows),


    takeEvery('GET_DEMOREQUEST_LIST', handle_demo_get_request_list),

    takeEvery('GET_REQUEST_LIST', handle_get_request_list),
    takeEvery('GET_SYUBETSU_LIST', handle_get_syubetsu_list),
    takeEvery('GET_REQUEST_DETAIL', handle_get_request_detail),
    takeEvery('SEARCH_REQUEST', handle_search_request_list),
    takeEvery('FILE_UPLOAD', handle_file_upload),
    takeEvery('FILE_UPDATE', handle_file_update),

    takeEvery('FILE_DOWNLOAD', handle_file_download),
    takeEvery('IMG_DOWNLOAD', handle_img_download),
    takeEvery('IMPORTANCE', handle_importance),

];
// export default masterSaga;





// リストのカスタマイズ関連
///////////////////////////////////////////////////////////////////////////
function* handle_insert_notset_contents(action: any) {
    const { payload, error } = yield call(request_api.insert_contents, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // yield put(get_notset_contents_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_notset_contents(action: any) {
    const { payload, error } = yield call(request_api.notset_contents, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_notset_contents_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_inset_contents(action: any) {
    const { payload, error } = yield call(request_api.inset_contents, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        var resule = payload.map(function (item: any) {

            item.resizable = true;
            return item;
        });
        console.log("pomhiapromairpaogi");
        console.log(resule);
        yield put(get_inset_contents_success(resule));
    } else {
        // alert(error)
    }
}



// 未表示の一覧を更新
function* handle_change_notset_contents(action: any) {
    if (action.payload.delete == 1) {
        var result = action.payload.data.filter(function (item: any) {
            return item.id !== action.payload.id;
        });
    } else {
        var result = action.payload;
    }

    result.sort(function (a: any, b: any) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
    });

    yield put(get_notset_contents_success(result));
}

// 表示側の一覧を更新
function* handle_change_inset_contents(action: any) {
    if (action.payload.delete == 1) {
        var result = action.payload.data.filter(function (item: any) {
            return item.id !== action.payload.id;
        });
        yield put(get_inset_contents_success(result));
    } else {
        yield put(get_inset_contents_success(action.payload));
    }
}

///////////////////////////////////////////////////////////////////////////


function* handle_get_complete_percent(action: any) {
    const { payload, error } = yield call(request_api.complete_percent, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_complete_percent_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_importance(action: any) {
    const { payload, error } = yield call(request_api.importance, action.payload);


    if (payload && !error) {
        yield put(get_request_detail_success(payload));
    } else {
    }

}
function* handle_search_status_true(action: any) {
    yield put(search_status_true_success());
}

function* handle_get_kaketsuke_kojin(action: any) {
    const { payload, error } = yield call(request_api.get_kaketsuke_kojin, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_kaketsuke_kojin_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_customers(action: any) {
    const { payload, error } = yield call(request_api.get_customers, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_customers_success(payload));
    } else {
        // alert(error)
    }
}




function* handle_img_download(action: any) {

    const { payload, error } = yield call(request_api.download_img, action.payload.filename);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        console.log(payload);
        console.log(action.payload);
        // ダウンロードされるファイル名
        payload.download = action.payload.file_name;
        payload.href = URL.createObjectURL(payload);
        yield put(img_download_success(payload));

    } else {
        // alert(error)
    }
}

function* handle_file_download(action: any) {

    const { payload, error } = yield call(request_api.download_file, action.payload.id);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        console.log(payload);
        console.log(action.payload);
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.file_name;
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        // yield put(file_download_success(payload));

    } else {
        // alert(error)
    }
}

function* handle_file_upload(action: any) {

    const { payload, error } = yield call(request_api.upload_file, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(file_upload_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_file_update(action: any) {

    const { payload, error } = yield call(request_api.update_file, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

    } else {
        // alert(error)
    }
}

function* handle_new_request(action: any) {
    const { payload, error } = yield call(request_api.new_request, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(new_request_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_tatemono(action: any) {
    const { payload, error } = yield call(request_api.get_tatemono, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_tatemono_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_room(action: any) {
    const { payload, error } = yield call(request_api.get_room, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_room_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_parking(action: any) {
    const { payload, error } = yield call(request_api.get_parking, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_parking_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_parkingarea(action: any) {
    const { payload, error } = yield call(request_api.get_parkingarea, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_parkingarea_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_gyousya(action: any) {
    const { payload, error } = yield call(request_api.get_gyosha, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_gyousya_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_outaisub(action: any) {
    const { payload, error } = yield call(request_api.get_outaisub, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_outaisub_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_request_list(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_api.request_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        var csvs = payload;
        var i = 0;
        csvs.forEach((e: any) => {
            csvs[i].created_at = csvs[i].created_at ? moment(csvs[i].created_at).utc().format('YYYY-MM-DD') : "";
            csvs[i].lasts = csvs[i].lasts ? moment(csvs[i].lasts).utc().format('YYYY-MM-DD') : "";
            csvs[i].Syokai_Date = csvs[i].Syokai_Date ? moment(csvs[i].Syokai_Date).utc().format('YYYY-MM-DD') : "";
            csvs[i].owner_houkoku = csvs[i].owner_houkoku ? moment(csvs[i].owner_houkoku).utc().format('YYYY-MM-DD') : "";
            csvs[i].complete_date = csvs[i].complete_date ? moment(csvs[i].complete_date).utc().format('YYYY-MM-DD') : "";
            csvs[i].Keiyaku_Date = csvs[i].Keiyaku_Date ? moment(csvs[i].Keiyaku_Date).utc().format('YYYY-MM-DD') : "";
            i++;
        });
        yield put(get_request_list_success(payload));
        yield put(get_request_list_csv_success(csvs));

    } else {
        // alert(error)
    }
}

function* handle_search_request_list(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_api.request_search, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        var csvs = payload;
        var i = 0;
        csvs.forEach((e: any) => {
            csvs[i].created_at = csvs[i].created_at ? moment(csvs[i].created_at).utc().format('YYYY-MM-DD') : "";
            csvs[i].lasts = csvs[i].lasts ? moment(csvs[i].lasts).utc().format('YYYY-MM-DD') : "";
            csvs[i].Syokai_Date = csvs[i].Syokai_Date ? moment(csvs[i].Syokai_Date).utc().format('YYYY-MM-DD') : "";
            csvs[i].owner_houkoku = csvs[i].owner_houkoku ? moment(csvs[i].owner_houkoku).utc().format('YYYY-MM-DD') : "";
            csvs[i].complete_date = csvs[i].complete_date ? moment(csvs[i].complete_date).utc().format('YYYY-MM-DD') : "";
            csvs[i].Keiyaku_Date = csvs[i].Keiyaku_Date ? moment(csvs[i].Keiyaku_Date).utc().format('YYYY-MM-DD') : "";
            i++;
        });
        yield put(search_request_success(payload));
        yield put(get_request_list_csv_success(csvs));
        yield put(search_status_false_success());

    } else {
        // alert(error)
    }
}

function* handle_get_request_detail(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_api.request_detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(get_request_detail_success(payload));


    } else {
        // alert(error)

    }

}


function* handle_get_syubetsu_list(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_api.request_syubetsu, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(get_syubetsu_list_success(payload));


    } else {
        // alert(error)

    }

}

function* handle_get_kanri_m_follows(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(kanri_m_follows_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        let frozen = true

        let follows = payload.kanri_m_follows.map(function (value: any) {


            value.frozen = frozen
            if (payload.kanri_m_follow_settings.frozen_key_name === value.key_name) {
                frozen = false
            }

            return value
        })

        yield put(change_kanri_m_follow_settings(payload.kanri_m_follow_settings))


        yield put(change_kanri_m_follows(follows))

        yield put(change_kanri_m_follows_disp(follows.filter((a: any) => a.disp === true)))

        yield put(change_kanri_m_follows_none(follows.filter((a: any) => a.disp === false)))



    } else {
        // alert(error)

    }
}



function* handle_demo_get_request_list(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(request_api.request_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      
        yield put(get_request_list_success(payload));
    } else {
        // alert(error)
    }
}

