import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as MessageShareApi from '../Api/MessageShare';


// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    share: any
}
const initialState: State = {
    share: []
}



///action 
const actionCreator = actionCreatorFactory();

export const share_check = actionCreator('SHARE_CHECK');
export const share_check_success = actionCreator('SHARE_CHECK_SUCCESS');

export const MessageShareReducer = reducerWithInitialState(initialState)
    .case(share_check_success, (state, payload) => ({ ...state, share: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const MessageShareSaga = [
    takeEvery('SHARE_CHECK', handle_share_check),

];
// export default masterSaga;


function* handle_share_check(action: any) {

    const { payload, error } = yield call(MessageShareApi.share_check, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(share_check_success(payload));
    } else {
        alert(error)
    }
}

