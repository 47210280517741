const authentication = require('../../../react-azure-adb2c2').default;





export const list = (values: any) => {

    // let url = `https://localhost:44341/api/MailMAddressLists`;
    let url = process.env.REACT_APP_API_BASE_URL + `/api/MailMAddresses`;

    console.log(values)


    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};














// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/MailAllReceives/` + Id;

//             const token = authentication.getAccessToken();
//             // alert(token)
//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



