const authentication = require('../../../react-azure-adb2c2').default;



export const list = (values: any) => {

    const token = authentication.getAccessToken();
    // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

    let url = `https://192.168.0.202/fuelphp/public/leasecontract/rest/list/monthreact.json`;
    // alert(url)
    // let url = `http://localhost:5000/api/v1/owner/remittance/check/logs`;
    // alert(JSON.stringify(values))
    // values = { ky: values, term: "2021/02" }

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

// export const parking = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/parking`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/parking`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }
// export const net = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/net`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/net`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }
// export const energization = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/energization`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/energization`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }







// export const electronics = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/electronics`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/electronics`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }


// export const wifi = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/wifi`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/wifi`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }


// export const owner_tel = (values: any) => {

//     const token = authentication.getAccessToken();
//     // const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/ownertel`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/ownertel`;
//     // alert(url)
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }


// export const detail = (Id: number) => {

//     const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + Id;

//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         headers: { 'Authorization': 'Bearer ' + token }
//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }

// export const check_completed = (values: any) => {
//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/completed`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/completed`;
//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }

// export const check_completed_cancel = (values: any) => {
//     console.log(values)
//     let url = process.env.REACT_APP_API_BASE_URL + `/api/v1/rent/contracts/check/completedcancel`;
//     // let url = `http://localhost:5000/api/v1/rent/contracts/check/completedcancel`;
//     const token = authentication.getAccessToken();
//     // alert(JSON.stringify(values))
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }