import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import * as RequestDetailSaga from './Saga/RequestDetail';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from './Index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import EditOutai from "./EditOutai";
import Button from '@material-ui/core/Button';


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});



const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      select_row: 0
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'OutaiDate', name: '応対日', width: 80, resizable: true },
    { key: 'OutaiDate_H', name: '時', width: 30, resizable: true },
    { key: 'OutaiDate_M', name: '分', width: 30, resizable: true },
    { key: 'ShosaiNaiyo', name: '内容', width: 450, resizable: true },
    { key: 'syosai_taiou_user', name: '応対者', width: 100, resizable: true },
    { key: 'OutaiHoho_Name', name: '応対方法', width: 75, resizable: true },
    { key: 'OutaiHoho_Biko', name: '応対方法備考', width: 200, resizable: true },
    { key: 'Biko', name: '備考', width: 150, resizable: true },
    { key: 'file_name', name: '添付', width: 60, resizable: true },
  ]

const Base = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
  (() => {
    if (props[rowIndex][column.key] !== "") {
      return <div onMouseEnter={() => mouse_hover(rowIndex)} style={{ cursor: "pointer" }} onClick={() => requestopen(rowIndex)}>
        <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell></Tooltip>
      </div>
    } else {
      return <div style={{ cursor: "pointer" }} onClick={() => requestopen(rowIndex)}> 　 </div>
    }
  })()

const Date = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
  (() => {
    if (props[rowIndex][column.key]) {
      return <div onMouseEnter={() => mouse_hover(rowIndex)} style={{ cursor: "pointer" }} onClick={() => requestopen(rowIndex)}>
        <Cell style={{ whiteSpace: "nowrap" }} >
          {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
        </Cell>
      </div>
    } else {
      return <div style={{ cursor: "pointer" }} onClick={() => requestopen(rowIndex)}>  　</div>
    }
  })()
const Files = ({ column, props, rowIndex, requestopen, mouse_hover }) =>
  <div onMouseEnter={() => mouse_hover(rowIndex)} style={{ cursor: "pointer" }} onClick={() => requestopen(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ?
        <i className="fas fa-file"></i> : ""}
    </Cell>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      reqtoggle: false,
      edittoggle: false,
      colSortDirs: {},
    };


    this.mouse_hover = this.mouse_hover.bind(this);

    this.edittoggle = this.edittoggle.bind(this);

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    // this.reqtoggle = this.reqtoggle.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

  }




  mouse_hover(Outai_No) {
    this.setState({ select_row: Outai_No })
  }


  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.request_detail.detail;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  requestopen = (rowIndex) => {
    var data = {
      Outai_No: this.props.request_detail.detail[rowIndex].Outai_No,
      OutaiShosai_No: this.props.request_detail.detail[rowIndex].OutaiShosai_No,
    }
    this.props.get_request_file(data);

    this.setState({
      index: rowIndex,
      // select_row: rowIndex
    })
    // this.reqtoggle();

    // if (this.state.edittoggle == true) {
    //   this.props.reqtoggle();
    // }
    this.setState({ edittoggle: !this.state.edittoggle })

  }
  // reqtoggle() {
  //   if (this.state.reqtoggle == true) {
  //     this.props.get_request_file({ Outai_No: 0 });
  //     this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[this.state.index].Outai_No });
  //   }
  //   this.setState({ reqtoggle: !this.state.reqtoggle })
  // }
  edittoggle() {

    if (this.state.edittoggle == true) {
      setTimeout(() => {
        this.props.get_request_file({ Outai_No: 0 });
        this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[this.state.index].Outai_No });
      }, 1000)
    }

    this.setState({ edittoggle: !this.state.edittoggle })
  }

  _rowClassNameGetter(index) {
    if (this.props.request_detail.detail[index]) {
      if (this.props.request_detail.detail[index]['ShosaiNaiyo'] == "初回対応登録なし") {
        return 'none-row';
      } else if (this.state.select_row == index) {
        return 'pushed-row';
      } else if (this.props.request_detail.detail[index]['houmon_irai'] == 1) {
        return 'irai-row';
      } else if (this.props.request_detail.detail[index]['schedule_completed'] == 1) {
        return 'yotei-row';
      }
    }
  }


  render() {

    return (
      <div id="seisanlist">



        <Table
          rowHeight={30}
          rowsCount={this.props.request_detail.detail.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 150}
          height={255 + this.props.ipad_height}
          headerHeight={30}
          rowClassNameGetter={this._rowClassNameGetter}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (
                value.key === "file_name" ? <Files column={value} props={this.props.request_detail.detail} rowIndex={rowIndex} requestopen={this.requestopen} mouse_hover={this.mouse_hover}></Files>
                  : value.key === "OutaiDate" ? <Date column={value} props={this.props.request_detail.detail} rowIndex={rowIndex} requestopen={this.requestopen} mouse_hover={this.mouse_hover}></Date>
                    : <Base column={value} props={this.props.request_detail.detail} rowIndex={rowIndex} requestopen={this.requestopen} mouse_hover={this.mouse_hover}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >


        {/* < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="xl" fade={false}>
          <ModalHeader toggle={this.reqtoggle}>対応履歴</ModalHeader>
          <SyousaiModal
            reqtoggle={this.reqtoggle}
            index={this.state.index} />
          <ModalFooter>
          </ModalFooter>
        </Modal > */}

        < Modal isOpen={this.state.edittoggle} toggle={this.edittoggle} size="kokyaku" fade={false} backdrop={false}>
          {/* <ModalHeader toggle={this.edittoggle}>応対編集</ModalHeader> */}
          <DialogTitle id="customized-dialog-title" onClose={this.edittoggle} >応対編集</DialogTitle>

          <EditOutai
            index={this.state.index}
            OutaiShosai_No={this.props.request_detail.detail.length}
            Outai_No={this.props.request_detail.detail[0].Outai_No}
            reqtoggle={this.edittoggle} />
          <ModalFooter>
          </ModalFooter>
        </Modal >


      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    request_detail: state.Adjustment.request_detail,
    request_file: state.RequestDetail.request_file,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_request_file(state) {
      dispatch(RequestDetailSaga.get_request_file(state))
    },
    get_request_detail(state) {
      dispatch(Adjustment.get_request_detail(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);