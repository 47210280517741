import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import * as firebase from "firebase";
import '@firebase/firestore' // 👈 If you're using firestore

// import ReduxSagaFirebase from 'redux-saga-firebase'
import { eventChannel } from 'redux-saga'
import { call, put, takeEvery, take, select, delay, all } from 'redux-saga/effects';
import * as LinesApi from '../Api/LinesApi';
import * as TimeLineApi from '../Api/TimeLineApi';
import * as TimeLineResponsesApi from '../Api/TimeLineResponsesApi';

import * as LineSendsApi from '../Api/LineSendsApi';
import * as LineUserResponsesApi from '../Api/LineUserResponsesApi';
import { reset } from 'redux-form';

import * as SmsApi from '../Api/SmsApi';
import * as MessageShareApi from '../../MessageShare/Api/MessageShare';


// import firebaseConfig from '../../../firebase-config';
// // 初期化
// const firebaseApp = firebase.initializeApp(firebaseConfig)

// const rsf = new ReduxSagaFirebase(firebaseApp)

// import console = require('console');
// import * as Common from './Common';

export interface SmsState {

    mobilenumber: any;
    m_customer_id: any;
    sms_id: any;

    smses: any;


    sms_bottom: boolean;
    sms_send: boolean;
    get_sms_open: any;
    sms_open_flg: boolean;
    share_open_flg: boolean;
    share_list: any;

    smsimage: any;
    sms_images: any;
    sms_file_data: any;
    sms_send_modal: number;
    sms_send_file_loading: boolean;
    sms_send_list_loading: boolean;
    sms_status: any;
}

const initialState: SmsState = {

    mobilenumber: [],
    m_customer_id: [],
    sms_id: [],

    smses: [],

    sms_bottom: false,

    sms_send: false,
    get_sms_open: [],
    sms_open_flg: false,
    share_open_flg: false,
    share_list: [],

    smsimage: [],
    sms_images: {},
    sms_file_data: null,
    sms_send_modal: 0,
    sms_send_file_loading: false,
    sms_send_list_loading: false,
    sms_status: 0
}



///action
const actionCreator = actionCreatorFactory();



export const get_smses = actionCreator<any>('GET_SMSES');
export const change_smses = actionCreator<any>("CHANGE_SMSES");


export const send_sms_message = actionCreator<any>('SEND_SMS_MESSAGE');
export const send_sms_message_success = actionCreator<any>('SEND_SMS_MESSAGE_SUCCESS');


export const change_sms_bottom = actionCreator<any>('CHANGE_SMS_BOTTOM');

export const change_sms_send = actionCreator<any>('CHANGE_SMS_SEND');


export const get_sms_open = actionCreator<any>('GET_SMS_OPEN');
export const get_sms_close = actionCreator<any>('GET_SMS_CLOSE');


export const change_sms_open_flg = actionCreator<any>('CHANGE_SMS_OPEN_FLG');

export const get_shares = actionCreator<any>('GET_SHARES');
export const change_share_open_flg = actionCreator<any>('CHANGE_SHARE_OPEN_FLG');


export const get_sms_images = actionCreator<any>('GET_SMS_IMAGES');
export const change_sms_images = actionCreator<any>('CHANGE_SMS_IMAGES');

export const change_sms_file_data = actionCreator<any>('CHANGE_SMS_FILE_DATA');


export const upload_sms_file = actionCreator<any>('UPLOAD_SMS_FILE');

export const download_sms_file = actionCreator<any>('DOWNLOAD_SMS_FILE');

export const change_sms_send_modal = actionCreator<number>('CHANGE_SMS_SEND_MODAL');

export const change_sms_send_file_loading = actionCreator<boolean>('CHANGE_SMS_SEND_FILE_LOADING');
export const change_sms_send_list_loading = actionCreator<boolean>('CHANGE_SMS_SEND_LIST_LOADING');

export const change_mobile_number = actionCreator<any>('CHANGE_MOBILE_NUMBER');
export const change_m_customer_id = actionCreator<any>('CHANGE_M_CUSTOMER_ID');
export const change_sms_id = actionCreator<any>('CHANGE_SMS_ID');

export const get_sms_share = actionCreator<any>('GET_SMS_SHARE');
export const get_sms_share_success = actionCreator<any>('GET_SMS_SHARE_SUCCESS');



export const smsReducer = reducerWithInitialState(initialState)

    .case(send_sms_message_success, (state, payload) => ({ ...state, sms_status: payload }))

    .case(change_smses, (state, payload) => ({ ...state, smses: payload }))
    .case(change_sms_bottom, (state, payload) => ({ ...state, sms_bottom: payload }))
    .case(change_sms_send, (state, payload) => ({ ...state, sms_send: payload }))
    .case(change_sms_open_flg, (state, payload) => ({ ...state, sms_open_flg: payload }))
    .case(change_share_open_flg, (state, payload) => ({ ...state, share_open_flg: payload }))


    .case(change_sms_images, (state, payload) => ({ ...state, sms_images: payload }))

    .case(change_sms_file_data, (state, payload) => ({ ...state, sms_file_data: payload }))
    .case(change_sms_send_modal, (state, payload) => ({ ...state, sms_send_modal: payload }))
    .case(change_sms_send_file_loading, (state, payload) => ({ ...state, sms_send_file_loading: payload }))

    .case(change_mobile_number, (state, payload) => ({ ...state, mobilenumber: payload }))
    .case(change_m_customer_id, (state, payload) => ({ ...state, m_customer_id: payload }))
    .case(change_sms_id, (state, payload) => ({ ...state, sms_id: payload }))

    .case(get_sms_share_success, (state, payload) => ({ ...state, share_list: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const smsSaga = [takeEvery('GET_SMSES', handle_get_smses),
takeEvery('GET_SHARES', handle_get_shares),

takeEvery('SEND_SMS_MESSAGE', handle_send_sms_message),

takeEvery('GET_SMS_OPEN', handle_get_sms_open),
takeEvery('GET_SMS_CLOSE', handle_get_sms_close),

takeEvery('GET_SMS_IMAGES', handle_get_sms_images),

takeEvery('UPLOAD_SMS_FILE', handle_upload_sms_file),

takeEvery('DOWNLOAD_SMS_FILE', handle_download_sms_file),

takeEvery('GET_SMS_SHARE', handle_get_sms_share),




];

function* handle_get_sms_share(action: any) {
    const { payload, error } = yield call(MessageShareApi.share_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_sms_share_success(payload));
    } else {
        // alert(error)
    }

}

function* handle_get_shares(action: any) {
    yield put(change_share_open_flg(action.payload))

}

function* handle_get_smses(action: any) {

    yield put(change_mobile_number(action.payload.mobilenumber));
    yield put(change_m_customer_id(action.payload.m_customer_id));
    yield put(change_sms_id(action.payload.sms_id));

    yield put(change_sms_bottom(true));
    yield put(change_sms_open_flg(true))
    const { payload, error } = yield call(TimeLineApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        yield put(change_sms_bottom(true));
        // 成功時アクション呼び出し
        yield put(change_smses(payload))
        yield put(change_sms_open_flg(true))



    } else {


    }
}







function* handle_get_sms_open(action: any) {


    const { payload, error } = yield call(TimeLineApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert()
        // 成功時アクション呼び出し
        yield put(change_smses(payload))
        yield put(change_sms_bottom(true));
        // yield put(change_get_mail_details_loading(0));
        yield put(change_sms_open_flg(true))

    } else {


    }
}



function* handle_get_sms_close(action: any) {

    yield put(change_sms_open_flg(false))
}



function* handle_send_sms_message(action: any) {

    if (action.payload.message_text) {
        yield put(change_sms_send(true));
    }
    const { payload, error } = yield call(SmsApi.send, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(send_sms_message_success(payload));



    } else {


    }
}




function* handle_get_sms_images(action: any) {



    const { payload, error } = yield call(LinesApi.get_images, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        let bloburl = URL.createObjectURL(payload)
        // console.log(bloburl)
        // 成功時アクション呼び出し
        yield put(change_sms_images({
            id: action.payload,
            bloburl: bloburl
        }))



    } else {


    }
}


function* handle_upload_sms_file(action: any) {

    const state = yield select();
    yield put(change_sms_send_file_loading(true));
    const { payload, error } = yield call(LineSendsApi.upload, { user_id: state.sms.sms_user_details.id, name: state.sms.sms_file_data.name, file: state.sms.sms_file_data.file, mime_type: state.sms.sms_file_data.mime_type });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(change_sms_file_data(null));
        yield put(change_sms_send_file_loading(false));
        yield put(change_sms_send_modal(0));


    } else {
        alert(error)

    }
}



function* handle_download_sms_file(action: any) {

    const state = yield select();

    const { payload, error } = yield call(LinesApi.download_file, action.payload.file_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.message_text;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}