import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import * as RequestDetailSaga from './Saga/RequestDetail';
import Dropzone from 'react-dropzone';
import FileGrid from "./FileGrid";
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import { format } from 'date-fns'
import moment from 'moment'
import styled from 'styled-components';

const authentication = require('../../react-azure-adb2c2').default;

const { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
`;

interface Props {
    users_select: any,
    reqtoggle: any,
    Outai_No: any,
    OutaiShosai_No: any,
    syousai_update: any,
    file_update: any,
    user_details: any,
    request_detail: any,
    index: any,
    request_file: any,
    get_app_files: any,
    demo_date:any
}
interface State {
    OutaiDate: any,
    OutaiDate_H: any,
    OutaiDate_M: any,
    OutaiNaiyo: any,
    OutaiHoho_Name: any,
    OutaiHoho_Biko: any,
    OutaiTanto_Id: any,
    OutaiBiko: any,
    filename: any,
    files: any,
    OutaiTanto: any,
    OutaiSchedule: boolean,
    houmon1: boolean,
    irai: any,
    button_disp: any,
    app_file_id: any,
    app_file_name: any,
    bloburl: any


}



var outai_houhou = [
    { value: "電話" },
    { value: "FAX" },
    { value: "訪問" },
    { value: "来店" },
    { value: "通知文" },
    { value: "メール" },
    { value: "Teams" },
    { value: "傷汚れチェックシート受付" },
    { value: "その他" },
    { value: "駆け付け" },
    { value: "チャット" },
    { value: "LINE" },
    { value: "アプリ" },
]


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            OutaiDate: this.props.demo_date[this.props.index].OutaiDate,
            OutaiDate_H: this.props.demo_date[this.props.index].OutaiDate_H,
            OutaiDate_M: this.props.demo_date[this.props.index].OutaiDate_M,
            OutaiNaiyo: this.props.demo_date[this.props.index].ShosaiNaiyo,
            OutaiHoho_Name: this.props.demo_date[this.props.index].OutaiHoho_Name,
            OutaiHoho_Biko: this.props.demo_date[this.props.index].OutaiHoho_Biko,
            OutaiTanto_Id: this.props.demo_date[this.props.index].OutaiTanto_Id,
            OutaiBiko: this.props.demo_date[this.props.index].Biko,
            OutaiSchedule: this.props.demo_date[this.props.index].schedule_completed == 1 ? true : false,
            filename: "",
            files: [],
            OutaiTanto: this.props.demo_date[this.props.index].syosai_taiou_user,
            houmon1: this.props.demo_date[this.props.index].houmon_irai == 1 ? true : false,
            irai: this.props.demo_date[this.props.index].houmon_datetime,
            button_disp: true,
            app_file_id: this.props.demo_date[this.props.index].app_file_id,
            app_file_name: this.props.demo_date[this.props.index].app_file_name,
            bloburl: "",

        }
        this.onChangeOutaiTanto_Id = this.onChangeOutaiTanto_Id.bind(this);
        this.onChangeOutaiHoho_Name = this.onChangeOutaiHoho_Name.bind(this);
        this.onChangeOutaiHoho_Biko = this.onChangeOutaiHoho_Biko.bind(this);
        this.onChangeOutaiNaiyo = this.onChangeOutaiNaiyo.bind(this);
        this.onChangeOutaiBiko = this.onChangeOutaiBiko.bind(this);
        this.save = this.save.bind(this);
        this.onChangeOutaiDate = this.onChangeOutaiDate.bind(this);
        this.onChangeOutaiDate_H = this.onChangeOutaiDate_H.bind(this);
        this.onChangeOutaiDate_M = this.onChangeOutaiDate_M.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.yoteiCheck = this.yoteiCheck.bind(this);
        this.yoteiCheck = this.yoteiCheck.bind(this);
        this.iraiChange = this.iraiChange.bind(this);
        this.ima = this.ima.bind(this);
        this.get_app_files = this.get_app_files.bind(this);

    }

    ima() {
        this.setState({
            OutaiDate: new Date(),
            OutaiDate_H: moment().tz("Asia/Tokyo").format('HH'),
            OutaiDate_M: moment().tz("Asia/Tokyo").format('mm'),
        });
    }

    onChangeOutaiDate = (date: any) => {
        this.setState({
            OutaiDate: new Date(date),
        });
    };

    onChangeOutaiDate_H(e: any) {

        var time = e.target.value;
        if (e.target.value < 0) {
            time = 24;
        } else if (e.target.value > 23) {
            time = 0;
        }

        this.setState({
            OutaiDate_H: time,
        });
    }

    onChangeOutaiDate_M(e: any) {

        var time = e.target.value;
        if (e.target.value < 0) {
            time = 59;
        } else if (e.target.value > 59) {
            time = 0;
        }
        this.setState({
            OutaiDate_M: time,
        });
    }


    onChangeOutaiTanto_Id(event: any, values: any) {
        if (values) {
            this.setState({
                OutaiTanto_Id: values.value,
            });
        }
    }

    onChangeOutaiHoho_Name(event: any, values: any) {
        this.setState({
            OutaiHoho_Name: values.value,
        });
    }

    onChangeOutaiHoho_Biko(e: any) {
        this.setState({
            OutaiHoho_Biko: e.target.value,
        });
    }

    onChangeOutaiNaiyo(e: any) {
        this.setState({
            OutaiNaiyo: e.target.value,
        });
    }

    onChangeOutaiBiko(e: any) {
        this.setState({
            OutaiBiko: e.target.value,
        });
    }

    save() {
        alert("※デモ画面のため、登録はされません");
        return;

        var schedule_completed = 0;

        if (this.state.OutaiSchedule == true) {
            schedule_completed = 1;
        }

        var houmon_irai = 0;

        if (this.state.houmon1 == true) {
            houmon_irai = 1;
        }

        var data =
        {
            Outai_No: this.props.Outai_No,
            OutaiDate: this.state.OutaiDate,
            OutaiDate_H: this.state.OutaiDate_H,
            OutaiDate_M: this.state.OutaiDate_M,
            Naiyo: this.state.OutaiNaiyo,
            OutaiHoho_Name: this.state.OutaiHoho_Name,
            OutaiHoho_Biko: this.state.OutaiHoho_Biko,
            Tanto_Id: this.state.OutaiTanto_Id,
            Biko: this.state.OutaiBiko,
            OutaiShosai_No: this.props.demo_date[this.props.index].OutaiShosai_No,
            schedule_completed: schedule_completed,
            houmon_irai: houmon_irai,
            houmon_datetime: this.state.irai,
        }

        this.props.syousai_update(data);
        this.setState({
            button_disp: false,
        });
        setTimeout(() => {
            var file_no = 1;

            this.props.request_file.forEach((e: any) => {

                if (file_no <= e.file_no) {
                    file_no = e.file_no + 1;
                }

            });

            this.state.files.forEach((e: any) => {
                setTimeout(() => {
                    console.log(e);

                    var updata = {
                        files: e,
                        file_no: file_no,
                        Outai_No: this.props.Outai_No,
                        OutaiShosai_No: this.props.demo_date[this.props.index].OutaiShosai_No,
                        uploaded_user_id: this.props.user_details.id,
                    }

                    // 添付ファイルをアップロード
                    this.props.file_update(updata);
                    file_no = file_no + 1;
                }, 1000)
            });
            this.setState({
                button_disp: true,
            });
            alert("変更しました");
            this.props.reqtoggle();
        }, 1100 * this.state.files.length)
    }

    onDrop = (files: any) => {
        files.forEach((e: any) => {
            var statefiles = this.state.files;
            statefiles.push(e);

            var filenames = "";
            if (this.state.filename !== "") {
                filenames = this.state.filename + " , " + e.name;
            } else {
                filenames = e.name;
            }

            this.setState({
                filename: filenames,
                files: statefiles
            });
        });
    };




    yoteiCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name);
        console.log(event.target.checked);
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };
    houmonCheck1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    iraiChange = (date: any) => {
        this.setState({
            irai: new Date(date),
        });
    };



    get_app_files() {
        const token = authentication.getAccessToken();
        const url = `https://roomsupportapi.azurewebsites.net/v1/hs/files/img/` + this.state.app_file_id;

        console.log(url);
        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {
            const a = document.createElement('a');
            a.download = this.state.app_file_name;
            a.href = URL.createObjectURL(images);
            a.click();
        });
    }



    componentWillMount() {
        if (this.state.app_file_id) {
            const token = authentication.getAccessToken();
            const url = `https://roomsupportapi.azurewebsites.net/v1/hs/files/img/` + this.state.app_file_id;

            console.log(url);
            var bloburl
            fetch(url, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => response.blob()).then(images => {
                bloburl = URL.createObjectURL(images)
                console.log(bloburl)
                this.setState({
                    bloburl: bloburl
                })
            });
        }
    }

    render() {

        return (
            this.props.demo_date[this.props.index].OutaiShosai_No ?
                <div className="newoutai" style={{ padding: 10, background: "#b0c4de" }} >
                    <div className="item-outer col-lg-12">
                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left" }} className="col-lg-12">

                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    defaultValue={{ value: this.state.OutaiTanto_Id, label: this.state.OutaiTanto }}
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeOutaiTanto_Id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="応対者" />}
                                                />
                                            </td>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    defaultValue={{ value: this.state.OutaiHoho_Name }}
                                                    options={outai_houhou}
                                                    getOptionLabel={(option: any) => option.value}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeOutaiHoho_Name}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="応対方法" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.OutaiHoho_Biko}
                                                    id="outlined-read-only-input"
                                                    label="応対方法備考"
                                                    fullWidth
                                                    multiline
                                                    rows={1}
                                                    variant="outlined"
                                                    onChange={this.onChangeOutaiHoho_Biko}
                                                    style={{ marginTop: "10px" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>

                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker

                                                        autoOk={true}
                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="対応日"
                                                        format="yyyy/MM/dd"
                                                        value={this.state.OutaiDate}
                                                        onChange={this.onChangeOutaiDate}
                                                        style={{ width: 130 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>

                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.OutaiDate_H}
                                                    value={this.state.OutaiDate_H}
                                                    id="standard-number"
                                                    label="時"
                                                    type="number"
                                                    onChange={this.onChangeOutaiDate_H}
                                                    style={{ width: 60, marginTop: 16 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <TextField
                                                    autoComplete='off'
                                                    defaultValue={this.state.OutaiDate_M}
                                                    value={this.state.OutaiDate_M}
                                                    id="standard-number"
                                                    label="分"
                                                    type="number"
                                                    onChange={this.onChangeOutaiDate_M}
                                                    style={{ width: 60, marginTop: 16 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <button onClick={this.ima} style={{ fontSize: 10, marginTop: 40, backgroundColor: "#5cb85c", color: "white" }}>今</button>

                                                <FormControlLabel
                                                    style={{ marginLeft: 50, marginTop: 20 }}
                                                    control={<GreenCheckbox name="OutaiSchedule" onChange={this.yoteiCheck} checked={this.state.OutaiSchedule} />}
                                                    label="予定"
                                                />

                                                <FormControlLabel
                                                    style={{ marginLeft: 50, marginTop: 20 }}
                                                    control={<GreenCheckbox name="houmon1" onChange={this.houmonCheck1} checked={this.state.houmon1} />}
                                                    label="訪問依頼として登録"
                                                />

                                                {this.state.houmon1 ?
                                                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                        <DateTimePicker
                                                            variant="inline"
                                                            label="訪問日時"
                                                            format="yyyy/MM/dd HH:mm"
                                                            value={this.state.irai ? this.state.irai : new Date()}
                                                            onChange={this.iraiChange}
                                                            style={{ marginTop: 16 }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    : ""
                                                }

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="応対内容"
                                    fullWidth
                                    multiline
                                    rows={10}
                                    variant="outlined"
                                    defaultValue={this.state.OutaiNaiyo}
                                    onChange={this.onChangeOutaiNaiyo}
                                    style={{ marginTop: "10px" }}
                                />

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="応対内容備考"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    defaultValue={this.state.OutaiBiko}
                                    onChange={this.onChangeOutaiBiko}
                                    style={{ marginTop: "10px" }}
                                />


                                {this.state.app_file_id ? <BodyImg onClick={this.get_app_files} id="cavas" src={this.state.bloburl} /> : ""}


                                {this.props.request_file.length !== 0 ?
                                    <Card style={{ marginTop: 10 }} className="col-lg-12" >
                                        <CardContent style={{ float: "left" }} className="col-lg-12">
                                            <FileGrid
                                                Outai_No={this.props.Outai_No}
                                                OutaiShosai_No={this.props.demo_date[this.props.index].OutaiShosai_No}
                                            />
                                        </CardContent>
                                    </Card>
                                    : ""}


                                {/* <div style={{ width: "250px", height: 40, margin: 5 }}>
                                    <Dropzone
                                        onDrop={this.onDrop}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5", height: 60, border: "solid" }}>
                                                <input {...getInputProps()} />
                                                <div>
                                                    <p>クリックまたはドラッグでファイルをアップロード</p>
                                                    <ul style={{ padding: 0, marginTop: 3 }}  >
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>

                                </div>
                                <div style={{ marginTop: 30 }}>
                                    {this.state.filename}
                                </div> */}
                            </CardContent>
                        </Card>
                    </div>

                    <div style={{ float: "right", marginBottom: "10px", }}>

                        <Button
                            style={{
                                marginRight: "20px",
                                color: "#fff",
                                backgroundColor: "#f50057",
                                borderColor: "#4cae4c",
                                width: "200px"
                            }}
                            onClick={this.props.reqtoggle}
                            color="secondary" variant="contained">
                            <i className="fas fa-door-closed"></i>閉じる
                            </Button>

                        {this.state.button_disp ?
                            <Button
                                style={{
                                    marginRight: "20px",
                                    color: "#fff",
                                    backgroundColor: "#5cb85c",
                                    borderColor: "#4cae4c",
                                    width: "200px"
                                }}
                                onClick={this.save}
                                variant="outlined" color="primary">
                                登録
          </Button> :
                            <span style={{ fontWeight: "bold" }}>登録中...</span>}
                    </div>





                </div > :



                <div className="newoutai" style={{ padding: 10, background: "#b0c4de" }} >
                    <div className="item-outer col-lg-12">
                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                {this.state.app_file_id ? <BodyImg onClick={this.get_app_files} id="cavas" src={this.state.bloburl} /> : ""}
                            </CardContent>
                        </Card></div>
                    <div style={{ float: "right", marginBottom: "10px", }}>

                        <Button
                            style={{
                                marginRight: "20px",
                                color: "#fff",
                                backgroundColor: "#f50057",
                                borderColor: "#4cae4c",
                                width: "200px"
                            }}
                            onClick={this.props.reqtoggle}
                            color="secondary" variant="contained">
                            <i className="fas fa-door-closed"></i>閉じる
                            </Button>
                    </div>
                </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        Parking: state.Adjustment.Parking,
        Gyousya: state.Adjustment.Gyousya,
        Outaisub: state.Adjustment.Outaisub,
        shops: state.masterReducer.shops,
        users_select: state.masterReducer.users_select,
        user_details: state.masterReducer.user_details,
        request_detail: state.Adjustment.request_detail,
        request_file: state.RequestDetail.request_file,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        syousai_update(state: any) {
            dispatch(RequestDetailSaga.syousai_update(state))
        },
        file_update(state: any) {
            dispatch(Adjustment.file_update(state))
        },
        get_app_files(state: any) {
            dispatch(RequestDetailSaga.get_app_files(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
//
