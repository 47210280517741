import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from './Index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import Button from '@material-ui/core/Button';
import * as RequestDetailSaga from './Saga/RequestDetail';

import * as Adjustment from '../Adjustment/Saga/Adjustment';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'file_no', name: 'No', width: 50, resizable: true },
    { key: 'file_name', name: 'ファイル名', width: 400, resizable: true },
    { key: 'nickname', name: '追加者', width: 100, resizable: true },
    { key: 'uploaded_at', name: '追加日時', width: 150, resizable: true },
    { key: 'delete', name: '', width: 150, resizable: true },
  ]

const Base = ({ column, props, rowIndex, fileopen }) =>
  <div style={{ cursor: "pointer" }} onClick={() => fileopen(rowIndex)}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell></Tooltip>;
  </div>

const Date = ({ column, props, rowIndex, fileopen }) =>
  <div style={{ cursor: "pointer" }} onClick={() => fileopen(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
    </Cell>
  </div>

const Delete = ({ column, props, rowIndex, file_delete }) =>
  <div>
    <Button size="small" onClick={() => file_delete(props[rowIndex]['id'])} color="secondary" variant="contained">
      <i className="fas fa-times-circle"></i>
    </Button>
  </div>




class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: [],
      columns: columns,
      reflash: false,
      reqtoggle: false,
      colSortDirs: {},
      img_disp: true
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.img_disp = this.img_disp.bind(this);
    this.file_delete = this.file_delete.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.request_file;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  fileopen = (rowIndex) => {
    // https://www.homestation.jp/manage/request/file/dl/36741/161370/1


    // 古い方のリクエスト一覧から登録された場合
    if (this.props.request_file[rowIndex].download_type == 1) {
      var no = this.props.request_file[rowIndex].Outai_No;
      var sy = this.props.request_file[rowIndex].OutaiShosai_No;
      var fi = this.props.request_file[rowIndex].file_no;

      var url = "https://www.homestation.jp/manage/request/file/dl/" + no + "/" + sy + "/" + fi;
      window.open(url, "_blank");
    } else {
      // 新しい方
      this.props.file_download({
        id: this.props.request_file[rowIndex].id,
        file_name: this.props.request_file[rowIndex].file_name
      });
      this.setState({ img: this.props.filefile.href })
    }
  }


  img_disp() {
    // var imgs = [];
    // this.props.request_file.forEach(element => {
    //   setTimeout(() => {
    //     if (element.ext == "jpg" || element.ext == "png") {
    //       var filename = element.id + "." + element.ext;
    //       this.props.img_download({ filename: filename, file_name: element.file_name });
    //       setTimeout(() => {
    //         console.log(this.props.imgs.download);
    //         // imgs.push(this.props.imgs.href);
    //         // this.setState({ img: imgs })
    //       }, 1500)
    //     }
    //   }, 1500)
    // })

    this.setState({ img_disp: false })


    var imgs = [];

    function sleep(time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    }

    (async () => {
      for (let element of this.props.request_file) {
        if (element.ext == "jpg" || element.ext == "png" || element.ext == "jpeg" || element.ext == "JPG" || element.ext == "PNG" || element.ext == "JPEG") {
          var filename = element.id + "." + element.ext;
          this.props.img_download({ filename: filename, file_name: element.file_name });
          await sleep(1000);
          imgs.push({
            filename: this.props.imgs.download,
            href: this.props.imgs.href
          });
          this.setState({ img: imgs });
        }
      }
    })();

    setTimeout(() => {
      this.setState({ img_disp: true })
    }, 1000)

  }


  file_delete(id) {


    var result = window.confirm('ファイルを削除します。データは戻りません。宜しいですか？');
    if (result) {
      this.props.delete_request_file({ id: id });
      alert("削除しました");
      var data = {
        Outai_No: this.props.Outai_No,
        OutaiShosai_No: this.props.OutaiShosai_No,
      }
      setTimeout(() => {
        this.props.get_request_file(data);
      }, 1000)
    }


  }

  render() {
    return (
      <div id="seisanlist">
        {this.state.img_disp ?
          <div>
            <span style={{ float: "right", marginBottom: "10px", }} >
              ※ルームサポートから追加した画像のみ
            <Button onClick={this.img_disp} color="primary" variant="contained">
                <i className="fas fa-eye"></i>　画像サムネイル表示</Button>
            </span>
            {this.state.img.map((d) => (
              <img style={{ width: 450 }} src={d.href} title={d.filename} alt={d.filename} />
            ))}
          </div>
          : ""}
        {/* <img style={{ width: 150 }} src={this.state.img} />あ */}
        <Table
          rowHeight={30}
          rowsCount={this.props.request_file.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 660}
          height={this.props.request_file.length * 100}
          headerHeight={30}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (
                value.key === "delete" ? <Delete column={value} props={this.props.request_file} rowIndex={rowIndex} file_delete={this.file_delete}></Delete>
                  : value.key === "uploaded_at" ? <Date column={value} props={this.props.request_file} rowIndex={rowIndex} fileopen={this.fileopen}></Date>
                    : <Base column={value} props={this.props.request_file} rowIndex={rowIndex} fileopen={this.fileopen}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >



        < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="xl" fade={false}>
          <ModalHeader toggle={this.reqtoggle}>対応履歴</ModalHeader>
          <SyousaiModal
            index={this.state.index} />
          <ModalFooter>
          </ModalFooter>
        </Modal >


      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    request_detail: state.Adjustment.request_detail,
    imgs: state.Adjustment.imgs,
    request_file: state.RequestDetail.request_file,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    file_download(state) {
      dispatch(Adjustment.file_download(state))
    },
    img_download(state) {
      dispatch(Adjustment.img_download(state))
    },
    delete_request_file(state) {
      dispatch(RequestDetailSaga.delete_request_file(state))
    },
    get_request_file(state) {
      dispatch(RequestDetailSaga.get_request_file(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);