import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from './Index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import Button from '@material-ui/core/Button';
import * as RequestDetailSaga from './Saga/RequestDetail';

import * as Adjustment from '../Adjustment/Saga/Adjustment';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'construction_id', name: '工事ID', width: 50, resizable: true },
    { key: 'name', name: '工事区分', width: 150, resizable: true },
    { key: 'subject_name', name: '件名', width: 300, resizable: true },
    { key: 'tatemono_name', name: '物件名', width: 150, resizable: true },
    { key: 'room_name', name: '部屋名', width: 100, resizable: true },
    { key: 'tsp', name: '売単価', width: 100, resizable: true },
    { key: 'tc', name: '原単価', width: 100, resizable: true },
    { key: 'order_issue', name: '発注日', width: 90, resizable: true },
    { key: 'completion_enter', name: '完工日', width: 90, resizable: true },

  ]

const Base = ({ column, props, rowIndex, kouji_open }) =>
  <div style={{ cursor: "pointer" }} onClick={() => kouji_open(props[rowIndex]["construction_id"])}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell></Tooltip>
  </div>

const Date = ({ column, props, rowIndex, kouji_open }) =>
  <div style={{ cursor: "pointer" }} onClick={() => kouji_open(props[rowIndex]["construction_id"])}>
    {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
  </div>

const Price = ({ column, props, rowIndex, kouji_open }) =>
  (() => {
    var prices = props[rowIndex][column.key].toLocaleString('ja-JP', { "style": "currency", "currency": "JPY" });
    return <div style={{ cursor: "pointer" }} onClick={() => kouji_open(props[rowIndex]["construction_id"])}>
      {prices}
    </div>
  })()
const Tatemono_name = ({ column, props, rowIndex, tatemono_name, kouji_open }) =>
  <div style={{ cursor: "pointer" }} onClick={() => kouji_open(props[rowIndex]["construction_id"])}>
    <Cell style={{ whiteSpace: "nowrap" }} > {tatemono_name} </Cell>
  </div>

const Room_name = ({ column, props, rowIndex, room_name, kouji_open }) =>
  <div style={{ cursor: "pointer" }} onClick={() => kouji_open(props[rowIndex]["construction_id"])}>
    <Cell style={{ whiteSpace: "nowrap" }} > {room_name} </Cell>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: [],
      columns: columns,
      reflash: false,
      reqtoggle: false,
      colSortDirs: {},
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.kouji_open = this.kouji_open.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }




  kouji_open = (id) => {
    var url = "https://www.homestation.jp/manage/construction/construction/edit?constid=" + id;
    window.open(url, '_blank', 'noopener');
  }

  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.kouji_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  render() {
    return (
      <div id="seisanlist">
        {this.props.kouji_list.kouji ?
          <Table
            rowHeight={30}
            rowsCount={this.props.kouji_list.kouji.length}
            // rowsCount={10}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            width={window.innerWidth - 660}
            height={this.props.kouji_list.kouji.length * 100}
            headerHeight={30}>
            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (
                  value.key === "tc" || value.key === "tsp" ? <Price column={value} props={this.props.kouji_list.kouji} rowIndex={rowIndex} kouji_open={this.kouji_open}></Price>
                    : value.key === "order_issue" || value.key === "completion_enter" ? <Date column={value} props={this.props.kouji_list.kouji} rowIndex={rowIndex} kouji_open={this.kouji_open}></Date>
                      : value.key === "room_name" ? <Room_name column={value} props={this.props.kouji_list.kouji} rowIndex={rowIndex} room_name={this.props.request_detail.detail[0].Room_Name} kouji_open={this.kouji_open}></Room_name>
                        : value.key === "tatemono_name" ? <Tatemono_name column={value} props={this.props.kouji_list.kouji} rowIndex={rowIndex} tatemono_name={this.props.request_detail.detail[0].Tatemono_Name} kouji_open={this.kouji_open}></Tatemono_name>
                          : <Base column={value} props={this.props.kouji_list.kouji} rowIndex={rowIndex} kouji_open={this.kouji_open}></Base>
                )}
                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}
              />
            }, this)}
          </Table > : ""}

      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    request_detail: state.Adjustment.request_detail,
    imgs: state.Adjustment.imgs,
    kouji_list: state.RequestDetail.kouji_list,
    request_detail: state.Adjustment.request_detail,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    file_download(state) {
      dispatch(Adjustment.file_download(state))
    },
    img_download(state) {
      dispatch(Adjustment.img_download(state))
    },
    get_kouji_list(state) {
      dispatch(RequestDetailSaga.get_kouji_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);