import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import '../RequestDetail/Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as Sms from '../Sms/Saga/Sms';
import * as RequestDetailSaga from '../RequestDetail/Saga/RequestDetail';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import ChatTemplateGrid from '../Chat/ChatTemplateGrid';
import TemplateKanri from "../Keiyaku/TemplateKanri";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import { Row, Col } from 'reactstrap';
import NewSmsGrid from "./NewSmsGrid";

import moment from 'moment'



interface Props {
    user_details: any,
    send_message: any,
    sms_status: any,
    smstoggle: any,
    template_category_list: any,
    get_template: any,
    get_template_category_list: any,
    sms_insert: any,
    tel: any,
    template_list: any,
    get_template_detail: any,
    template: any,
    get_sms: any
}
interface State {
    Renraku_Saki: any,
    message: any,
    disp: boolean,
    buton_disp: boolean,
    Templatetoggle: boolean,
    template_disp: any,
    template_disp2: any,
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        props.get_sms(props.tel);
        this.state = {
            template_disp: false,
            template_disp2: false,
            Templatetoggle: false,
            disp: true,
            buton_disp: true,
            Renraku_Saki: props.tel,
            message: "株式会社グローバルセンターです。\n入居者の皆様に大切なお知らせがございます。\n\n平素より入居者の皆様にはお世話になっております。\n当社管理物件入居者様により快適にお過ごしいただくために、\nグローバルセンター入居者様専用アプリ「RoomSupport(ﾙｰﾑｻﾎﾟｰﾄ）」をリリースする運びとなりました。\n入居中のお問合せや建物情報(ライフライン等)の確認に、ぜひ活用ください！\n詳細は、下記のページをご確認下さい。\nhttps://living.global-center.co.jp/roomsupport\n\niPhone\nhttps://apps.apple.com/jp/app/id1571451725?mt=8\n\nandroid\nhttps://play.google.com/store/apps/details?id=jp.co.globalcenter.roomsupport\n\nなお、このメッセージは送信専用となっておりますので、ご返信は受付できません。\n\n●㈱グローバルセンター\n●お問合せ先\nhttps://living.global-center.co.jp/contact/"
            // message: "いつも大変お世話になっております。\n株式会社グローバルセンターです。\n\nなお、このメッセージは送信専用となっておりますので、ご返信は受付できません。 \n以上、宜しくお願い致します。"
        }
        this.onChangeRenraku_Saki = this.onChangeRenraku_Saki.bind(this);
        this.onChangemessage = this.onChangemessage.bind(this);

        this.ontemplate = this.ontemplate.bind(this);
        this.send = this.send.bind(this);
        this.onchange_category = this.onchange_category.bind(this);
        this.onChange = this.onChange.bind(this);
        this.Templatetoggle = this.Templatetoggle.bind(this);
        this.onchange_template = this.onchange_template.bind(this);
        this.search = this.search.bind(this);

    }

    Templatetoggle() {

        if (this.state.Templatetoggle == false) {
            this.props.get_template_category_list();
        }
        this.setState({ Templatetoggle: !this.state.Templatetoggle })

    }

    onChange(e: any) {
        this.setState({ message: e.target.value, disp: false });
        setTimeout(() => {
            this.setState({ disp: true })
        }, 1000)
    }

    onChangeRenraku_Saki(e: any) {
        this.setState({
            Renraku_Saki: e.target.value,
        });
    }
    onChangemessage(e: any) {
        this.setState({
            message: e.target.value,
        });
    }

    ontemplate() {
        this.setState({
            message: "いつも大変お世話になっております。\n株式会社グローバルセンターです。\n\n弊社の管理物件にお住まいの方向けの入居者様専用アプリをご案内致します。iPhone、androidをご使用の方は、下記URLからアプリをダウンロードいただけます。\n\niPhone\nhttps://apps.apple.com/jp/app/id1571451725?mt=8\n\nandroid\nhttps://play.google.com/store/apps/details?id=jp.co.globalcenter.roomsupport\n\nアプリの利用方法などは下記のページをご確認下さい。\nhttps://living.global-center.co.jp/roomsupport\n\nなお、このメッセージは送信専用となっておりますので、ご返信は受付できません。 \n以上、宜しくお願い致します。",
            disp: false
        });

        setTimeout(() => {
            this.setState({ disp: true })
        }, 1000)
    }

    send() {

        this.setState({ buton_disp: false })

        var d = moment(new Date()).tz("Asia/Tokyo").format('YYYY年MM月DD日 HH:mm:ss');

        var data = {
            message_text: this.state.message,
            mobilenumber: this.state.Renraku_Saki,
            returnsmsdatetime: d,
            user_id: this.props.user_details.id,
            user_name: this.props.user_details.userNameAll,
            section_id: this.props.user_details.amSectionId
        }

        this.props.send_message(data);

        setTimeout(() => {
            if (this.props.sms_status == 200) {
                alert("送信しました。");


                var sms_data = {
                    mobilenumber: this.state.Renraku_Saki,
                    message: this.state.message,
                    sended_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
                    sended_user: this.props.user_details.id,
                    sended_section: this.props.user_details.amSectionId,
                    return_code: this.props.sms_status,
                }

                this.props.sms_insert(sms_data);

                setTimeout(() => {
                    this.props.smstoggle();
                }, 500)
            } else {
                var sms_data = {
                    mobilenumber: this.state.Renraku_Saki,
                    message: this.state.message,
                    sended_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
                    sended_user: this.props.user_details.id,
                    sended_section: this.props.user_details.amSectionId,
                    return_code: this.props.sms_status
                }

                this.props.sms_insert(sms_data);

                alert("失敗しました。 エラーコードは" + this.props.sms_status + "です。画面下にあるエラーコード一覧をご確認下さい。");
                this.setState({ buton_disp: true });
            }
        }, 2000)
    }

    onchange_category(event: any, values: any) {
        this.setState({
            template_disp2: false
        });
        if (values) {
            this.props.get_template(values.id);

            setTimeout(() => {
                this.setState({
                    template_disp2: true
                });
            }, 1000)
        }
    }

    onchange_template(event: any, values: any) {
        if (values) {
            this.setState({
                disp: false
            });
            this.props.get_template_detail(values.id);
            setTimeout(() => {
                this.setState({
                    message: this.props.template[0].template_contents,
                });
            }, 500)
            setTimeout(() => {
                this.setState({
                    disp: true
                });
            }, 500)
        }
    }

    search() {
        this.props.get_sms(this.state.Renraku_Saki);
    }

    render() {

        return (
            <div className="newrequest" style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Row>
                        <Card className="col-lg-8" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>
                                    送信先：<TextField
                                        autoComplete='off'
                                        value={this.state.Renraku_Saki}
                                        onChange={this.onChangeRenraku_Saki}
                                        style={{ width: 400 }}
                                        id="standard-basic" />

                                    <Button
                                        style={{
                                            marginLeft: "50px"
                                        }}
                                        onClick={this.search}
                                        variant="outlined" color="primary">
                                        履歴更新
          </Button>※番号以外の文字があると検索できません
                                </div>
                                <div>●連絡先はハイフン無しの携帯電話の番号にしてください。</div>
                                <div>●このSMSに返信はできません。必ずその旨を内容に入れて下さい。</div>
                                <div>●600文字を超えると文章が途切れます。</div>
                                <Row>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.template_category_list}
                                        getOptionLabel={(option: any) => option.category_title}
                                        style={{ width: 400, marginTop: 12, marginLeft: 15 }}
                                        onChange={this.onchange_category}
                                        renderInput={(params) => <TextField {...params} label="テンプレートカテゴリー" />}
                                    />
                                    {this.state.template_disp2 ?
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.props.template_list}
                                            getOptionLabel={(option: any) => option.template_name}
                                            style={{ width: 400, marginTop: 12, marginLeft: 15 }}
                                            onChange={this.onchange_template}
                                            renderInput={(params) => <TextField {...params} label="テンプレート" />}
                                        /> : ""}


                                </Row>

                                <Button
                                    style={{ marginLeft: 200, float: "right", margin: 5 }}
                                    onClick={this.Templatetoggle}
                                    color="primary"
                                    variant="outlined">
                                    テンプレートを作成する
                                    </Button>
                                {this.state.disp ?
                                    <TextField
                                        id="outlined-read-only-input"
                                        label="本文"
                                        fullWidth
                                        multiline
                                        rows={15}
                                        variant="outlined"
                                        onChange={this.onChangemessage}
                                        defaultValue={this.state.message}
                                        style={{ marginTop: "10px" }}
                                    />
                                    : ""}
                                <div>
                                    文字数:{this.state.message.length}
                                </div>
                                {/* <Button style={{ color: "white", backgroundColor: "#28a745", margin: 5 }} variant="contained" onClick={this.ontemplate}>アプリ勧誘テンプレート</Button> */}


                                {this.state.buton_disp ?
                                    <Button style={{ float: "right", margin: 5 }} color="primary" variant="contained" onClick={this.send}>送信</Button>
                                    : ""}

                                {/* <div>
                                <ChatTemplateGrid onChange={this.onChange} />
                            </div> */}





                            </CardContent>

                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>エラーコード一覧</div>
                                <div>
                                    401 認証エラー 402 送信数が上限 503 一時的に利用不可 555 不正アクセス
                            </div>
                                <div>
                                    560 電話番号の値がおかしい
                                </div>
                            </CardContent>
                        </Card>
                        {/* <Card className="col-lg-8" style={{ marginTop: 10 }}  >
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>エラーコード一覧</div>
                                <div>
                                    401 認証エラー 402 送信数が上限 503 一時的に利用不可 555 不正アクセス
                            </div>
                                <div>
                                    560 電話番号の値がおかしい
                                </div>
                            </CardContent>
                        </Card> */}
                        <Card className="col-lg-4" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <NewSmsGrid />
                            </CardContent>
                        </Card>

                    </Row>
                </div>

                < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} size="keiyaku" fade={false} style={{ marginRight: 700 }}>
                    <ModalHeader toggle={this.Templatetoggle}>テンプレート管理</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TemplateKanri />
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        sms_status: state.sms.sms_status,
        template_category_list: state.Keiyaku.template_category_list,
        template_list: state.Keiyaku.template_list,
        template: state.Keiyaku.template,
        sms_list: state.RequestDetail.sms_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        send_message(state: any) {
            dispatch(Sms.send_sms_message(state))
        },
        syousai_insert(state: any) {
            dispatch(RequestDetailSaga.syousai_insert(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        sms_insert(state: any) {
            dispatch(RequestDetailSaga.sms_insert(state))
        },
        get_template_detail(state: any) {
            dispatch(Keiyaku.get_template_detail(state))
        },
        get_sms(state: any) {
            dispatch(RequestDetailSaga.get_sms(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

