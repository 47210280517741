import React from 'react';

import styled from 'styled-components';

var moment = require('moment-timezone');


const Hiduke = styled.div`
        text-align: center;
        color: white;
`;

const Detail = styled.span`
        background-color: #4682b4;padding:3px 6px;border-radius: 20%;
`;

let yobi = ["日", "月", "火", "水", "木", "金", "土"];
let hiduke = new Date();
export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            mode: '1',
            messages: [],

            date_time: (hiduke.getFullYear() === this.props.backdate.getFullYear() && hiduke.getMonth() === this.props.backdate.getMonth() && hiduke.getDate() === this.props.backdate.getDate()) ? "今日" : (Number(this.props.backdate.getMonth()) + 1) + "/" + this.props.backdate.getDate() + '(' + yobi[this.props.backdate.getDay()] + ')'

        };



    }

    render() {
        return (
            <Hiduke className="col-xs-12">
                <Detail>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Detail>
            </Hiduke>

        )
    }
}
