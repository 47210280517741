import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Chat.css';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import * as Chat from './Saga/Chat';



const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');
var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


function reverseSortDirection(sortDir) {
    return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        var { onSortChange, sortDir, children, ...props } = this.props;
        return (
            <Cell {...props} onClick={this._onSortChange}>
                {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
            </Cell>
        );
    }

    _onSortChange(e) {
        e.preventDefault();
        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}



const columns =
    [
        { key: 'title', name: '', width: 480, resizable: true },
    ]



const Base = ({ column, props, rowIndex, chatopen, tabs }) =>
    (() => {
        if (tabs == true && props.mikanryou[rowIndex]['completion_at'] == null) {
            var url = "https://roomsupport.homestation.jp/RequestDetail/" + props.mikanryou[rowIndex]['Outai_No'];
            return <div style={{ textAlign: "left" }} onClick={() => chatopen(props.mikanryou[rowIndex])}>
                <div style={{ fontSize: 20 }}>
                    {props.mikanryou[rowIndex][column.key] ? props.mikanryou[rowIndex][column.key] : "無題"}
                </div>
                <div>
                    {moment(props.mikanryou[rowIndex]['updated_at']).utc().format('YYYY-MM-DD HH:mm:ss')}
                    {props.mikanryou[rowIndex]['Outai_No'] ? <a style={{ marginLeft: 10 }} href={url} target="_blank" rel="noopener"><i className="fas fa-window-restore"></i></a> : ""}
                </div>
                <div>
                    {props.mikanryou[rowIndex]['last_message']}
                </div>
            </div>
        } else if (tabs == false && props.kanryou[rowIndex]['completion_at'] !== null) {
            var url = "https://roomsupport.homestation.jp/RequestDetail/" + props.kanryou[rowIndex]['Outai_No'];
            return <div style={{ textAlign: "left" }} onClick={() => chatopen(props.kanryou[rowIndex])}>
                <div style={{ fontSize: 20 }}>
                    {props.kanryou[rowIndex][column.key] ? props.kanryou[rowIndex][column.key] : "無題"}
                </div>
                <div>
                    {moment(props.kanryou[rowIndex]['updated_at']).utc().format('YYYY-MM-DD HH:mm:ss')}
                    {props.kanryou[rowIndex]['Outai_No'] ? <a style={{ marginLeft: 10 }} href={url} target="_blank" rel="noopener"><i className="fas fa-window-restore"></i></a> : ""}
                </div>
                <div>
                    {props.kanryou[rowIndex]['last_message']}
                </div>
            </div>
        } else {
            return ""
        }
    })()

class Chats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: columns,
            reflash: false,
            tabs: true,
            colSortDirs: {},
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);
        this.onchangeTab = this.onchangeTab.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

    }
    _onColumnResizeEndCallback(newColumnWidth, columnKey) {

        let columnsset = this.state.columns.map(function (value) {
            if (value.key === columnKey) {
                value.width = newColumnWidth
            }
            return value
        })

        this.setState({ columns: columnsset })

    }





    _onSortChange(columnKey, sortDir) {

        let seisan_follow_search_list = this.props.chatlist;
        seisan_follow_search_list.sort((indexA, indexB) => {
            var valueA = indexA[columnKey];
            var valueB = indexB[columnKey];
            var sortVal = 0;
            if (valueA > valueB) {
                sortVal = 1;
            }
            if (valueA < valueB) {
                sortVal = -1;
            }

            if (!valueA && !valueB) {
                sortVal = 0
            }
            if (!valueA) {
                sortVal = -1
            }
            if (!valueB) {
                sortVal = 1
            }


            if (sortVal !== 0 && sortDir === SortTypes.DESC) {
                sortVal = sortVal * -1;
            }

            return sortVal;
        });
        this.setState({
            // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
            colSortDirs: {
                [columnKey]: sortDir,
            },
        });
    }

    chatopen = (data) => {
        console.log("-----------------")
        console.log("========================")
        console.log(data)
        this.props.set_process_flag(data);
        this.props.listonchat(data.id);
        this.props.get_chats(data.id);
    }

    onchangeTab() {
        this.setState({ tabs: !this.state.tabs })
    }
    none() {

    }


    _rowClassNameGetter(index) {
        if (this.state.tabs) {
            if (this.props.chatlist.mikanryou[index]) {
                if (this.props.chatlist.mikanryou[index]["process_flag"] == 0 && this.props.chatlist.mikanryou[index]["Outai_No"]) {
                    return 'midoku-row';
                }
            }
        } else {
            if (this.props.chatlist.kanryou[index]) {
                if (this.props.chatlist.kanryou[index]["process_flag"] == 0 && this.props.chatlist.kanryou[index]["Outai_No"]) {
                    return 'midoku-row';
                }
            }
        }
    }

    render() {
        if (this.props.chatlist.mikanryou) {
            return (
                <div>
                    <div style={{ fontSize: 20, paddingTop: 20, float: "left", fontWeight: "bold" }}>
                        {this.props.chatlist.m_customers[0].matching_rank == 2 ?
                            "電話番号の認証ができていないため、個人情報は送らないようにしてください。" : "　"}
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <Tab
                            onChange={this.state.tabs ? this.none : this.onchangeTab}
                            style={{
                                backgroundColor: this.state.tabs ? "white" : "#d3d3d3",
                                width: 200,
                                fontWeight: this.state.tabs ? "bold" : "",
                                color: this.state.tabs ? "black" : "#bab8b8",
                            }}
                            label="進行中" />
                        <Tab
                            onChange={this.state.tabs ? this.onchangeTab : this.none}
                            style={{
                                backgroundColor: this.state.tabs ? "#d3d3d3" : "white",
                                width: 200,
                                fontWeight: this.state.tabs ? "" : "bold",
                                color: this.state.tabs ? "#bab8b8" : "black",
                            }}
                            label="完了" />
                    </div>


                    <Table
                        rowHeight={110}
                        rowsCount={this.state.tabs ? this.props.chatlist.mikanryou.length : this.props.chatlist.kanryou.length}
                        // rowsCount={10}
                        onColumnResizEndCallback={this._onColumnResizeEndCallback}
                        width={480}
                        height={window.innerHeight - 320}
                        headerHeight={0}
                        style={{ whiteSpace: "nowrap" }}
                        rowClassNameGetter={this._rowClassNameGetter}>
                        {columns.map(function (value, i) {
                            return <Column
                                columnKey={value.key}
                                header={<SortHeaderCell
                                    onSortChange={this._onSortChange}
                                    sortDir={this.state.colSortDirs[value.key]}
                                    style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                                cell={({ rowIndex, ...props }) => (
                                    <Base column={value} props={this.props.chatlist} rowIndex={rowIndex} chatopen={this.chatopen} tabs={this.state.tabs}></Base>
                                )}
                                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                                isResizable={true}
                                fixed={value.frozen}
                            />
                        }, this)}
                    </Table >
                </div>
            );
        } else {
            return (
                <div>
                    読み込み中
                </div>
            );
        }
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        chatlist: state.Keiyaku.chatlist,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_chats(state) {
            dispatch(Chat.get_chats(state))
        },
        set_process_flag(state) {
            dispatch(Chat.set_process_flag(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Chats);
