import React from 'react';

import styled from 'styled-components';

var moment = require('moment-timezone');
const Balloon = styled.div`
position:relative;
    padding:0px;
    margin-top:15px;
`;
const Faceicon = styled.div`
    float: left;
        margin-right: -50px;
        width: 40px;
`;
const FaceiconImg = styled.img`
    width: 100%;
        height: auto;
        border-radius: 50%;
`;
const DisplayName = styled.div`
    margin:0 0 0 50px;
    font-size:9px;
    font-weight:bold;
    color:#FFF;
text-align:left;
`;
const Chatting = styled.div`
    width: 100%;
        text-align: left;
`;
const Say = styled.div`
    display: inline-block;
        position: relative; 
        margin: 0 0 0 50px;
        padding: 10px;
        max-width: 250px;
        border-radius: 12px;
        background: #73e6bf;
        margin-bottom: 2px;
    &:after{
        content: "";
        display: inline-block;
        position: absolute;
        top: 3px; 
        left: -19px;
        border: 8px solid transparent;
        border-right: 18px solid #73e6bf;
        -ms-transform: rotate(35deg);
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
}
`;

const MsgArea = styled.p`
    margin: 0;
        padding: 0;
word-wrap: break-word;
`;
const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;bottom: 0px;
    font-size:10px;
    color:#FFF;
`;


export default class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = this.props.created_at ? new Date(this.props.created_at) : "";
        console.log("-----------------");
        console.log(this.props);
        this.state = {
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };

    }

    render() {
        return (

            <Balloon className="col-xs-12" >
                {/* <Faceicon><FaceiconImg src={this.props.msg.img_url} /></Faceicon>
                <DisplayName>{this.props.msg.display_name}</DisplayName> */}
                <Chatting>
                    <Say>
                        <MsgArea>
                            {this.props.msg}
                            {/* <span dangerouslySetInnerHTML={{ __html: this.props.msg.contents.replace(/\r?\n/g, '<br/>') }} /> */}
                        </MsgArea>
                    </Say>
                    <Time>
                        <span style={{ color: "black" }}>
                            {moment.utc(this.props.created_at).format('MM/DD HH:mm')}
                        </span>
                    </Time>
                    {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
                </Chatting>
            </Balloon>

        )
    }
}
