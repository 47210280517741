import React, { Component } from 'react';

import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { firebaseDb } from '../../Store/FirebaseStore';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import './Css/Chat.css';
import ChatRightText from './ChatRightText';
import ChatRightImg from './ChatRightImg';
import ChatRightType1 from './ChatRightType1';
import ChatRightType2 from './ChatRightType2';
import ChatRightType3 from './ChatRightType3';
import ChatRightType4 from './ChatRightType4';
import ChatRightType5 from './ChatRightType5';
import ChatLeftText from './ChatLeftText';
import ChatLeftImg from './ChatLeftImg';
import ChatTime from './ChatTime';
import ChatForm from './ChatForm.js';
import throttle from 'lodash.throttle';
import * as Chat from './Saga/Chat';
import ChatDisp from './ChatDisp';
import ChatList from './ChatList';
import Dropzone from 'react-dropzone';
// import Grid from '../StockRooms/Grid';
import Card from '@material-ui/core/Card';
// import * as firebase from 'firebase';
// import firebaseSmsConfig from '../../firebase-sms-config';
import { roomsupportDB } from '../../Store/FirebaseStore';
import MessageShare from '../MessageShare/Index';
import * as RequestDetailSaga from '../RequestDetail/Saga/RequestDetail';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './Css/Table.css';
import './Css/Form.css';
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }

let unsub


class Chats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            modal_send: 0,
            listdisp: true,
            base64: null,
            mimeType: null,
            chat_id: String(props.chat_id),
            share_disp: true,
            totyu: false,
            automatic: false
        };
        console.log(this.props.chats)

        this.send = this.send.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.chatopen = this.chatopen.bind(this);
        this.toggle_send = this.toggle_send.bind(this);

        this.scrollToBottom = this.scrollToBottom.bind(this);

        this.onDrop = this.onDrop.bind(this);

        this.listonchat = this.listonchat.bind(this);

        this.share_disp = this.share_disp.bind(this);
        this.new_chat = this.new_chat.bind(this);
        this.listdisp_check = this.listdisp_check.bind(this);

        // .where("chat_user_id", "==", this.props.chat_user_details.id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")


    }




    // 新しくチャットを追加するボタン
    new_chat() {
        var result = window.confirm('お客様のアプリに新しいチャットが作成されます。よろしいですか？');
        if (result) {
            // 上書き処理
            this.props.new_chat({ room_support_m_user_id: this.props.room_support_m_user_id });
            alert("作成しました。")

            setTimeout(() => {
                this.props.get_chatlist({
                    m_customer_id: this.props.m_customer_id,
                    room_support_m_user_id: this.props.room_support_m_user_id,
                    totyu: this.state.totyu,
                    automatic: this.state.automatic
                });
            }, 1000)

        } else {
            alert("キャンセルしました")
        }
    }



    async componentDidMount() {
        console.log(this.props)
        if (unsub) {
            unsub()
        }
        if (this.node != null && this.props.chat_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_chat_bottom(false);
        }


        // console.log(firebaseSmsConfig)

        // const firebaseSmsApp = firebase.initializeApp(firebaseSmsConfig, "dests");
        // const db_response = firestore.firestore("sms");

        // const docId = db_response.collection("sms");
        // db_response.collection("sms").doc("!").set({
        //     docId: "aaa",
        //     name: "aaa",
        //     email: "aaa",
        //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // });
        // .where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")


        unsub = roomsupportDB.collection("chats").doc(this.state.chat_id).collection("messages").orderBy("timestamp", "asc")
            .onSnapshot((querySnapshot) => {
                this.props.change_chats([])
                querySnapshot.forEach((doc) => {
                    // alert(doc.data().type)
                    // console.log(change.doc.data())
                    this.props.change_chats(this.props.chats.concat(doc.data()))
                    this.props.change_chat_bottom(true);
                    // cities.push(doc.data().name);
                }, this);

            });
        // .onSnapshot(snapshot => {
        //     snapshot.docChanges().forEach(function (change) {
        //         // alert(timestamp)
        //         alert("s")
        //         console.log(change.doc.data())
        //         this.props.change_chats(this.props.chats.concat(change.doc.data()))
        //         this.props.change_chat_bottom(true);
        //         // this.setState({ reflesh: !this.state.reflesh })
        //     }, this)
        // });
    }
    componentDidUpdate() {

        if (this.node != null && this.props.chat_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_chat_bottom(false);
        }
    }




    // チャットの送信
    send(value) {

        this.props.chat_kanryou(
            {
                id: this.state.chat_id,
                user: this.props.user_details.id
            });


        this.props.send_chat_message({ contents: value.message, room_support_m_chat_id: this.state.chat_id })


        var data = {
            OutaiNaiyo: value.message,
            OutaiTanto_Id: this.props.user_details.id,
            room_support_m_chat_id: this.state.chat_id
        }

        this.props.send_chat_outai(data);
        this.props.set_process_flag(
            {
                process_user: this.props.user_details.userNameAll,
                process_flag: 1
            }
        );

        this.props.get_chats(0);

        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.props.Outai_No });
            this.props.get_chats(this.state.chat_id);
        }, 2000)

    }


    chatopen() {
        this.props.get_chat_open({ chat_user_id: this.props.chat_user_details.id, take: 20 })
    }


    toggle_send(nom) {

        this.setState({
            // modal_send: nom,
            base64: null,
            name: null,
            mimeType: null,
        })
        this.props.change_chat_file_data(null)
        this.props.change_chat_send_modal(nom)
    }

    scrollToBottom = () => {

        if (this.node != null && this.props.chat_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;


            this.props.change_chat_bottom(false);
        }
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        console.log(file.type)
        reader.onload = () => {
            this.setState({
                base64: reader.result,
                name: file.name,
                mimeType: file.type
            })
            this.props.change_chat_file_data({
                file: files,
                name: file.name,
                mimeType: file.type
            })
        }

        // let sendfile = [];aaa
        // sendfile.file = files;



    };


    handleScroll = throttle((event) => {
        if (this.node.scrollTop === 0) {

            // this.props.get_chats({ chat_user_id: this.props.chat_user_details.id, take: this.state.take + 5 })
            // this.setState({ take: this.state.take + 5 })
            // this.node.scrollTop = 50;

        }
    }, 100)



    // チャットリストからチャットを開く
    listonchat(id) {

        this.props.get_chats(0);

        console.log("------------------")
        console.log(String(id));
        this.setState({
            listdisp: !this.state.listdisp,
            chat_id: String(id)
        })

        if (this.state.listdisp) {
            if (unsub) {
                unsub()
            }
            if (this.node != null && this.props.chat_bottom === true) {

                this.node.scrollTop = this.node.scrollHeight;
                this.props.change_chat_bottom(false);
            }

            unsub = roomsupportDB.collection("chats").doc(String(id)).collection("messages").orderBy("timestamp", "asc")
                .onSnapshot((querySnapshot) => {
                    this.props.change_chats([])
                    querySnapshot.forEach((doc) => {
                        // alert(doc.data().type)
                        // console.log(change.doc.data())
                        this.props.change_chats(this.props.chats.concat(doc.data()))
                        this.props.change_chat_bottom(true);
                        // cities.push(doc.data().name);
                    }, this);

                });
        }
    }


    share_disp() {
        this.setState({ share_disp: !this.state.share_disp })
    }

    listdisp_check = (e) => {

        console.log("----------------");
        console.log(e.target.name);
        console.log(e.target.checked);

        this.setState({ ...this.state, [e.target.name]: e.target.checked });

        setTimeout(() => {
            this.props.get_chatlist({
                m_customer_id: this.props.m_customer_id,
                room_support_m_user_id: this.props.room_support_m_user_id,
                totyu: this.state.totyu,
                automatic: this.state.automatic
            });
        }, 500)

        this.setState({ share_disp: !this.state.share_disp })
        setTimeout(() => {
            this.setState({ share_disp: !this.state.share_disp })
        }, 1000)
    }


    render() {
        return (
            <div className="item-chat" id="item-chat" onScroll={this.handleScroll} ref={(node) => this.node = node} style={{ height: this.props.chat_open_flg ? "calc(100% - 60px)" : "500px" }}>




                {/* {this.state.listdisp && this.props.m_customer_id !== 0 && this.props.chat_id == 0 ?
                    <div style={{ marginBottom: 60 }}>
                        <MessageShare messages="chat" share_disp={this.state.share_disp} />
                    </div> : ""
                } */}

                {this.state.listdisp && this.props.chat_id == 0 ?
                    <div>
                        <FormControlLabel
                            style={{
                                marginLeft: 20
                            }}
                            onChange={this.listdisp_check}
                            control={<Checkbox
                                color="primary"
                                name="totyu" />}
                            label="入力途中も表示"
                        />
                        <FormControlLabel
                            style={{
                                marginLeft: 20
                            }}
                            onChange={this.listdisp_check}
                            control={<Checkbox
                                color="primary"
                                name="automatic" />}
                            label="自動完了も表示"
                        />
                    </div>
                    : this.state.listdisp ? "" :
                        <div className="backarrow" style={{ cursor: "pointer" }}>
                            <a onClick={() => this.listonchat("0")} style={{ fontSize: 15 }} className="fas fa-chevron-left">戻る</a>
                        </div>
                }

                <div>
                    <i
                        style={{ position: "absolute", top: 5, color: "black", fontSize: 15, cursor: "pointer" }}
                        class="fas fa-times-circle chatclose" onClick={this.share_disp} onClick={this.props.get_chat_close}>閉じる</i>
                </div>


                <div>
                    {this.state.listdisp && this.props.chat_id == 0 ? <ChatList listonchat={this.listonchat} /> : <ChatDisp />}


                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                    {this.state.listdisp && this.props.chat_id == 0 ?
                        <div className="chatbottom">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.new_chat}
                            >
                                新しいチャットを追加する
                            </Button>
                        </div>
                        :
                        <div className="chatbottom">
                            {this.props.completion_at ? "" :
                                <ChatForm
                                    onSubmit={this.send}
                                    get_chat_close={this.props.get_chat_close}
                                    toggle_send={this.toggle_send} />}
                        </div>
                    }


                </div>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        // responsedetail: state.responsesReducer.responsedetail,
        chats: state.chat.chats,
        chat_id: state.chat.chat_id,
        chat_bottom: state.chat.chat_bottom,
        chat_open_flg: state.chat.chat_open_flg,
        chat_send_modal: state.chat.chat_send_modal,
        chat_send_file_loading: state.chat.chat_send_file_loading,
        chat_send_list_loading: state.chat.chat_send_list_loading,
        m_customer_id: state.Keiyaku.m_customer_id,
        room_support_m_user_id: state.Keiyaku.room_support_m_user_id,

        user_details: state.masterReducer.user_details,
        Outai_No: state.chat.Outai_No,
        new_chat_id: state.chat.new_chat_id,

        m_customer_id: state.Keiyaku.m_customer_id,
        room_support_m_user_id: state.Keiyaku.room_support_m_user_id,

        Outai_No: state.chat.Outai_No,
        completion_at: state.chat.completion_at,
        request_detail: state.Adjustment.request_detail,

        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_chat_message(state) {
            dispatch(Chat.send_chat_message(state))
        },
        get_chats(state) {
            dispatch(Chat.get_chats(state))
        },
        change_chats(state) {
            dispatch(Chat.change_chats(state))
        },
        change_chat_bottom(state) {
            dispatch(Chat.change_chat_bottom(state))
        },
        get_chat_close(state) {
            dispatch(Chat.get_chat_close(state))
        },
        get_chat_open(state) {
            dispatch(Chat.get_chat_open(state))
        },

        change_chat_file_data(state) {
            dispatch(Chat.change_chat_file_data(state))
        },
        upload_chat_file(state) {
            dispatch(Chat.upload_chat_file(state))
        },
        change_chat_send_modal(state) {
            dispatch(Chat.change_chat_send_modal(state))
        },
        chat_kanryou(state) {
            dispatch(RequestDetailSaga.chat_kanryou(state))
        },
        send_chat_outai(state) {
            dispatch(Chat.send_chat_outai(state))
        },
        get_request_detail(state) {
            dispatch(Adjustment.get_request_detail(state))
        },
        set_process_flag(state) {
            dispatch(Chat.set_process_flag(state))
        },
        new_chat(state) {
            dispatch(Chat.new_chat(state))
        },
        get_chatlist(state) {
            dispatch(Keiyaku.get_chatlist(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Chats);
