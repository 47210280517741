
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as pm_cleaning_api from '../Api/pm_cleaning_api';
import * as tm_tatemono_api from '../Api/tm_tatemono_api';


// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
var moment = require('moment-timezone');
// import * as Customers from './Customers';

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}


export interface ResponsesListState {
  calendar_list: any;
  calendar_list_disp: any;
  calendar_list_form: {
    start: string,
    end: string,
    section_id: number,
    user_id: number,

  },
  calender_loading: boolean,
  calendar_tatemono_list: any,
  calendar_form: any
  // change_modalMatching:any
}

const initialState: ResponsesListState = {

  calendar_list: [],
  calendar_list_disp: [],


  calendar_list_form: {
    start: '',
    end: '',
    section_id: 0,
    user_id: 0,


  },
  calender_loading: false,
  calendar_tatemono_list: [],
  calendar_form: {
    tatemono_id: "100062",
    start: moment().startOf('month').format("YYYY/MM/DD"),
    end: moment().endOf('month').format("YYYY/MM/DD"),
  }
}

///action 
const actionCreator = actionCreatorFactory();





export const get_calendar_list = actionCreator<any>('GET_CALENDAR_LIST');
export const change_calendar_list = actionCreator<any>('CHANGE_CALENDAR_LIST');

export const change_calendar_list_disp = actionCreator<any>('CHANGE_CALENDAR_LIST_DISP');

// export const change_contract_follow_list_loading = actionCreator<boolean>('CHANGE_CONTRACT_FOLLOW_LIST_LOADING');

export const change_calendar_list_form = actionCreator<any>('CHANGE_CALENDAR_LIST_FORM');
export const change_calender_loading = actionCreator<any>('CHANGE_CALENDAR_LOADING');


export const get_calendar_tatemono_list = actionCreator<any>('GET_CALENDAR_TATEMONO_LIST');
export const change_calendar_tatemono_list = actionCreator<any>('CHANGE_CALENDAR_TATEMONO_LIST');

export const change_calendar_form = actionCreator<any>('CHANGE_CALENDAR_FORM');


// export const get_rent_m_contract_follows = actionCreator<any>('GET_RENT_M_CONTRACT_FOLLOWS');
// export const change_rent_m_contract_follows = actionCreator<any>("CHANGE_RENT_M_CONTRACT_FOLLOWS");


// export const search_contract_follow_list = actionCreator<any>('SEARCH_CONTRACT_FOLLOW_LIST');


// export const change_contract_follow_list_form = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_LIST_FORM');

// export const change_contract_follow_editer = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDITER');



export const calendarReducer = reducerWithInitialState(initialState)
  // .case(change_response_id, (state, payload) => ({ ...state, response_id: payload }))
  .case(change_calendar_list, (state, payload) => ({ ...state, calendar_list: payload }))
  .case(change_calendar_list_disp, (state, payload) => ({ ...state, calendar_list_disp: payload }))

  .case(change_calendar_list_form, (state, payload) => ({ ...state, calendar_list_form: payload }))
  .case(change_calender_loading, (state, payload) => ({ ...state, calender_loading: payload }))

  .case(change_calendar_tatemono_list, (state, payload) => ({ ...state, calendar_tatemono_list: payload }))

  .case(change_calendar_form, (state, payload) => ({ ...state, calendar_form: payload }))




// .case(change_contract_follow_list_loading, (state, payload) => ({ ...state, contract_follow_list_loading: payload }))

// // .case(change_contract_count, (state, payload) => ({ ...state, contract_count: payload }))
// .case(change_contract_follow_list_form, (state, payload) => ({ ...state, contract_follow_list_form: payload }))

// .case(change_rent_m_contract_follows, (state, payload) => ({ ...state, rent_m_contract_follows: payload }))

// .case(change_contract_follow_editer, (state, payload) => ({ ...state, contract_follow_editer: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const calendarSaga = [

  takeEvery('GET_CALENDAR_LIST', handle_get_calendar_list),

  takeEvery('GET_CALENDAR_TATEMONO_LIST', handle_get_calendar_tatemono_list),
  // takeEvery('GET_RENT_M_CONTRACT_FOLLOWS', handle_get_rent_m_contract_follows),

  // takeEvery('SEARCH_CONTRACT_FOLLOW_LIST', handle_search_contract_follow_list),

];
// export default responsesSaga;

function* handle_get_calendar_tatemono_list(action: any) {

  const { payload, error } = yield call(tm_tatemono_api.get_tatemono, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    yield put(change_calendar_tatemono_list(payload));
  } else {
    alert(error)
  }
}


function* handle_get_calendar_list(action: any) {

  yield put(change_calender_loading(true));

  // const state = yield select();　　// store.getState()と同じ
  // // 成功時アクション呼び出し

  // let calendar_list_form = state.calendar.calendar_list_form
  yield put(change_calendar_list([]));

  //  if(action.payload){
  //   // if (action.payload[0].sectionId) {
  //     action.payload.sectionId=action.payload.sectionId.value

  // alert("list")
  // }
  // const { payload, error } = yield call(RentResponsesApi.getlist, action.payload);


  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let calendar_form = state.calendar.calendar_form

  const { payload, error } = yield call(pm_cleaning_api.calendar, calendar_form);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {


    // payload.info
    let cal: any = []
    payload.patroled.map(function (value: any) {

      cal.push({
        // id: 5,
        hexColor: "4169e1",
        title: '清掃済',
        start: new Date(value),
        end: new Date(value),
        desc: '清掃済',
      })
    })

    payload.scheduled.map(function (value: any) {
      if (!payload.patroled.filter((a: any) => a === value)[0]) {
        cal.push({
          // id: 5,
          hexColor: "98fb98",
          title: '予定',
          start: new Date(value),
          end: new Date(value),
          desc: '予定',
        })
      }
    })

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)
    // alert(JSON.stringify(cal))
    // let payloads = payload.map(function (value: any) {
    //   if (moment(value.start).utc().format('YYYY/MM/DD HH:mm') !== moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm')) {
    //     value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
    //     value.end = new Date(moment(value.end).utc().add(30, 'm').format('YYYY/MM/DD HH:mm'))
    //   } else {
    //     value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD'))
    //     value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD'))
    //   }
    //   value.title = value.id === 0 ? "出勤" + value.title + "名" : value.title + "様(" + value.user_nickname + ")"
    //   return value
    // })

    // alert(payloads.length)
    yield put(change_calendar_list(cal));

    yield put(change_calendar_list_disp(cal));
    yield put(change_calender_loading(false));
    // yield put(search_contract_follow_list(payload));


  } else {
    alert("dd")
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}


