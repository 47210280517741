import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import './Css/Table.css';
import './Css/Form.css';
import * as Sms from './Saga/Sms';

let Bosyuflg;


export class RenderField extends React.Component {

    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};


class SmsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSetRoomName: false,

            setShubetu0: true,
            setShubetu2: true,
            setShubetu7: true,
            setShubetu21: true,
            setBoshuOnly0: true,
            introduction_rooms_modal: true,
            setKubun1: true,
            message: "",
            row: 1,
        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle_introduction_rooms_modal = this.toggle_introduction_rooms_modal.bind(this);

        this.share_open = this.share_open.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
    }





    onChange(e) {
        console.log(e.target.value);
        this.setState({ message: e.target.value, row: 4 });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.message !== "") {
            this.props.onSubmit({ message: this.state.message })
            this.setState({ message: "", row: 1 });
        }
    }

    componentDidMount() {
        this.ensureDataFetched();
    }

    componentDidUpdate() {
        this.ensureDataFetched();
    }

    ensureDataFetched() {
    }

    toggle_introduction_rooms_modal() {
        this.setState({ introduction_rooms_modal: !this.state.introduction_rooms_modal })
    }



    share_open() {

        if (this.props.share_open_flg == false) {
            this.props.get_shares(true);
        } else {
            this.props.get_shares(false);
        }
    }

    render() {

        const { handleDate, handlerequestMoveDate, handleSetsubiSubmit, handleSubmit } = this.props;

        return (
            <form onSubmit={this.handleSubmit}  >
                <div className="searchform float-left">
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={this.state.row}
                        defaultValue=""
                        style={{ width: "250px", marginTop: "0px", marginBottom: "0px" }}
                        onChange={this.onChange}
                        value={this.state.message}
                        margin="normal"
                        variant="outlined"
                    />
                </div>

                <button type="submit" className="btn btn-primary m-10" style={{ margin: "5px" }}>送信</button>

                {/* <button onClick={this.share_open} className="btn btn-info m-10" style={{ margin: "5px" }}>
                    <i className="fas fa-share-alt"></i>
                </button> */}

                {/* <i class="fas fa-times-circle smsclose" onClick={this.props.get_sms_close}></i> */}

            </form >
        );
    }
}


const mapStateToProps = state => {
    return {
        sms_send: state.sms.sms_send,
        share_open_flg: state.sms.share_open_flg,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_shares(state) {
            dispatch(Sms.get_shares(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsForm);