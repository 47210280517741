const authentication = require('../../../react-azure-adb2c2').default;






export const search_customers = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/search`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/search`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const newmessage_customers = (values: any) => {
  console.log("---------------------------");

  console.log(values);

  // let url = `http://localhost:5000/v1/m/customers/newmessage/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/newmessage/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const newlist_issei = (values: any) => {

  // let url = `http://localhost:5000/v1/sms/send/inbulks/newlist`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/send/inbulks/newlist`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const inbulks_byid = (values: any) => {

  // let url = `http://localhost:5000/v1/sms/send/inbulks/get_inbulk` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/send/inbulks/get_inbulk` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const update_customers = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/update`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/update`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const newmessagecount = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/newmessagecount/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/newmessagecount/` + values;
  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};