const authentication = require('../../react-azure-adb2c2').default;

export const get_shops = (values: any) => {

    const url = `https://homestationapi.azurewebsites.net/api/TmTenpoes/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => (res.json()))
        .catch(error => ({ error }));
}
