import React from 'react';

import styled from 'styled-components';

var moment = require('moment-timezone');


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Smsting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;font-weight:bold;text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;



// const Says = styled.div`
//     display: inline-block;
//     position: relative; 
//     margin: 0 0 0 60px;
//     padding: 10px;
//     width: 250px;
//     border-radius: 12px;
//     background: White;
//     margin-bottom: 2px;
//     text-align: left;
// `;




const Time = styled.div`
    width:100%;
    position: relative; 
    margin: 5 5 0 5px;
    bottom: 0;
    font-size:10px;
    color:#FFF;
    text-align:right;
`;



export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };
    }

    render() {
        return (
            <Balloon className="col-lg-12">
                <Smsting>

                    <List>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/sunflower.jpg" width="200" alt="" /></div>
                            <Title>サンフラワー店</Title>
                            <Detail>サンフラワー通り沿いの店舗です。ファミリー向け物件を得意とします。</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/bancho.jpg" width="200" alt="" /></div>
                            <Title>番町店</Title>
                            <Detail>香川大学本学部前のアパマンショップです。学生様のお部屋探しが得意です。</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/takamatsueki.jpg" width="200" alt="" /></div>
                            <Title>高松駅前店</Title>
                            <Detail>JR高松駅前のアパマンショップです。高松市全域をサポートします。　　　</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/park.jpg" width="200" alt="" /></div>
                            <Title>高松中央公園店</Title>
                            <Detail>高松市役所・丸亀町グリーン商店街に近い店舗です。高松中心部のお部屋探しを得意とします。</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/rainbow.jpg" width="200" alt="" /></div>
                            <Title>レインボーロード店</Title>
                            <Detail>レインボーロード沿いの店舗です。ファミリー向け物件を得意とします。</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                        <ListItem>
                            <div><img className="l_img" border="0" src="https://www.global-center.co.jp/claso/assets/img/shop/yashima.jpg" width="200" alt="" /></div>
                            <Title>高松東店</Title>
                            <Detail>高松市東部のアパマンショップです。高松市東部の単身・ファミリー物件を得意とします。</Detail>
                            <Menu1 className="col-xs-12">地図を見る</Menu1>
                            <Menu2 className="col-xs-12">問い合わせる</Menu2>
                        </ListItem>
                    </List>
                </Smsting>
                <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
            </Balloon>

        )
    }
}
