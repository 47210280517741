const authentication = require('../../../react-azure-adb2c2').default;





export const request_getfile = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/getfile`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/getfile`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const insert = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outaishosai/insert`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outaishosai/insert`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const update = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outaishosai/update`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outaishosai/update`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const Outai_update = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/update`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/update`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const Outai_complete = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/complete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/complete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const past_request = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/past_request`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/past_request`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_keiyaku = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/bykeiyakuno`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/bykeiyakuno`;


  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const Outai_delete = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/delete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/delete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_kouji = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/kouji/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/kouji/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const chat_kanryou = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/chats/chatkanryou_byid`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/chats/chatkanryou_byid`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const chatcomplet_byid = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/chats/chatcomplet_byid`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/chats/chatcomplet_byid`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};



export const get_request_excels = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/requestreport/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/requestreport` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};



export const request_file_delete = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/file/file_delete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/file_delete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_file = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/file/byoutai_no/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/byoutai_no/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const kouji_list_for_request = (values: any) => {
  // let url = `http://localhost:5000/v1/construction/list_for_request/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/construction/list_for_request/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const Outai_chat_plus = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/chat_plus`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/chat_plus`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const sms_insert = (values: any) => {
  // let url = `http://localhost:5000/v1/sms/sends/insert`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/sends/insert`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_sms_by_mobilenumber = (values: any) => {
  // let url = `http://localhost:5000/v1/sms/sends/get_by_mobilenumber/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/sms/sends/get_by_mobilenumber/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};
