import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Keiyaku from './Saga/Keiyaku';
import Search from "./Search";
import Grid from "./Grid";

interface Props {
    get_tatemono: any,
}



interface State {
    tatemono_id: any,
    name: any
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            tatemono_id: 0,
            name: ""
        }
        this.set_tatemono = this.set_tatemono.bind(this);
        this.onChange_kokyaku_name = this.onChange_kokyaku_name.bind(this);

    }

    set_tatemono(e: any) {
        console.log(e);
        this.setState({
            tatemono_id: e,
        });
    }

    onChange_kokyaku_name(e: any) {
        this.setState({
            name: e.target.value,
        });
    }




    render() {

        return (
            <div>
                <div className="item-outer col-lg-12">
                    <Card >
                        <CardContent >
                            <Search
                                onChange_kokyaku_name={this.onChange_kokyaku_name}
                                set_tatemono={this.set_tatemono}
                                name={this.state.name} />
                        </CardContent >
                    </Card >
                </div>
                <div className="item-outer col-lg-12">
                    <Card >
                        <CardContent >
                            <Grid
                                tatemono_id={this.state.tatemono_id}
                                name={this.state.name} />
                        </CardContent >
                    </Card >
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_tatemono(state: any) {
            dispatch(Keiyaku.get_tatemono(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




