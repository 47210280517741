const authentication = require('../../../react-azure-adb2c2').default;




export const responsedetail = (value: any) => {


    // let url = `https://localhost:44341/api/MailTemplateResponse/`+value.id+'?rentResponseId='+value.rentResponseId;
    let url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateResponse/` + value.id + '?rentResponseId=' + value.rentResponseId;
    // alert(url)
    // const url = process.env.REACT_APP_API_BASE_URL+`/api/MailTemplates/`+value
    const token = authentication.getAccessToken();
    //    alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (value: any) => {


    // let url = `https://localhost:44341/api/MailTemplateResponse/`+value+'?rentResponseId=89389&tatemonoId=10&roomId=1';
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateResponse/` + value + '?rentResponseId=89389&tatemonoId=10&roomId=1';
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const rooms = (value: any) => {


    // let url = `https://localhost:44341/api/MailTemplateRooms/`+value.id+'?rentResponseId='+value.rentResponseId+'&tatemonoId='+value.tatemonoId+'&roomId='+value.roomId+'';
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateRooms/` + value.id + '?rentResponseId=' + value.rentResponseId + '&tatemonoId=' + value.tatemonoId + '&roomId=' + value.roomId + '';
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const list = (value: any) => {


    // let url = `https://localhost:44341/api/MailTemplates`;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates2/`+value.id;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const insert = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletetemplate = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insertcategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
export const updatecategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value.id;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletecategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

// export const get_file = (Id: number) => {
//     const token = authentication.getAccessToken();

//     // let url = "https://localhost:44341/api/c/MailFileDownloadApi/"+Id;
//     let url = "http://homestationapi.azurewebsites.net/api/c/MailFileDownloadApi/"+Id;
//     return   fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]])}).then(response => response.blob())
//     .then(payload => ({ payload }))
//             .catch(error => ({ error }));
//     // .then(blob => {

//     //     window.URL.createObjectURL(blob)
//     //     // alert(window.URL.createObjectURL(blob))
//     //     // let anchor = document.createElement("a");
//     //     // anchor.href = window.URL.createObjectURL(blob);
//     //     // anchor.click();
//     //    })



//     //     return  fetch(url, {
//     //     method: 'get',
//     //     // body: JSON.stringify(search),

//     //     headers: { 'Authorization': 'Bearer ' + token},

//     // }).then(response => response.blob())
//     // .then(blob => ({blob}))
//     //     .catch(error => ({ error }));
// };












// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/MailAllReceives/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



