import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as Keiyaku from './Saga/Keiyaku';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import Button from '@material-ui/core/Button';
import format from "date-fns/format";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');




const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


interface Props {
    Tatemono: any,
    get_customers: any,
    set_tatemono: any,
    get_tatemono: any,
    del_tatemono: any,
    onChange_kokyaku_name: any,
    name: any
}

interface State {
    date1: Date,
    date2: Date,
    tatemono_id: Number,
    tatemono_name: string,
    old_check: boolean,
}


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

const tatemono = [{ Tatemono_Name: "" }]

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        props.get_tatemono();
        this.state = {
            date1: new Date(),
            date2: new Date(),
            tatemono_id: 0,
            tatemono_name: "",
            old_check: false
        }
        this.onChangeDate1 = this.onChangeDate1.bind(this);
        this.onChangeDate2 = this.onChangeDate2.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.tatemonochange = this.tatemonochange.bind(this);
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.search = this.search.bind(this);
    }


    onChangeDate1(date: any) {
        this.setState({
            date1: new Date(date),
        });
    }
    onChangeDate2(date: any) {
        this.setState({
            date2: new Date(date),
        });
    }
    onChangeTatemono(event: any, values: any) {
        console.log(event.target.value);
        if (values) {
            this.setState({
                tatemono_id: values.Tatemono_Id,
            });
        } else {
            this.setState({
                tatemono_id: 0,
            });
        }
    }

    tatemonochange(event: any) {

        this.setState({
            tatemono_name: event.target.value
        });

    }

    search() {

        this.props.set_tatemono(this.state.tatemono_id);
        var date = {
            date1: this.state.date1,
            date2: this.state.date2,
            tatemono_id: this.state.tatemono_id,
            tatemono_name: this.state.tatemono_name,
            old_check: this.state.old_check,
            name: this.props.name
        }
        this.props.get_customers(date);
    }



    onChangeCheck = (e: any) => {
        this.setState({
            old_check: e.target.checked
        });
        if (e.target.checked == false) {
            this.setState({
                date1: new Date(),
                date2: new Date(),
            });
        }
    };

    render() {

        return (
            <div>
                <Autocomplete
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.search();
                        }
                    }}
                    id="combo-box-demo"
                    options={this.props.Tatemono}
                    getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"} style={{ width: 500, float: "left", marginTop: 12 }}
                    onChange={this.onChangeTatemono}
                    renderInput={(params) => <TextField {...params} label="建物検索" onChange={this.tatemonochange} value={this.state.tatemono_name} />}
                />

                <TextField
                    style={{ width: 200, marginLeft: 30, marginTop: 10 }}
                    id="standard-basic"
                    autoComplete='off'
                    onChange={this.props.onChange_kokyaku_name}
                    label="顧客名"
                />


                <FormControlLabel
                    onChange={this.onChangeCheck}
                    style={{ marginLeft: 100 }}
                    control={<Checkbox
                        color="primary"
                        name="tousha_hoshu" />}
                    label="過去の契約も検索する"
                />



                {this.state.old_check ?
                    <span>
                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                            <KeyboardDatePicker

                                variant="inline"
                                margin="normal"
                                id="date-picker-dialog"
                                label="契約期間"
                                format="yyyy/MM/dd"
                                autoOk={true}
                                style={{ width: 130 }}
                                value={this.state.date1}
                                onChange={this.onChangeDate1}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>



                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                            <KeyboardDatePicker
                                variant="inline"
                                margin="normal"
                                id="date-picker-dialog"
                                label="契約期間"
                                format="yyyy/MM/dd"
                                autoOk={true}
                                style={{ width: 130 }}
                                value={this.state.date2}
                                onChange={this.onChangeDate2}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>

                    </span> : ""}
                <Button
                    style={{
                        marginLeft: "50px",
                        marginTop: 30
                    }}
                    onClick={this.search}
                    variant="outlined" color="primary">
                    検索</Button>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        Tatemono: state.Keiyaku.Tatemono,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_customers(state: any) {
            dispatch(Keiyaku.get_customers(state))
        },
        get_tatemono(state: any) {
            dispatch(Keiyaku.get_tatemono(state))
        },
        del_tatemono(state: any) {
            dispatch(Keiyaku.del_tatemono(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




