import React from 'react';

import styled from 'styled-components';


var moment = require('moment-timezone');

const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Smsting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    width: 250px;
    border-radius: 12px;
    background: White;
    margin-bottom: 2px;
    text-align: left;
`;

const Description = styled.div`
    font-size:10px;
`;
const List = styled.div`
    padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };
    }

    render() {
        return (
            <Balloon className="col-lg-12">
                <Smsting>
                    <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
                    <Says>
                        <Description>ご希望の間取りを選択してください。</Description>
                        <List className="row">
                            <div className="col-lg-5"><img border="0" src="https://www.global-center.co.jp/claso/common/img/room/2292/11/8/1" width="60" alt="" /></div>
                            <div className="col-lg-7" style={{ fontWeight: "bold" }}>1K～1LDK</div>
                        </List>
                        <List className="row">
                            <div className="col-lg-5"><img border="0" src="https://www.global-center.co.jp/claso/common/img/room/2848596/80703151/8/1" width="50" alt="" /></div>
                            <div className="col-lg-7" style={{ fontWeight: "bold" }}>2K～2LDK</div>
                        </List>
                        <List className="row">
                            <div className="col-lg-5"><img border="0" src="https://www.global-center.co.jp/claso/common/img/room/2844307/78542547/8/1" style={{ maxHeight: 50 }} alt="" /></div>
                            <div className="col-lg-7" style={{ fontWeight: "bold" }}>3K～</div>
                        </List>
                    </Says>
                </Smsting>
            </Balloon>

        )
    }
}
