import React, { Component } from 'react';
// import firebase from "firebase";
import firebase from "firebase/app"
// import 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
import Fab from '@material-ui/core/Fab';
// import { firebaseDb } from '../../Store/FirebaseStore';
import './Css/Sms.css';
import SmsRightText from './SmsRightText';
import SmsRightImg from './SmsRightImg';
import SmsRightType1 from './SmsRightType1';
import SmsRightType2 from './SmsRightType2';
import SmsRightType3 from './SmsRightType3';
import SmsRightType4 from './SmsRightType4';
import SmsRightType5 from './SmsRightType5';
import SmsLeftText from './SmsLeftText';
import SmsLeftImg from './SmsLeftImg';
import SmsTime from './SmsTime';
import SmsForm from './SmsForm';
import SmsRightFile from './SmsRightFile';
import { smsDB } from '../../Store/FirebaseStore';
import throttle from 'lodash.throttle';
import * as Sms from './Saga/Sms';
let unsub
var smses
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }



var timestamp = ""
class Smses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            reflesh: false

        };
        console.log(this.props.smses)
        smses = this.props.smses


    }

    format(value) {
        if (value.communication_status === 1) {
            return <SmsRightText msg={value} />
        } else if (value.communication_status === 2) {
            return <SmsLeftText msg={value} />
        } else {
            return "";
        }
    }


    async componentDidMount() {


        this.props.change_sms_bottom(true);


        if (unsub) {
            // alert("exist")
            unsub()
        }


        this.props.smses.map(function (value) {
            timestamp = value.timestamp > timestamp ? value.timestamp : timestamp
        })
        // let times = Math.floor(new Date().getTime() / 1000)
        // alert(timestamp)
        // alert(times)
        // var db_response = firebase.firestore();

        // unsub = smsDB.collection("sms")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        //             // alert(timestamp)
        //             console.log("-------------------------------------------")
        //             console.log(change.doc.data())
        //             console.log("-------------------------------------------")
        //             this.props.change_smses(smses.concat(change.doc.data()))
        //             this.props.change_sms_bottom(true);
        //             // this.setState({ reflesh: !this.state.reflesh })
        //         }, this)
        //     });

    }
    componentDidUpdate() {
        smses = this.props.smses

    }
    componentWillUnmount() {
        // alert("unmount")


    }
    render() {

        return (


            <div className="sms-bc" id="sms-bc">
                {/* {this.state.messages} */}
                {this.props.smses.map(function (value) {

                    //   return value.eventType
                    //   alert()

                    return this.format(value)

                }, this)}
                {/* {timestamp} */}
            </div>





        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        sms_bottom: state.sms.sms_bottom,
        sms_user_details: state.sms.sms_user_details,
        smses: state.sms.smses,
        sms_open_flg: state.sms.sms_open_flg,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_sms_message(state) {
            dispatch(Sms.send_sms_message(state))
        },

        change_smses(state) {
            dispatch(Sms.change_smses(state))
        },
        change_sms_bottom(state) {
            dispatch(Sms.change_sms_bottom(state))
        },
        get_sms_close(state) {
            dispatch(Sms.get_sms_close(state))
        },
        get_sms_open(state) {
            dispatch(Sms.get_sms_open(state))
        },


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Smses);
