// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../react-azure-adb2c2').default;
export const UsersLIst = (values: any) => {
    const url = `https://homestationapi.azurewebsites.net/api/users/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => (res.json()))
        .catch(error => ({ error }));
}
export const UsersDetails = (values: any) => {
    const url = `https://homestationapi.azurewebsites.net/api/c/userdetails/`;
    // const url = ` https://localhost:44341/api/c/userdetails/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}