import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { RouteComponentProps } from 'react-router-dom'
import { Route } from 'react-router';
import Layout from './Components/Layout/LayoutComponent';
// import Adjustment from './Components/Adjustment/Index';
import Demo from './Components/Adjustment/demoIndex';
import DemoDetail from './Components/RequestDetail/demoIndex';
// import RequestDetail from './Components/RequestDetail/Index';

// import RentMoney from './Components/RentMoney/Index';

// import Vacancy from './Components/Vacancy/Index';
// import Keiyaku from './Components/Keiyaku/Index';
// import MessageList from './Components/MessageList/Index';
// import ContractsList from './Components/ContractsList/Index';

// import CustomerLink from './Components/CustomerLink/Index';
// import Calendar from './Components/Calendar/Index';

// import Board from './Components/Board/Index';

// import Video from './Components/Board/Video';

// import Scenario from './Components/Scenario/Index';

// import KizuCheckList from './Components/KizuCheckList/Index';
// import KizuDetail from './Components/KizuDetail/Index';

// import AccountingListComponent from './Components/Accounting/AccountingListComponent';

// import Rent from './Components/Rent/RentComponent';
// import ComingComponent from './Components/Coming/ComingComponent';

// import DetailComponent from './Components/Detail/DetailComponent';
interface Props extends RouteComponentProps<{}> {
  // 他の型定義

}

const App: React.FC = () => {

  return (

    <Layout >
      {/* <Route path='/RentMoney' component={RentMoney} /> */}

      {/* <Route exact path='/Board' component={Board} />
      <Route exact path='/Adjustment/:outai_no' component={Adjustment} />
      <Route exact path='/Adjustment' component={Adjustment} />
      <Route exact path='/RequestDetail/:outai_no' component={RequestDetail} />
      <Route exact path='/' component={Adjustment} /> */}
      <Route exact path='/' component={Demo} />
      <Route exact path='/demo/:outai_no' component={DemoDetail} />
      {/* <Route exact path='/Vacancy' component={Vacancy} />
      <Route exact path='/Keiyaku' component={Keiyaku} />
      <Route exact path='/MessageList' component={MessageList} />
      <Route exact path='/ContractsList' component={ContractsList} />
      <Route exact path='/CustomerLink' component={CustomerLink} />
      <Route exact path='/Video' component={Video} />
      <Route exact path='/Calendar' component={Calendar} />
      <Route exact path='/Scenario' component={Scenario} />
      <Route exact path='/KizuCheckList' component={KizuCheckList} />
      <Route exact path='/KizuDetail' component={KizuDetail} /> */}

    </Layout>


  );
}
export default App;
