import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Keiyaku from './Saga/Keiyaku';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Kihon from "./Kihon";
import TextField from '@material-ui/core/TextField';
import { Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import TemplateKanriCategoryGrid from "./TemplateKanriCategoryGrid";
import TemplateKanriDetailGrid from "./TemplateKanriDetailGrid";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import moment from 'moment'

const request = require('request');


interface Props {
    insert_template_category: any,
    insert_template: any,
    update_template: any,
    user_details: any,
    get_template_category_list: any,
    get_template: any,
    template: any
}

interface State {
    new_category_open: boolean,
    template_open: boolean,
    new_template_open: boolean,
    new_category_title: any,
    choise_category_title: any,
    choise_category_id: any,
    choise_template_title: any
    choise_template_name: any
    choise_template_contents: any
}


class CustomerList extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            new_category_open: false,
            template_open: false,
            new_template_open: false,
            new_category_title: "",
            choise_category_title: "",
            choise_category_id: 0,
            choise_template_title: "",
            choise_template_name: "",
            choise_template_contents: ""

        }

        this.new_category_open = this.new_category_open.bind(this);
        this.template_open = this.template_open.bind(this);
        this.new_template_open = this.new_template_open.bind(this);


        this.title_change = this.title_change.bind(this);
        this.category_save = this.category_save.bind(this);
        this.template_save = this.template_save.bind(this);
        this.new_template_save = this.new_template_save.bind(this);

        this.choise_category = this.choise_category.bind(this);

    }


    choise_category(e: any) {
        this.setState({
            choise_category_title: e.title,
            choise_category_id: e.id
        })

    }

    title_change(e: any) {
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    }

    category_save() {
        var data = {
            category_title: this.state.new_category_title,
            created_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
            created_user: this.props.user_details.id
        }
        this.props.insert_template_category(data);

        setTimeout(() => {
            alert("登録しました。");
            this.props.get_template_category_list();
            this.setState({ new_category_open: !this.state.new_category_open })

        }, 1000)
    }

    template_save() {
        var data = {
            id: this.props.template[0].id,
            template_name: this.state.choise_template_name,
            template_title: this.state.choise_template_title,
            template_contents: this.state.choise_template_contents,
            updated_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
            updated_user: this.props.user_details.id
        }
        this.props.update_template(data);

        setTimeout(() => {
            alert("保存しました。");
            this.props.get_template(this.state.choise_category_id);
            this.setState({ template_open: false })

        }, 1000)
    }

    new_template_save() {
        var data = {
            news_template_category_id: this.state.choise_category_id,
            template_name: this.state.choise_template_name,
            template_title: this.state.choise_template_title,
            template_contents: this.state.choise_template_contents,
            created_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
            created_user: this.props.user_details.id
        }
        this.props.insert_template(data);

        setTimeout(() => {
            alert("新規作成しました。");
            this.props.get_template(this.state.choise_category_id);
            this.setState({ new_template_open: false })

        }, 1000)
    }

    new_category_open() {
        this.setState({ new_category_open: !this.state.new_category_open })
    }

    template_open() {

        // テンプレートを開いた際にタイトルとか内容を取る
        if (this.state.template_open == false) {
            this.setState({
                choise_template_title: this.props.template[0].template_title,
                choise_template_name: this.props.template[0].template_name,
                choise_template_contents: this.props.template[0].template_contents
            })
        }

        this.setState({ template_open: !this.state.template_open })
    }

    new_template_open() {
        this.setState({ new_template_open: !this.state.new_template_open })

    }

    render() {
        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <Card className="col-lg-12" square={true} style={{ padding: 5 }}>
                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                        <div>
                            <Row>
                                <h5>テンプレート カテゴリー一覧</h5>

                                <Button style={{ marginLeft: 20 }}
                                    onClick={this.new_category_open}
                                    color="primary"
                                    variant="outlined">
                                    <i className="fas fa-plus"></i>カテゴリーを追加する
                                    </Button>
                            </Row>
                        </div>

                        <div>
                            <TemplateKanriCategoryGrid choise_category={this.choise_category} />
                        </div>

                    </CardContent>
                </Card>

                {this.state.choise_category_title ?
                    <Card className="col-lg-12" square={true} style={{ padding: 5, marginTop: 10 }}>
                        <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">
                            <Row>
                                <h5>{this.state.choise_category_title} テンプレート一覧</h5>

                                <Button style={{ marginLeft: 20 }}
                                    onClick={this.new_template_open}
                                    color="primary"
                                    variant="outlined">
                                    <i className="fas fa-plus"></i>テンプレートを追加する
                                    </Button>
                            </Row>
                            <TemplateKanriDetailGrid
                                choise_category_id={this.state.choise_category_id}
                                template_open={this.template_open} />

                        </CardContent>
                    </Card> : ""}




                {/* カテゴリー作成のモーダル */}
                < Modal isOpen={this.state.new_category_open} toggle={this.new_category_open} fade={false}>
                    <ModalHeader toggle={this.new_category_open}>カテゴリー追加</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TextField
                                id="new_category_title"
                                label="カテゴリー名"
                                fullWidth
                                variant="outlined"
                                onChange={this.title_change}
                                style={{ marginTop: 10 }}
                            />

                            <div>
                                <Button style={{ marginLeft: 400 }}
                                    onClick={this.category_save}
                                    color="primary"
                                    variant="outlined">
                                    追加
                                    </Button>
                            </div>
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

                {/* テンプレート編集のモーダル */}
                < Modal isOpen={this.state.template_open} toggle={this.template_open} fade={false}>
                    <ModalHeader toggle={this.template_open}>テンプレートを編集</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TextField
                                id="choise_template_name"
                                label="テンプレート名"
                                fullWidth
                                variant="outlined"
                                defaultValue={this.state.choise_template_name}
                                onChange={this.title_change}
                                style={{ marginTop: 10 }}
                            />

                            <TextField
                                id="choise_template_title"
                                label="タイトル"
                                fullWidth
                                variant="outlined"
                                defaultValue={this.state.choise_template_title}
                                onChange={this.title_change}
                                style={{ marginTop: 10 }}
                            />
                            ※SMS・チャットで使用した場合、タイトルはメッセージに入りません

                            <TextField
                                id="choise_template_contents"
                                label="通知内容"
                                fullWidth
                                defaultValue={this.state.choise_template_contents}
                                multiline
                                onChange={this.title_change}
                                rows={15}
                                variant="outlined"
                                style={{ marginTop: "10px" }}
                            />

                            <div>
                                <Button style={{ marginLeft: 400 }}
                                    onClick={this.template_save}
                                    color="primary"
                                    variant="outlined">
                                    保存
                                    </Button>
                            </div>
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

                {/* テンプレート作成のモーダル */}
                < Modal isOpen={this.state.new_template_open} toggle={this.new_template_open} fade={false}>
                    <ModalHeader toggle={this.new_template_open}>テンプレートを編集</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TextField
                                id="choise_template_name"
                                label="テンプレート名"
                                fullWidth
                                variant="outlined"
                                onChange={this.title_change}
                                style={{ marginTop: 10 }}
                            />

                            <TextField
                                id="choise_template_title"
                                label="タイトル"
                                fullWidth
                                variant="outlined"
                                onChange={this.title_change}
                                style={{ marginTop: 10 }}
                            />
                            ※SMS・チャットで使用した場合、タイトルはメッセージに入りません


                            <TextField
                                id="choise_template_contents"
                                label="通知内容"
                                fullWidth
                                multiline
                                onChange={this.title_change}
                                rows={15}
                                variant="outlined"
                                style={{ marginTop: "10px" }}
                            />

                            <div>
                                <Button style={{ marginLeft: 400 }}
                                    onClick={this.new_template_save}
                                    color="primary"
                                    variant="outlined">
                                    作成
                                    </Button>
                            </div>
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        template: state.Keiyaku.template,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        insert_template_category(state: any) {
            dispatch(Keiyaku.insert_template_category(state))
        },
        insert_template(state: any) {
            dispatch(Keiyaku.insert_template(state))
        },
        update_template(state: any) {
            dispatch(Keiyaku.update_template(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




