const authentication = require('../../../react-azure-adb2c2').default;







export const list = (mailId: number) => {

    // let url = "https://localhost:44341/api/MailFiles/"+mailId;
    let url = process.env.REACT_APP_API_BASE_URL + `/api/MailFiles/` + mailId;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const get_file = (Id: number) => {
    const token = authentication.getAccessToken();

    // let url = "https://localhost:44341/api/c/MailFileDownloadApi/"+Id;
    let url = process.env.REACT_APP_API_BASE_URL + `/api/c/MailFileDownloadApi/` + Id;
    return fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]]) }).then(response => response.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
    // .then(blob => {

    //     window.URL.createObjectURL(blob)
    //     // alert(window.URL.createObjectURL(blob))
    //     // let anchor = document.createElement("a");
    //     // anchor.href = window.URL.createObjectURL(blob);
    //     // anchor.click();
    //    })



    //     return  fetch(url, {
    //     method: 'get',
    //     // body: JSON.stringify(search),

    //     headers: { 'Authorization': 'Bearer ' + token},

    // }).then(response => response.blob())
    // .then(blob => ({blob}))
    //     .catch(error => ({ error }));
};












// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/MailAllReceives/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



