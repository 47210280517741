import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import './Css/Keiyaku.css';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import * as Keiyaku from './Saga/Keiyaku';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>
        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'template_name', name: 'テンプレート名', width: 300, resizable: true },
    { key: 'template_title', name: 'タイトル', width: 300, resizable: true },
    { key: 'template_contents', name: '内容', width: 300, resizable: true },
    { key: 'delete', name: '', width: 100, resizable: true },
  ]

const Delete = ({ column, props, rowIndex, template_delete }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <i onClick={() => template_delete(rowIndex)} style={{ cursor: "pointer" }} className="fas fa-times-circle" />
  </Cell>


const Base = ({ column, props, rowIndex, template_open }) =>
  <div style={{ cursor: "pointer" }} onClick={() => template_open(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      contentstoggle: false,
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.template_open = this.template_open.bind(this);
    this.template_delete = this.template_delete.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

  template_open(index) {
    this.props.get_template_detail(this.props.template_list[index].id);
    setTimeout(() => {
      this.props.template_open();
    }, 500)
  }

  template_delete(index) {
    var result = window.confirm('テンプレートを削除します。データは戻りません。よろしいですか？');
    if (result) {
      var data = {
        id: this.props.template_list[index].id,
        is_deleted: 1,
        updated_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
        updated_user: this.props.user_details.id
      }
      this.props.update_template(data);

      setTimeout(() => {
        alert("削除しました。");
        this.props.get_template(this.props.choise_category_id);

      }, 1000)

    }
  }


  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.template_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }


  render() {

    return (
      <div id="Keiyaku">
        <Table
          rowHeight={40}
          rowsCount={this.props.template_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 260}
          height={300}
          headerHeight={30}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (
                value.key === "delete" ? <Delete column={value} props={this.props.template_list} rowIndex={rowIndex} template_delete={this.template_delete}></Delete>
                  : <Base column={value} props={this.props.template_list} rowIndex={rowIndex} template_open={this.template_open}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    template_list: state.Keiyaku.template_list,
    user_details: state.masterReducer.user_details,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_detail(state) {
      dispatch(Keiyaku.get_detail(state))
    },
    get_template_detail(state) {
      dispatch(Keiyaku.get_template_detail(state))
    },
    update_template(state) {
      dispatch(Keiyaku.update_template(state))
    },
    get_template(state) {
      dispatch(Keiyaku.get_template(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);