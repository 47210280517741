const authentication = require('../../../react-azure-adb2c2').default;



export const get_notlinks = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_links = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/linklist`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/linklist`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_links_search = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/linklistsearch`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/linklistsearch`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const linking = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/linking`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/linking`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const linking_same = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/linking_same`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/linking_same`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const linking_notsame = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/linking_notsame`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/linking_notsame`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_familys = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/bytatemonoid_roomid_family`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/bytatemonoid_roomid_family`;


  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const link_delete = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/link_delete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/link_delete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const devices_delete = (values: any) => {
  // let url = `http://localhost:5000/v1/room/support/m/devices/devices_delete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/m/devices/devices_delete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};
