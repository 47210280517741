const authentication = require('../../../react-azure-adb2c2').default;




export const request_list = (values: any) => {

  // が
  // let url = `http://localhost:5000/v1/t/outai/list/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/list/` + values;


  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const request_search = (values: any) => {

  // let url = `http://localhost:5000/v1/t/outai/search`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/search`;


  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const request_syubetsu = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/syubetsu`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/syubetsu`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const request_detail = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/detail`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/detail`;
  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const importance = (values: any) => {
  console.log(values);
  // let url = `http://localhost:5000/v1/t/outai/importance`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/importance`;
  const token = authentication.getAccessToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_tatemono = (values: any) => {
  // let url = `http://localhost:5000/v1/tm/tatemono/kanrilist`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/tatemono/kanrilist`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_room = (values: any) => {
  // let url = `http://localhost:5000/v1/tm/room/list2/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/room/list2/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_parking = (values: any) => {
  // let url = `http://localhost:5000/v1/tm/parking/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/parking/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_parkingarea = (values: any) => {
  // let url = `http://localhost:5000/v1/tm/parkingarea/list2/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/parkingarea/list2/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_gyosha = (values: any) => {
  // let url = `http://localhost:5000/v1/t/gyosha/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/gyosha/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_outaisub = (values: any) => {
  // let url = `http://localhost:5000/v1/tm/outai/sub/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/outai/sub/list`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const new_request = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/new`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/new`;


  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};




export const upload_file = (values: any) => {

  // let url = `http://localhost:5000/v1/t/outai/file/upload_file/` + values.Outai_No + "/" + values.OutaiShosai_No + "/" + values.file_no + "/" + values.uploaded_user_id;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/upload_file/` + values.Outai_No + "/" + values.OutaiShosai_No + "/" + values.file_no + "/" + values.uploaded_user_id;

  const token = authentication.getAccessToken();
  var formData = new FormData();
  formData.append("files", values.files);
  console.log(formData);
  return fetch(url, {
    method: 'post',
    body: formData,
    headers: { 'Authorization': 'Bearer ' + token },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const update_file = (values: any) => {

  // let url = `http://localhost:5000/v1/t/outai/file/update_file/` + values.Outai_No + "/" + values.OutaiShosai_No + "/" + values.file_no + "/" + values.uploaded_user_id;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/update_file/` + values.Outai_No + "/" + values.OutaiShosai_No + "/" + values.file_no + "/" + values.uploaded_user_id;

  const token = authentication.getAccessToken();
  var formData = new FormData();
  formData.append("files", values.files);
  console.log(formData);
  return fetch(url, {
    method: 'post',
    body: formData,
    headers: { 'Authorization': 'Bearer ' + token },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const download_file = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/file/download_file/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/download_file/` + values;
  const token = authentication.getAccessToken();
  return fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]]) }).then(response => response.blob())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const download_img = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/file/download_img/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/file/download_img/` + values;
  const token = authentication.getAccessToken();
  return fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]]) }).then(response => response.blob())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};



export const get_customers = (values: any) => {
  // let url = `http://localhost:5000/v1/m/customers/bytatemonoid_roomid`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/m/customers/bytatemonoid_roomid`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_kaketsuke_kojin = (values: any) => {
  // let url = `http://localhost:5000/v1/t/kaketsuke/search`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/kaketsuke/search`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const notset_contents = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/list/contents/notset_contents/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/list/contents/notset_contents/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const inset_contents = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/list/custom/set_contents/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/list/custom/set_contents/` + values;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const insert_contents = (values: any) => {
  // let url = `http://localhost:5000/v1/t/outai/list/custom/insert`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/list/custom/insert`;
  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};




export const complete_percent = (values: any) => {

  // let url = `http://localhost:5000/v1/t/outai/complete_percent`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/complete_percent`;


  const token = authentication.getAccessToken();
  alert(token)
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};
