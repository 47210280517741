// Initialize Firebase
export default {
  apiKey: "AIzaSyDyimE4KzvRA1R7TvgzXW-9py23cs9vaN8",
  authDomain: "roomsupport-fbd5b.firebaseapp.com",
  databaseURL: "https://roomsupport-fbd5b.firebaseio.com",
  projectId: "roomsupport-fbd5b",
  storageBucket: "roomsupport-fbd5b.appspot.com",
  messagingSenderId: "446502001716"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyDyimE4KzvRA1R7TvgzXW-9py23cs9vaN8",
//   authDomain: "roomsupport-fbd5b.firebaseapp.com",
//   projectId: "roomsupport-fbd5b",
//   storageBucket: "roomsupport-fbd5b.appspot.com",
//   messagingSenderId: "446502001716",
//   appId: "1:446502001716:web:314bec775248080a7d7e61",
//   measurementId: "G-B5R1DFZX1M"
// };

