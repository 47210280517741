// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
import * as Msal from 'msal';
import React from 'react';

const logger = new Msal.Logger(loggerCallback, { level: Msal.LogLevel.Warning });
const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: []
}
var appConfig = {
  instance: null,
  tenant: null,
  signInPolicy: null,
  resetPolicy: null,
  applicationId: null,
  cacheLocation: null,
  redirectUri: null,
  postLogoutRedirectUri: null
};

function loggerCallback(logLevel, message, piiLoggingEnabled) {
  console.log(message);
}

function authCallback(response, error) {
  console.log(response, error, "れすぽんす！")
  if (!error) {
    acquireToken()
  }
}

function redirect() {

  const localMsalApp = window.msal;
  localMsalApp.authority = `https://rentglobalcenter.b2clogin.com/${appConfig.tenant}/${appConfig.resetPolicy}`;
  // localMsalApp.authority = `https://login.microsoftonline.com/tfp/${appConfig.tenant}/${appConfig.resetPolicy}`;
  acquireToken();
}

async function acquireToken(successCallback) {
  const localMsalApp = window.msal;
  const user = localMsalApp.getAccount(state.scopes);
  //   const user = localMsalApp.getUser(state.scopes);
  console.log('ゆうざあ', user)
  if (!user) {
    localMsalApp.loginRedirect(state.scopes);
  }
  else {
    // const result = await localMsalApp.acquireTokenSilent(state.scopes);
    // const accessTokenRequest = {

    //   scopes: state.scopes,
    //   account: user,
    // };

    // const accessTokenRequest = {

    //   scopes: ["openid"],
    //   account: user,
    // };
    const accessTokenRequest = {
      scopes: appConfig.scopes,
      account: user,
    };

    // const accessTokenRequest = {
    //   scopes: [`openid`],
    //   account: user,
    // };


    // alert(JSON.stringify(accessTokenRequest))


    localMsalApp.acquireTokenSilent(accessTokenRequest).then(token => {
      console.log('get token');
      console.log(token);
    });


    const token = await localMsalApp.acquireTokenSilent(accessTokenRequest)
    // alert(JSON.stringify(token.accessToken))
    console.log(token)
    state.accessToken = token.accessToken;
    if (state.launchApp) {
      state.launchApp();
    }
    if (successCallback) {
      successCallback();
    }
  }
}

const authentication = {
  initialize: (config) => {
    appConfig = config;

    const instance = config.instance ? config.instance : 'https://login.microsoftonline.com/tfp/';

    const authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    // var validateAuthority = instance.indexOf('b2clogin.com') === -1;
    let scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      console.log('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens');
      state.stopLoopingRedirect = true;
    }
    state.scopes = scopes;
    const msalConfig = {
      auth: {
        logger: logger,
        authority: authority,
        clientId: config.applicationId,
        cacheLocation: config.cacheLocation,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        redirectUri: config.redirectUri,
        validateAuthority: false,
        scopes: scopes,
      }
    };
    const msalInstance = new Msal.UserAgentApplication(msalConfig)
    msalInstance.handleRedirectCallback((error, response) => {
      authCallback(response, error);
    });
  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      if (!state.stopLoopingRedirect) {
        acquireToken();
      }
    }
  },
  required: (WrappedComponent, renderLoading) => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      componentWillMount() {
        acquireToken(() => {
          this.setState({
            signedIn: true
          });
        });
      }

      render() {
        if (this.state.signedIn) {
          return (<WrappedComponent {...this.props} />);
        }
        return typeof renderLoading === 'function' ? renderLoading() : null;
      }
    };
  },
  signOut: async () => {
    console.log(appConfig)
    const config = {
      auth: {
        logger: logger,
        authority: `${appConfig.instance}${appConfig.tenant}/${appConfig.signInPolicy}`,
        clientId: appConfig.applicationId,
        cacheLocation: appConfig.cacheLocation,
        postLogoutRedirectUri: appConfig.redirectUri,
        redirectUri: appConfig.redirectUri,
        validateAuthority: false,
        // postLogoutRedirectUri: `https://homestation.b2clogin.com/${appConfig.tenant}/${appConfig.resetPolicy}`,
        scopes: appConfig.scopes,
      }
    };


    const myMsal = new Msal.UserAgentApplication(config);

    myMsal.logout(state.scopes);
  },
  getAccessToken: () => state.accessToken
}

export default authentication

