import React, { Component } from 'react';

import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { firebaseDb } from '../../Store/FirebaseStore';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import './Css/Sms.css';
import SmsRightText from './SmsRightText';
import SmsRightImg from './SmsRightImg';
import SmsRightType1 from './SmsRightType1';
import SmsRightType2 from './SmsRightType2';
import SmsRightType3 from './SmsRightType3';
import SmsRightType4 from './SmsRightType4';
import SmsRightType5 from './SmsRightType5';
import SmsLeftText from './SmsLeftText';
import SmsLeftImg from './SmsLeftImg';
import SmsTime from './SmsTime';
import SmsForm from './SmsForm';
import MessageShare from '../MessageShare/Index';
import throttle from 'lodash.throttle';
import * as Sms from './Saga/Sms';
import SmsDisp from './SmsDisp';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import * as MessageList from '../MessageList/Saga/MessageList';
import { smsDB } from '../../Store/FirebaseStore';

var moment = require('moment-timezone');


let unsub


class Smses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            modal_send: 0,
            base64: null,
            mimeType: null,
            share_disp: true
        };
        console.log(this.props.smses)

        this.send = this.send.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.smsopen = this.smsopen.bind(this);
        this.toggle_send = this.toggle_send.bind(this);

        this.scrollToBottom = this.scrollToBottom.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.share_disp = this.share_disp.bind(this);

        // .where("chat_user_id", "==", this.props.chat_user_details.id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")


    }






    // }


    async componentDidMount() {






        console.log(this.props)
        if (unsub) {
            // alert("exist")
            unsub()
        }
        if (this.node != null && this.props.sms_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_sms_bottom(false);
        }


        // 電話番号　仮で佐々木のやつ
        // var mobilenumber = '09028233963';
        var mobilenumber = this.props.mobilenumber;


        unsub = smsDB.collection("sms")
            .where("mobilenumber", "==", mobilenumber)
            .orderBy("returnsmsdatetime", "asc")
            .onSnapshot((querySnapshot) => {
                var data = [];
                this.props.change_smses(data);
                querySnapshot.forEach((doc) => {

                    // console.log(change.doc.data())
                    this.props.change_smses(this.props.smses.concat(doc.data()))
                    this.props.change_sms_bottom(true);
                    // cities.push(doc.data().name);
                }, this);

            });




    }
    componentDidUpdate() {

        if (this.node != null && this.props.sms_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_sms_bottom(false);
        }
    }


    send(value) {



        // 電話番号　仮で佐々木のやつ
        // var mobilenumber = '09028233963';

        // 本番
        // var mobilenumber = this.props.mobilenumber;
        var d = moment(new Date()).tz("Asia/Tokyo").format('YYYY年MM月DD日 HH:mm:ss');

        var data = {
            message_text: value.message,
            mobilenumber: this.props.mobilenumber,
            returnsmsdatetime: d,
            sms_id: this.props.sms_id,
            m_customer_id: this.props.m_customer_id,
            user_id: this.props.user_details.id,
            user_name: this.props.user_details.userNameAll,
            section_id: this.props.user_details.amSectionId
        }


        console.log("---------------------------");
        console.log(data);

        // var d = moment(new Date()).tz("Asia/Tokyo").format('YYYY年MM月DD日 HH:mm:ss');
        // this.props.send_message({
        //     message_text: value.message,
        //     mobilenumber: mobilenumber,
        //     returnsmsdatetime: d
        // });

        this.props.send_message(data);
        this.props.newmessage_customers();

        // this.props.send_message({ replytoken: "globalcenter", message_type: "text", message_text: value.message, sms_user_id: this.props.sms_user_details.id })


    }


    smsopen() {
        this.props.get_sms_open({ sms_user_id: this.props.sms_user_details.id, take: 20 })
    }


    toggle_send(nom) {

        this.setState({
            // modal_send: nom,
            base64: null,
            name: null,
            mimeType: null,
        })
        this.props.change_sms_file_data(null)
        this.props.change_sms_send_modal(nom)
    }

    scrollToBottom = () => {

        if (this.node != null && this.props.sms_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;


            this.props.change_sms_bottom(false);
        }
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        console.log(file.type)
        reader.onload = () => {
            this.setState({
                base64: reader.result,
                name: file.name,
                mimeType: file.type
            })
            this.props.change_sms_file_data({
                file: files,
                name: file.name,
                mimeType: file.type
            })
        }



    };


    handleScroll = throttle((event) => {
        if (this.node.scrollTop === 0) {
        }
    }, 100)

    share_disp() {
        this.setState({ share_disp: !this.state.share_disp })
    }

    render() {
        return (
            <div className="item-sms" id="item-sms" onScroll={this.handleScroll} ref={(node) => this.node = node} style={{ height: this.props.sms_open_flg ? "calc(100% - 60px)" : "500px" }}>

                <div style={{ marginBottom: 60 }}>
                    <MessageShare messages="sms" share_disp={this.state.share_disp} />
                </div>

                <div>
                    <i class="fas fa-times-circle smsclose" onClick={this.share_disp} onClick={this.props.get_sms_close}></i>
                </div>

                <div>
                    {/* <div style={{ marginBottom: 30 }}>
                            {this.props.share_open_flg ? <MessageShare /> : ""}
                        </div> */}
                    {this.props.smses.length > 0 ? <SmsDisp /> : ""}
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                    {this.props.sms_open_flg ? <div className="smsbottom">
                        <SmsForm onSubmit={this.send} get_sms_close={this.props.get_sms_close} toggle_send={this.toggle_send} />
                    </div> : ""
                    }

                </div>

            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        sms_user_details: state.sms.sms_user_details,
        mobilenumber: state.sms.mobilenumber,
        sms_id: state.sms.sms_id,
        m_customer_id: state.sms.m_customer_id,
        user_details: state.masterReducer.user_details,
        smses: state.sms.smses,
        sms_bottom: state.sms.sms_bottom,
        sms_open_flg: state.sms.sms_open_flg,
        share_open_flg: state.sms.share_open_flg,
        sms_send_modal: state.sms.sms_send_modal,
        sms_send_file_loading: state.sms.sms_send_file_loading,
        sms_send_list_loading: state.sms.sms_send_list_loading,
        kys: state.kys.kys,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_sms_message(state) {
            dispatch(Sms.send_sms_message(state))
        },
        get_smses(state) {
            dispatch(Sms.get_smses(state))
        },
        change_smses(state) {
            dispatch(Sms.change_smses(state))
        },
        change_sms_bottom(state) {
            dispatch(Sms.change_sms_bottom(state))
        },
        get_sms_close(state) {
            dispatch(Sms.get_sms_close(state))
        },
        get_sms_open(state) {
            dispatch(Sms.get_sms_open(state))
        },

        change_sms_file_data(state) {
            dispatch(Sms.change_sms_file_data(state))
        },
        upload_sms_file(state) {
            dispatch(Sms.upload_sms_file(state))
        },
        change_sms_send_modal(state) {
            dispatch(Sms.change_sms_send_modal(state))
        },
        send_message(state) {
            dispatch(Sms.send_sms_message(state))
        },
        newmessage_customers(state) {
            dispatch(MessageList.newmessage_customers(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Smses);
