import React from 'react';

import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MenuLi = styled.li`
    display: inline-block;

    
`;

export default class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    render() {

        const result = this.props.shops.filter(x => x.tenpoNo === this.props.user_details.amSectionId);


        //    alert(shops.tenpoName)
        return (
            <header>

                <div style={{ textAlign: "right", position: "relative" }}>

                    {/* <a href="https://www.homestation.jp/manage/top/index"> */}
                        <i className="icon-homestation navbar-brand" style={{ lineHeight: "inherit" }}></i>
                    {/* </a> */}
                    <nav>




                        <ul className="">


                            <MenuLi > {/*
ログイン中： {!result[0]? "" : result[0].tenpoName}　{this.props.user_details.userNameAll}
</MenuLi>
                            <MenuLi className="dropdown" >
                                <Dropdown  direction="down" isOpen={this.state.dropdownOpenStar} toggle={() => { this.setState({ dropdownOpenStar: !this.state.dropdownOpenStar }); }}>
                                    <DropdownToggle
                                        tag="span"
                                        className="fas fa-star navbar-menu"
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.dropdownOpenStar}
                                    >
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>ブックマーク追加</DropdownItem>
                                        <DropdownItem divider />


                                        <Dropdown direction="right" isOpen={this.state.dropdownOpenStar2} toggle={() => { this.setState({ dropdownOpenStar2: !this.state.dropdownOpenStar2 }); }}>
                                            <DropdownToggle caret
                                                tag="span"
                                                className="navbar-menu-sub"
                                                    data-toggle="dropdown"
                                                aria-expanded={this.state.dropdownOpenStar2}
                                            >ブックマーク
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>ブックマーク追加</DropdownItem>
                                                <DropdownItem divider />
                                            </DropdownMenu>
                                        </Dropdown>

                                    </DropdownMenu>
                                </Dropdown>
                            </MenuLi>
                            <MenuLi className="dropdown" >
                                {/* <a className="fas fa-comment-dots navbar-menu" href="https://www.homestation.jp/manage/message"></a> */}
                                {/* </MenuLi>
                        <MenuLi className="dropdown" >
                            <Dropdown direction="down" isOpen={this.state.dropdownOpenQuestion} toggle={() => { this.setState({ dropdownOpenQuestion: !this.state.dropdownOpenQuestion }); }}>
                                <DropdownToggle
                                    tag="span"
                                    className="far fa-question-circle navbar-menu"
                                    data-toggle="dropdown"
                                    aria-expanded={this.state.dropdownOpenQuestion}
                                >
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href="https://www.homestation.jp/manage/knowledgebase/index/detail?id=1">home station wiki について</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem href="https://www.homestation.jp/manage/knowledgebase/index/detail?id=1">Wikiトップ</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </MenuLi> */}
                                {/* <MenuLi className="dropdown" >
                                <Dropdown direction="down" isOpen={this.state.dropdownOpenSelect} toggle={() => { this.setState({ dropdownOpenSelect: !this.state.dropdownOpenSelect }); }}>
                                    <DropdownToggle
                                        tag="div"
                                        className="navbar-menu"
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.dropdownOpenSelect}
                                        style={{paddingTop:7}}
                                    >
                                        <div id="sheetcount" style={{ border: "1px solid #FFFFFF", width: 23, height: 23, textAlign: "center"}}>18</div>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>まとめて物件選択</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem href="https://www.homestation.jp/manage/room/sheet/select/roomselect">マップ資料作成</DropdownItem>
                                        <DropdownItem href="https://www.homestation.jp/manage/mail/listing/new/">メール作成</DropdownItem>
                                        <DropdownItem >全選択クリア</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </MenuLi> */}

                                {/* <MenuLi className="dropdown" >
                            <Dropdown direction="down" isOpen={this.state.dropdownOpenExchange} toggle={() => { this.setState({ dropdownOpenExchange: !this.state.dropdownOpenExchange }); }}>
                                <DropdownToggle
                                    tag="span"
                                    className="fas fa-exchange-alt navbar-menu"
                                    data-toggle="dropdown"
                                    aria-expanded={this.state.dropdownOpenExchange}
                                >
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=1">番町店</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=2">レインボーロード店</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=3">高松駅前店</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=4">屋島店</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=5">高松中央公園店</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?shop=6">サンフラワー店</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </MenuLi>
                        <MenuLi className="dropdown" >
                            <Dropdown direction="down" isOpen={this.state.dropdownOpenCog} toggle={() => { this.setState({ dropdownOpenCog: !this.state.dropdownOpenCog }); }}>
                                <DropdownToggle
                                    tag="span"
                                    className="fas fa-cog navbar-menu"
                                    data-toggle="dropdown"
                                    aria-expanded={this.state.dropdownOpenCog}
                                >
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?settings=1">ガジェットストア</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?settings=2">ガジェット並び替え</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/master/holidays">祝日更新</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem href="https://www.homestation.jp/manage/login/logout">ログアウト</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>*/}
                            </MenuLi>
                        </ul>
                    </nav>

                </div >





            </header >
        );
    }
}
 //<NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              //  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>

              //<NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>