import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { get_chat_images } from './Saga/Chat';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

var moment = require('moment-timezone');
// import './Css/Images.css';
const authentication = require('../../react-azure-adb2c2').default;
const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin-top:15px;
`;
const Faceicon = styled.div`
    float: left;
        margin-right: -50px;
        width: 40px;
`;
const FaceiconImg = styled.img`
    width: 100%;
        height: auto;
        border-radius: 50%;
`;
const DisplayName = styled.div`
    margin:0 0 0 50px;
    font-size:9px;
    font-weight:bold;
    color:#FFF;
text-align:left;
`;
const Chatting = styled.div`
    width: 100%;
        text-align: left;
`;
const Say = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 50px;
    max-width: 60%;
    margin-bottom: 2px;
    height:250px;
`;
const SayP = styled.p`
    margin:0；
    height:270px;
`;
const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
`;
const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;bottom: 0px;
    font-size:10px;
    color:#FFF;
`;
const Description = styled.div`
    font-size:10px;
`;
const List = styled.div`
    padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;width:250px;margin:5px;margin-left:-10px !important;
`;
//export default

class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = new Date(this.props.msg.date)
        this.state = {
            bloburl: "",
            bloburl_thum: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            images_thum: [],
            currentIndex: 0,
            modalIsOpen: false,
        };
        this.imagebox = this.imagebox.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.download_file = this.download_file.bind(this);
    }


    componentWillMount() {



        const token = authentication.getAccessToken();
        const url = `https://roomsupportapi.azurewebsites.net/v1/hs/files/moviethum/` + this.props.msg.message_id
        // const url = `http://localhost:5000/v1/hs/files/moviethum/` + this.props.msg.message_id
        // alert(url)
        // 
        var bloburl_thum
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {
            // Then create a local URL for that image and print it 
            bloburl_thum = URL.createObjectURL(images)
            console.log(bloburl_thum)
            this.setState({
                bloburl_thum: bloburl_thum,
                images_thum:images
            })


            // const a = document.createElement('a');
            // // ダウンロードされるファイル名
            // a.download = "aaaaaaaaaaaaaa.mp4";
            // // a.download = "10.pdf";
            // // alert()
            // a.href = URL.createObjectURL(images);
            // // ダウンロード開始
            // a.click();




        });

    }

    download_file(){
        const token = authentication.getAccessToken();
        const url = `https://roomsupportapi.azurewebsites.net/v1/hs/files/img/` + this.props.msg.message_id
        // const url = `http://localhost:5000/v1/hs/files/img/` + this.props.msg.message_id
        // alert(url)
        // 
        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(response => response.blob())
        .then(images => {
            // Then create a local URL for that image and print it 
            bloburl = URL.createObjectURL(images)
            console.log(bloburl)
            this.setState({
                bloburl: bloburl,
                images:images
            })

            const a = document.createElement('a');
            // ダウンロードされるファイル名
            // alert(this.props.msg.message_id + "." + this.props.msg.ext)
            a.download = this.props.msg.message_id + "." + this.props.msg.ext;
            // a.download = "10.pdf";
            // alert(JSON.stringify(this.state.bloburl))
            a.href = URL.createObjectURL(this.state.images);
            // ダウンロード開始
            a.click();

        })
        .catch((reason)=>{
            //エラー
            // alert("err")
        });
    }


    imagebox(index) {

        let images = [];

        images.push({ src: this.state.bloburl })



        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: 0 })
            this.setState({ modalIsOpen: true });
        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };

    render() {
        return (
            <div>
                <Balloon className="col-xs-12">
                    <Faceicon><FaceiconImg src={this.props.msg.picture_url} /></Faceicon>
                    <DisplayName>{this.props.msg.display_name}</DisplayName>
                    <Chatting>
                        
                        <Say>
                            <SayP>
                                <BodyImg src={this.state.bloburl_thum} />
                                <div style={{position:"absolute", bottom:10, left:5}}>
                                <Fab size="small" color="default" aria-label="edit" onClick={this.download_file}>
                                    <GetAppIcon />
                                </Fab>
                                </div>
                            </SayP>
                        </Say>
                        <Time>
                            <span style={{ color: "black" }}>
                                {moment.utc(this.props.msg.created_at).format('MM/DD HH:mm')}
                            </span>
                        </Time>


                    </Chatting>


                </Balloon>

                <ModalGateway>
                    {this.state.modalIsOpen ? (
                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>

                    ) : null}
                </ModalGateway>

            </div >

        )
    }
}
//<BodyImg src={"https://homestationapi.azurewebsites.net/api/lineimage/" + this.props.msg.body_all} />
//<BodyImg src={this.props.lineimage.map(this.getFullName)} /> 
//containers
const mapStateToProps = (state) => {
    return {
        line_images: state.line.line_images

    }
}
function mapDispatchToProps(dispatch) {
    return {

        get_chat_images(state) {
            dispatch(get_chat_images(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineImgComponent);
