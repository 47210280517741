import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as m_vacancy_log_headers_api from '../Api/m_vacancy_log_headers_api';
// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';

///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    // rent_contract_monies:any;
    vacancies: any;
    // search_id: number;
    // search_detail: any;
    // search_modal: boolean;
    // grid_update: boolean;
    // csv_loading: boolean;
    // search_list: any;

}
const initialState: State = {

    // rent_contract_monies: [],
    vacancies: [],
    // search_id: 0,
    // search_detail: {},
    // search_modal: false,
    // grid_update: false,
    // csv_loading: false,
    // search_list: { name: "", tatemono_name: "", start_date: null, end_date: null, search_completed: false }
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

// export const get_rent_contract_monies = actionCreator<{rent_contract_id:number}>('GET_RENT_CONTRACT_MONIES');
// export const get_rent_contract_monies_success = actionCreator("GET_RENT_CONTRACT_MONIES_SUCCESS");
// export const get_rent_contract_monies_failure = actionCreator("GET_RENT_CONTRACT_MONIES_FAILURE");

// export const get_rent_contract_accounting = actionCreator("GET_RENT_CONTRACT_ACCOUNTING");


export const get_vacancies = actionCreator('GET_VACANCIES');
export const change_vacancies = actionCreator("CHANGE_VACANCIES");

// export const update_kyousai_comp = actionCreator('UPDATE_KYOUSAI_COMP');
// export const upload_kyousai_csv = actionCreator<any>('UPLOAD_KYOUSAI_CSV');
// // export const get_banks_failure = actionCreator("GET_BANKS_FAILURE");

// export const change_search_id = actionCreator<number>("CHANGE_SEARCH_ID");

// export const change_search_modal = actionCreator<boolean>("CHANGE_SEARCH_MODAL");

// export const change_grid_update = actionCreator<boolean>("CHANGE_GRID_UPDATE");

// export const change_csv_loading = actionCreator<boolean>("CHANGE_CSV_LOADING");

// export const change_search_detail = actionCreator<any>("CHANGE_SEARCH_DETAIL");


// export const change_search_list = actionCreator<any>("CHANGE_SEARCH_LIST");

// export const matching = actionCreator<any>("MATCHNG");

// export const delete_kyousais = actionCreator<any>("DELETE_KYOUSAIS");

// export const all_select = actionCreator<any>("ALL_SELECT");

// export const get_kyousais_csv = actionCreator<any>("GET_KYOUSAIS_CSV");


// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const vacancyReducer = reducerWithInitialState(initialState)
    .case(change_vacancies, (state, payload) => ({ ...state, vacancies: payload }))
// .case(change_search_id, (state, payload) => ({ ...state, search_id: payload }))

// .case(change_search_modal, (state, payload) => ({ ...state, search_modal: payload }))

// .case(change_grid_update, (state, payload) => ({ ...state, grid_update: payload }))

// .case(change_csv_loading, (state, payload) => ({ ...state, csv_loading: payload }))

// .case(change_search_detail, (state, payload) => ({ ...state, search_detail: payload }))

// .case(change_search_list, (state, payload) => ({ ...state, search_list: payload }))


// .case(get_rent_contract_monies_success, (state, payload) => ({ ...state, rent_contract_monies: payload }));



//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const vacancySaga = [
    //     takeEvery('GET_RENT_CONTRACT_MONIES', handleRequestMoney),
    // takeEvery('GET_RENT_CONTRACT_ACCOUNTING', handle_get_rent_contract_accounting),
    takeEvery('GET_VACANCIES', handle_get_vacancies),
    // takeEvery('UPDATE_KYOUSAI_COMP', handle_update_kyousai_comp),
    // takeEvery('UPLOAD_KYOUSAI_CSV', handle_upload_kyousai_csv),
    // takeEvery('MATCHNG', handle_matching),
    // takeEvery('DELETE_KYOUSAIS', handle_delete_kyousais),
    // takeEvery('ALL_SELECT', handle_all_select),
    // takeEvery('GET_KYOUSAIS_CSV', handle_get_kyousais_csv),

    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_vacancies(action: any) {
    // yield put(change_csv_loading(true))
    const { payload, error } = yield call(m_vacancy_log_headers_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        yield put(change_vacancies(payload))
        // yield put(change_csv_loading(false))
        // let editor =action.payload.window.CKEDITOR.instances.editor2;
        //        let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
        //      const imgurl= "https://homestationopenrooms.azurewebsites.net/api/tcblogimg/"+payload
        //    //   alert(imgurl)
        //        editor.insertHtml("<img src='"+imgurl+"' alt='' />");
        //  yield put(get_mail_detail_files_success(payload))

        //  yield put(change_get_mail_detail_files_loading(0))




    } else {
        alert(error)

    }



}



// function* handle_get_rent_contract_accounting(action:any) {

//     const { payload, error }= yield call(RentContractAccountingApi.list, action.payload);

//        // サーバーからのレスポンスデータによる分岐処理
//        if (payload && !error) {
//            // 成功時アクション呼び出し
//        console.log(payload)
//        const a = document.createElement('a');
// // ダウンロードされるファイル名
// a.download = "会計.csv";
// a.href = URL.createObjectURL(payload);
// // ダウンロード開始
// a.click();
//     //    yield put(get_rent_contract_monies_success(payload));

//        } else {
//        //    // 失敗時アクション呼び出し
//        //    yield put(loginFailure(payload));
//        }
//    }



// function* handle_get_kyousais(action: any) {
//     console.log(action.payload)

//     const state = yield select();
//     let search_list = state.kyousaiReducer.search_list
//     const { payload, error } = yield call(KyousaiApi.list, search_list);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         yield put(change_kyousais(payload));

//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {

//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }



// function* handle_get_kyousais_csv(action: any) {
//     const state = yield select();
//     let search_list = state.kyousaiReducer.search_list
//     const { payload, error } = yield call(KyousaiApi.downloadcsv, search_list);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         // 成功時アクション呼び出し
//         console.log(payload)
//         const a = document.createElement('a');
//         // ダウンロードされるファイル名
//         a.download = "きょうさい.csv";
//         a.href = URL.createObjectURL(payload);
//         // ダウンロード開始
//         a.click();
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


// function* handle_update_kyousai_comp(action: any) {
//     yield put(change_csv_loading(true))
//     const state = yield select();
//     let kyousais = state.kyousaiReducer.kyousais
//     // console.log(kyousais)
//     let kyousaisset = kyousais.filter((a: any) => a.select === 1)
//     console.log(kyousaisset)

//     const { payload, error } = yield call(KyousaiApi.update, kyousaisset);

//     if (payload && !error) {
//         // console.log(payload)
//         // alert()
//         yield put(change_kyousais(payload));
//         yield put(change_csv_loading(false))
//         //  yield put(change_kyousais(payload));
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {

//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }

// function* handle_matching(action: any) {
//     yield put(change_csv_loading(true))

//     const { payload, error } = yield call(KyousaiApi.matching, action.payload);
//     if (payload && !error) {
//         // console.log(payload)
//         // alert()
//         yield put(get_kyousais())
//         yield put(change_csv_loading(false))
//         //  yield put(change_kyousais(payload));
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {

//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


// function* handle_delete_kyousais(action: any) {
//     yield put(change_csv_loading(true))
//     const state = yield select();
//     let kyousais = state.kyousaiReducer.kyousais
//     // console.log(kyousais)
//     let kyousaisset = kyousais.filter((a: any) => a.delete === 1)
//     console.log(kyousaisset)

//     const { payload, error } = yield call(KyousaiApi.delete_kyousais, kyousaisset);

//     if (payload && !error) {
//         // console.log(payload)
//         // alert()
//         yield put(change_kyousais(payload));
//         yield put(change_csv_loading(false))
//         //  yield put(change_kyousais(payload));
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {

//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }





// function* handle_all_select(action: any) {
//     // yield put(change_csv_loading(true))
//     const state = yield select();
//     let kyousais = state.kyousaiReducer.kyousais
//     const count = kyousais.filter((a: any) => a.select === 1).length
//     kyousais = kyousais.map((value: any) => {
//         if (value.receipt_at && value.rent_contract_id) {
//             value.select = count > 0 ? 0 : 1
//         }
//         return value
//     })

//     yield put(change_kyousais(kyousais))
//     yield put(change_grid_update(true));


// }






// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }